import {
  FETCH_COMPANIES,
  CREATE_COMPANY,
  API_ERROR,
  CREATE_COMPANY_SUCCESS,
  FETCH_COMPANIES_SUCCESS,
  REST_ERROR,
  FETCH_ROLE,
  FETCH_ROLE_SUCCESS,
} from "./actionTypes";

export const fetchRole = () => {
  return {
    type: FETCH_ROLE,
  };
};

export const fetchRoleSuccess = (data) => {
  return {
    type: FETCH_ROLE_SUCCESS,
    payload: data,
  };
};

export const fetchCompanies = (url) => {
  return {
    type: FETCH_COMPANIES,
    url: url,
  };
};

export const fetchCompaniesSuccess = (data) => {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    payload: data,
  };
};

export const createCompany = (company, history) => {
  return {
    type: CREATE_COMPANY,
    payload: { company, history },
  };
};

export const createCompanySuccess = (company) => {
  return {
    type: CREATE_COMPANY_SUCCESS,
    payload: company,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const restError = () => {
  return {
    type: REST_ERROR,
  };
};
