import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, FormGroup, Alert, Card, CardBody } from "reactstrap";
import {
  getUsersAndEmployees,
  putUsersAndEmployees,
} from "../../../api/usersAndEmployees";
import {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";
import profileIcon from "../../../assets/images/profile-icon.png";
import debounce from "lodash.debounce";
import { formatNumber, formatDate } from "../../../utils";
import NumberInput from "../../Inputs/NumberInput";

const StaffCash = ({
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();

  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0.0);
  const [errors, setErrors] = useState({});

  const forms = useRef([]);
  const saveUsers = async (cb) => {
    const canSave = await getCanSave();
    if (!canSave) {
      forms.current.forEach((form) => {
        form.setTouched(Object.keys(form._inputs));
      });
    } else {
      let array = [];
      array = users.filter((user) => user.updated);
      if (array.length) {
        showLoader();
        putUsersAndEmployees(`/employee-cash/${company?.id}`, array)
          .then((data) => {
            setUsers(data);
            setErrors({});
            cb();
            removeLoader();
          })
          .catch((error) => {
            setErrors(error);
            removeLoader();
            throw error;
          });
      } else {
        setErrors({});
        cb();
      }
    }
  };
  const save = debounce(() => {
    saveUsers(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    saveUsers(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveUsers(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    saveUsers(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  useEffect(() => {
    showLoader();
    getUsersAndEmployees(`/employee-cash/${company?.id}`)
      .then((data) => {
        setUsers(data);
        removeLoader();
      })
      .catch(() => {
        removeLoader();
      });
  }, []);
  const getCanSave = async () => {
    const flag = await forms.current.reduce(async (flag, form) => {
      const values = form.getValues();
      const errors = await form.validateAll(values);
      return flag && errors.isValid;
    }, true);
    return flag;
  };
  useEffect(() => {
    let totalCash = 0.0;
    users.map((user) => {
      totalCash += parseFloat(user.opening_balance) || 0;
    });
    setTotal(totalCash);
  }, [users]);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  const onValidSubmit = (e, vals) => {};

  const onChangeNumber = async (value, name) => {
    const users_copy = users.slice();
    users_copy.forEach((user) => {
      if (user.id == name) {
        user.updated = true;
        user.opening_balance = value;
      }
    });
    setUsers(users_copy);
  };
  return (
    <div className="container-fluid">
      <ActionHeader canSave={true} save={save} saveExit={saveExit} />
      <div className="">
        <div className="container-fluid">
          <Row>
            <Col>
              <h3>
                Employee{users.length > 1 ? "s" : ""} Cash Balance as of:{" "}
                {formatDate(company.starting_date)}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {!!Object.keys(errors).length && (
                <Alert color="danger">{Object.values(errors)}</Alert>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Card className="table-container-input-without-title-total">
                <CardBody>
                  {users.map((user, cnt) => {
                    return (
                      <Row>
                        <Col md="3">
                          <div className="text-center">
                            <img
                              className="rounded-circle"
                              alt="100x100"
                              width="50px"
                              height="50px"
                              src={profileIcon}
                              data-holder-rendered="true"
                            />
                            <div>{user?.employee_type}</div>
                          </div>
                        </Col>
                        <Col md="3">
                          <div>
                            {user.name} <br />
                          </div>
                        </Col>
                        <Col md="6">
                          <AvForm
                            className="needs-validation"
                            id={user.id}
                            onValidSubmit={onValidSubmit}
                            ref={(ref) => {
                              forms.current[user.id] = ref;
                            }}
                            disabled={!activeCompanyIsEditable}
                          >
                            <FormGroup>
                              <NumberInput
                                name={`${user.id.toString()}`}
                                value={user?.opening_balance || ""}
                                onChangeValue={onChangeNumber}
                                disabled={!activeCompanyIsEditable}
                              />
                            </FormGroup>
                          </AvForm>
                        </Col>
                      </Row>
                    );
                  })}
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <div> Total Company Cash Balance </div>
                  <div>{formatNumber(total)} AED</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ActionFooter
        next={next}
        canSave={true}
        back={back}
        complete={saveUsers}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(StaffCash);
