import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import ProfileImg from "../ProfileImg";
import Notification from "../Notification";
import History from "../History";
import { openPdfViewer } from "../../store/layout/PdfViewer/actions.js";
import { toast } from "react-toastify";
import PdfViewer from "../PdfViewer";
import { convertToBase64 } from "../FileUpload/helper";
import { getOnboarder, downloadFile, getFile } from "../../api/common";
import { showLoader, removeLoader } from "../../store/Loader/actions";
import RequiredError from "../RequiredError";
import {
  resetState,
  setActiveCompany,
  setActiveCompanyIsEditable,
} from "../../store/CompanyState/actions.js";
import {
  startOnBoardingRequest,
  startReviewRequest,
} from "../../store/auth/AssignTome/actions";
import { DropdownItem } from "reactstrap";
import DropDownList from "../DropdownList";

const Header = ({
  company,
  roleUser,
  resetState,
  setActiveCompany,
  setActiveCompanyIsEditable,
  startOnBoardingRequest,
  startReviewRequest,
  successStartOnboarding,
  openPdfViewer,
  showLoader,
  removeLoader,
}) => {
  const params = useParams();
  const history = useHistory();
  const companyId = params.company_id;
  useEffect(() => {
    if (companyId)
      getOnboarder(`/UserManagement/company/${companyId}`).then((data) => {
        setActiveCompany(data);
        setActiveCompanyIsEditable(
          (roleUser.is_staff &&
            data.status !== "completed" &&
            data.status !== "finalizing" &&
            data.status !== "cancelled") ||
            (data.status === "in_process" && data.user === roleUser.id) ||
            (data.status === "in_review" && data.reviewer === roleUser.id)
        );
        resetState(data.wizard_states, data.current_wizard_state);
      });
    return () => {
      setActiveCompany({});
    };
  }, []);

  const handleStartOnboarding = (e) => {
    e.preventDefault();
    e.stopPropagation();
    startOnBoardingRequest(company.id);
  };

  const handleStartReview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    startReviewRequest(company.id);
  };

  useEffect(() => {
    if (successStartOnboarding && company?.id)
      getOnboarder(`/UserManagement/company/${company.id}`).then((data) => {
        setActiveCompany(data);
        setActiveCompanyIsEditable(
          (roleUser.is_staff &&
            data.status !== "completed" &&
            data.status !== "finalizing" &&
            data.status !== "cancelled") ||
            (data.status === "in_process" && data.user === roleUser.id) ||
            (data.status === "in_review" && data.reviewer === roleUser.id)
        );
        resetState(data.wizard_states, data.current_wizard_state);
      });
    return () => {
      setActiveCompany({});
    };
  }, [successStartOnboarding]);
  const generateAgreement = async (e, sendAgreement) => {
    showLoader();
    try {
      const res = await getFile(
        `/UserManagement/${company.id}/previewAgreement`
      );
      const url = window.URL.createObjectURL(
        new Blob([res], { type: "application/pdf" })
      );
      const companyProfile = await getOnboarder(
        `/UserManagement/companyProfile/${company?.id}`
      );
      const disabledSendAgreement = companyProfile.is_mobile_verified;
      openPdfViewer(url, "agreement", sendAgreement, !disabledSendAgreement);
      removeLoader();
    } catch (error) {
      toast.error(<RequiredError errors={error} />);
      removeLoader();
      throw error;
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box"></div>
            <div className="d-flex">
              <div className="container-fluid pt-2">
                {company?.id && (
                  <div className="d-flex">
                    <div className="p-2">
                      <DropDownList>
                        <DropdownItem
                          onClick={() => {
                            history.push(`/history/${company.id}`);
                          }}
                        >
                          History
                        </DropdownItem>
                        <DropdownItem divider />

                        <DropdownItem
                          onClick={(e) => generateAgreement(e, false)}
                        >
                          Generate Agreement
                        </DropdownItem>

                        <DropdownItem
                          onClick={(e) => generateAgreement(e, true)}
                        >
                          Send Agreement
                        </DropdownItem>
                      </DropDownList>
                    </div>
                    <div>
                      <Link to={`/dashboard/company/${company?.id}`}>
                        <h1 style={{ display: "inline", color: "#5969ff" }}>
                          {company?.name}
                        </h1>
                      </Link>
                      <span>{company?.status?.replaceAll("_", " ")}</span>
                    </div>
                    <div>
                      {company.status == "ready_for_onboarding" &&
                        company.user === roleUser.id && (
                          <i
                            className="fas fa-play-circle p-2"
                            style={{
                              fontSize: "x-large",
                              color: "green",
                              cursor: "pointer",
                            }}
                            title="Start OnBoarding"
                            onClick={(e) => handleStartOnboarding(e)}
                          ></i>
                        )}
                      {company.status == "ready_for_review" &&
                        company.reviewer === roleUser.id && (
                          <i
                            className="fas fa-play-circle p-2"
                            style={{
                              fontSize: "x-large",
                              color: "green",
                              cursor: "pointer",
                            }}
                            title="Start Review"
                            onClick={(e) => handleStartReview(e)}
                          ></i>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ml-1">
              <Notification />
            </div>
            <div className="dropdown d-none d-lg-inline-block ml-1">
              <ProfileImg />
            </div>
            <div className="ml-1 d-flex align-items-center">
              <div>
                {roleUser && (
                  <span>
                    {(roleUser?.first_name || "") +
                      " " +
                      (roleUser?.last_name || "")}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentState: state.CompanyState.currentState,
    company: state.CompanyState.activeCompany,
    roleUser: state.Companies.role,
    successStartOnboarding: state.Assign.successStartOnboarding,
  };
};
export default connect(mapStateToProps, {
  resetState,
  setActiveCompany,
  setActiveCompanyIsEditable,
  startOnBoardingRequest,
  startReviewRequest,
  openPdfViewer,
  showLoader,
  removeLoader,
})(Header);
