import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { closeWordViewer } from "../../store/layout/WordViewer/actions";
import mammoth from "mammoth";
import "./style.scss";

const WordViewer = ({ wordFile, isOpen, closeWordViewer }) => {
  useEffect(() => {
    if (wordFile) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var arrayBuffer = this.result;
        var data = e.target.result;
        mammoth
          .convertToHtml({ arrayBuffer: arrayBuffer })
          .then((result) => {
            const docEl = document.createElement("div");
            docEl.className = "document-container";
            docEl.innerHTML = result.value;
            document.getElementById("docx").innerHTML = docEl.outerHTML;
          })
          .catch((a) => {
            console.log("alexei: something went wrong", a);
          })
          .done();
      };
      reader.onerror = function (ex) {
        console.log(ex);
      };
      reader.readAsArrayBuffer(wordFile);
    }
  }, [wordFile]);
  return (
    <div>
      {isOpen && (
        <div className="warper pg-viewer-wrapper">
          <div className="close" onClick={() => closeWordViewer()}>
            &times;
          </div>
          <div id="docx"></div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isOpen: state.WordViewerReducer.isOpen,
    wordFile: state.WordViewerReducer.wordFile,
  };
};
export default connect(mapStateToProps, { closeWordViewer })(WordViewer);
