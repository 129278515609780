import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { formatDateTime } from "../../utils";
import ProfileImg from "../ProfileImg";
import { fetchUsers } from "../../store/auth/Users/actions";

import "./style.css";
const HistoryCard = ({ history, listUsers, fetchUsers }) => {
  useEffect(() => {
    if (!listUsers?.length) fetchUsers();
  }, []);
  return (
    <div className={"list-group-item list-group-item-action"}>
      <div className="history-info">
        {/* <div className="history-list-user-img"><img src="../assets/images/avatar-2.jpg" alt="" className="user-avatar-md rounded-circle" /></div> */}
        <div>
          <div className="history-list-user-img">
            <span
              style={{
                fontSize: "x-large",
              }}
            >
              <ProfileImg width="40px" />
            </span>
          </div>
          <div className="history-list-user-block">
            <span className="history-list-user-name">
              {
                listUsers.filter((user) => {
                  return user.id == history?.user;
                })[0]?.username
              }
            </span>
            <span>{history?.message}.</span>
            <div className="history-date">
              {formatDateTime(history.created_at)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    listUsers: state.Users.list,
  };
};
export default connect(mapStateToProps, {
  fetchUsers,
})(HistoryCard);
