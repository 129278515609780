import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  BASIC_INFO_GET_REQUEST,
  BASIC_INFO_GET_STATE_REQUEST,
  BASIC_INFO_GET_COUNTRY_REQUEST,
  UPDATE_COMPANY_BASIC_INFO,
} from "./actionTypes";

import {
  apiError,
  basicInfoGetSuccess,
  basicInfoGetStateSuccess,
  basicInfoGetCountrySuccess,
  updateBasicInfoSuccess,
} from "./actions";

import { companyExit } from "../../../../../store/layout/actions.js";

import { getWizardStatus } from "../../../../../store/auth/WizardStatus/actions";
import {
  getBasicInfoApi,
  getBasicInfoStateApi,
  getBasicInfoCountryApi,
  putBasicInfo,
} from "../../../../../api/basicInfo";

function* getBasicInfo({ payload: { companyId } }) {
  try {
    const response = yield call(
      getBasicInfoApi,
      `/companyProfile/${companyId}`
    );
    yield put(getWizardStatus(response));
    yield put(basicInfoGetSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// //state/{id}
function* getBasicInfoState() {
  try {
    const response = yield call(getBasicInfoStateApi, `/state/`);
    yield put(basicInfoGetStateSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getBasicInfoCountry() {
  try {
    const response = yield call(getBasicInfoCountryApi, `/country/`);
    yield put(basicInfoGetCountrySuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* updateCompanyBasicInfo({
  payload: { type, companyId, updatedBasicInfoItem },
}) {
  try {
    const response = yield call(
      putBasicInfo,
      `/companyProfile/${companyId}`,
      updatedBasicInfoItem
    );
    yield put(updateBasicInfoSuccess(response));
    // if (type === "back") {
    //   yield put(previousStep());
    // } else if (type === "next") {
    //   yield put(nextStep());
    // } else if (type === "exit") {
    //   companyExit();
    // }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchBasicInfo() {
  yield takeEvery(BASIC_INFO_GET_REQUEST, getBasicInfo);
  yield takeEvery(BASIC_INFO_GET_COUNTRY_REQUEST, getBasicInfoCountry);
  yield takeEvery(BASIC_INFO_GET_STATE_REQUEST, getBasicInfoState);
  yield takeEvery(UPDATE_COMPANY_BASIC_INFO, updateCompanyBasicInfo);
}

function* sagaBasicInfo() {
  yield all([fork(watchBasicInfo)]);
}

export default sagaBasicInfo;
