import axios from "axios";
import { BASEURL } from ".";

// Login Method
const postLogin = (url, data) => {
  return axios
    .post(`${BASEURL}${url}`, data)
    .then((response) => {
      return response?.data?.access_token;
    })
    .catch((err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid User Name or password";
            break;
          default:
            message = "Sorry! something went wrong";
            break;
        }
      }
      throw message;
    });
};

export { postLogin };
