import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Notifications from "../pages/Notifications";
import History from "../pages/History";
import Users from "../pages/Users";
import Settings from "../pages/Settings";
import Login from "../pages/Login";
import Home from "../pages/Home";
import ForgetPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/ResetPassword";
import VerifyMobile from "../pages/VerifyMobile";
import ViewAgreement from "../pages/ViewAgreement";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard, roles: ["admin", "user"] },
  {
    path: "/notifications",
    component: Notifications,
    roles: ["admin", "user"],
  },
  {
    path: "/history/:company_id",
    component: History,
    roles: ["admin", "user"],
  },

  { path: "/users", component: Users, roles: ["admin"] },
  { path: "/settings", component: Settings, roles: ["admin"] },
];

const publicRoutes = [
  { path: "/", component: Home, exact: true },
  { path: "/login", component: Login },
  { path: "/forgetPassword", component: ForgetPassword },
  { path: "/resetPassword/:token", component: ResetPassword },
  { path: "/verifyMobile/:token", component: VerifyMobile },
  { path: "/viewAgreement/:token", component: ViewAgreement },
];

export { authProtectedRoutes, publicRoutes };
