import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { useLocation, useHistory } from "react-router-dom";
import { Tr, Td } from "react-super-responsive-table";
import { Button, Col, Row } from "reactstrap";
import { fetchUsers } from "../../store/auth/Users/actions";

import { toast } from "react-toastify";
import ResponsiveTables from "../../components/Table";
import Pagination from "../../components/Pagination";
import SelectUserOnFly from "../../components/SelectUserOnFly";
import Search from "../../components/Search";
import FilterChoice from "../../components/FilterChoice";
import DateRangeFilter from "../../components/DateRangeFilter";
import { fetchCompanies, apiError } from "../../store/auth/Companies/actions";
import {
  assignRequest,
  assignReviewRequest,
  startOnBoardingRequest,
  startReviewRequest,
  restStateAssign,
  changeOnBoardingRequest,
  changeReviewerRequest,
} from "../../store/auth/AssignTome/actions";
import { setActiveCompany } from "../../store/CompanyState/actions.js";
import { formatDateTime } from "../../utils";

import { putOnboarder } from "../../api/common";
import "./style.css";

const headCells = [
  {
    id: "company",
    label: "Company Name",
    colspan: 3,
  },
  {
    id: "owner",
    label: "Owner",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "created_at",
    label: "Creation DateTime",
  },
  {
    id: "assignee",
    label: "Assignee",
  },
];
const PAGELIMIT = 20;
const Dashboard = (props) => {
  const location = useLocation();
  const history = useHistory();
  const {
    list,
    count,
    error,
    assignError,
    success,
    fetchCompanies,
    assignRequest,
    assignReviewRequest,
    roleUser,
    loadingRole,
    startOnBoardingRequest,
    startReviewRequest,
    successStartOnboarding,
    companySelected,
    userId,
    restStateAssign,
    fetchUsers,
    listUsers,
    changeOnBoardingRequest,
    changeReviewerRequest,
    successChangeOnBoarding,
    apiError,
    setActiveCompany,
  } = props;
  const [page, setPage] = useState(0);
  const [resetFlag, setResetFlag] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  const handleRouting = (e, index) => {
    e.stopPropagation();
    e.preventDefault();
    const item = list[index];
    history.push(`${location.pathname}/company/${item?.id}`);
  };

  const handleAssign = (e, companyId) => {
    e.preventDefault();
    e.stopPropagation();
    assignRequest(companyId);
  };
  const handleStartOnboarding = (e, companyId) => {
    e.preventDefault();
    e.stopPropagation();
    startOnBoardingRequest(companyId);
  };

  const handleAssignReviewer = (e, companyId) => {
    e.preventDefault();
    e.stopPropagation();
    assignReviewRequest(companyId);
  };
  const handleStartReview = (e, companyId) => {
    e.preventDefault();
    e.stopPropagation();
    startReviewRequest(companyId);
  };

  const fetchCompaniesCall = () => {
    let filter = "";
    if (page) filter += `page=${page}`;

    if (searchValue) filter += `&name=${searchValue}`;

    filter += statusFilter.reduce((filter, status) => {
      return filter + `&status=${status?.value}`;
    }, "");

    const date_after = dateRange[0].startDate;
    const date_before = dateRange[0].endDate;
    if (date_after) {
      filter += `&created_at_after=${date_after.toLocaleDateString("en-US")}`;
    }
    if (date_before) {
      filter += `&created_at_before=${date_before.toLocaleDateString("en-US")}`;
    }
    if (orderBy) {
      filter += `&order_by=${orderBy}`;
    }

    fetchCompanies(`/company/?${filter}`);
  };
  useEffect(() => {
    if (page) fetchCompaniesCall();
  }, [page, searchValue, orderBy]);
  useEffect(() => {
    if (success || successChangeOnBoarding) {
      fetchCompaniesCall();
      const company = list.find((item) => {
        return item.id === companySelected;
      });
      const user = listUsers.find((item) => {
        return item.id === userId;
      });
      if (company)
        toast.success(
          `${company?.name} is now assigned to ${
            user?.id ? user?.first_name + " " + user?.last_name : "you"
          }`
        );
    }
  }, [success, successChangeOnBoarding]);

  useEffect(() => {
    if ((successStartOnboarding || success) && companySelected) {
      getCompanyIndex(companySelected);
    }
  }, [success, successStartOnboarding, companySelected]);

  useEffect(() => {
    setActiveCompany({});
    return () => {
      restStateAssign();
    };
  }, []);

  const getCompanyIndex = (id) => {
    const companyIndex = list.findIndex((item) => {
      return item.id === id;
    });

    const item = list[companyIndex];
    history.push(`${location.pathname}/company/${item?.id}`, item);
  };

  const handleAction = (currentUser, item, index) => {
    let controllers = [];
    controllers = [];
    switch (item.status) {
      case "in_process":
        return controllers;
      case "ready_for_onboarding":
        const assigned = currentUser.id == item.user && (
          <Button
            color="primary"
            onClick={(e) => handleStartOnboarding(e, item.id)}
          >
            Start Onboarding
          </Button>
        );
        const notAssigned = !item.user &&
          (currentUser.is_onboarder || currentUser.is_staff) && (
            <Button color="primary" onClick={(e) => handleAssign(e, item.id)}>
              Assign to me
            </Button>
          );
        controllers = controllers.concat([assigned, notAssigned]);
        return controllers;
      case "ready_for_review":
        const assignedReviewer = currentUser.id == item.reviewer && (
          <Button
            color="primary"
            onClick={(e) => handleStartReview(e, item.id)}
          >
            Start Review
          </Button>
        );

        const notAssignedReviewer = !item.reviewer &&
          (currentUser.is_reviewer || currentUser.is_staff) && (
            <Button
              color="primary"
              onClick={(e) => handleAssignReviewer(e, item.id)}
            >
              Assign to me Review
            </Button>
          );
        controllers = controllers.concat([
          assignedReviewer,
          notAssignedReviewer,
        ]);
        return controllers;
      default:
        return controllers;
    }
  };

  const handleSelectGroup = (value, companyId) => {
    changeOnBoardingRequest(companyId, +value.value);
  };

  const handleSelectReviewer = (value, companyId) => {
    changeReviewerRequest(companyId, +value.value);
  };

  useEffect(() => {
    fetchUsers();
  }, [roleUser]);
  const onSearch = (value) => {
    setSearchValue(value);
    setPage(1);
  };
  const onChangeStatusFilter = (statuses) => {
    setStatusFilter(statuses);
  };

  const onChangeDateRange = (dateRange) => {
    setDateRange(dateRange);
  };

  const reset = () => {
    setDateRange(
      [
        {
          startDate: "",
          endDate: "",
          key: "selection",
        },
      ],
      filter
    );
    setStatusFilter([]);
    setResetFlag(true);
  };
  useEffect(() => {
    if (resetFlag) filter();
    setResetFlag(false);
  }, [resetFlag]);
  const filter = () => {
    if (page === 1) fetchCompaniesCall();
    setPage(1);
  };
  const setCurrentOrderBy = (name) => {
    if (name === "created_at")
      if (name == orderBy) {
        setOrderBy(`-${name}`);
      } else {
        setOrderBy(`${name}`);
      }
  };
  const getAssignee = (company) => {
    if (["in_review"].includes(company.status)) {
      return listUsers.find((user) => {
        return company.reviewer === user.id;
      });
    } else {
      return listUsers.find((user) => {
        return company.user === user.id;
      });
    }
  };
  const handleAssignButtons = (item) => {
    if (roleUser.is_staff) {
      if (
        item.status === "ready_for_onboarding" ||
        item.status === "in_process"
      ) {
        return (
          <SelectUserOnFly
            company={item}
            handleOnchange={handleSelectGroup}
            label="Onboarder"
          />
        );
      } else if (
        item.status === "ready_for_review" ||
        item.status === "in_review"
      ) {
        return (
          <SelectUserOnFly
            company={item}
            label="Reviewer"
            handleOnchange={handleSelectReviewer}
          />
        );
      }
    } else {
      if (item.status === "ready_for_onboarding") {
        return (
          <i
            class="fas fa-user-plus"
            onClick={(e) => handleAssign(e, item.id)}
            title={`Assign to me`}
          ></i>
        );
      } else if (item.status === "ready_for_review") {
        return (
          <i
            class="fas fa-user-plus"
            onClick={(e) => handleAssignReviewer(e, item.id)}
            title={`Assign to me`}
          ></i>
        );
      }
    }
  };
  return (
    <>
      {(!loadingRole || error) && (
        <div className="page-content">
          <div className="container-fluid">
            <Row className="mb-2">
              <Col md="4">
                <Search onSearch={onSearch} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="4">
                <FilterChoice
                  name="status"
                  onChangeFilter={onChangeStatusFilter}
                  statuses={statusFilter}
                  options={[
                    {
                      value: "ready_for_onboarding",
                      label: "Ready for Onboarding",
                      color: "#00B8D9",
                      isFixed: true,
                    },
                    {
                      value: "in_process",
                      label: "In Process",
                      color: "#00B8D9",
                      isFixed: true,
                    },
                    {
                      value: "blocked",
                      label: "Blocked",
                      color: "#00B8D9",
                      isFixed: true,
                    },
                    {
                      value: "ready_for_review",
                      label: "Ready for Review",
                      color: "#00B8D9",
                      isFixed: true,
                    },
                    {
                      value: "in_review",
                      label: "In Review",
                      color: "#00B8D9",
                      isFixed: true,
                    },
                    {
                      value: "configuring",
                      label: "Configuring",
                      color: "#00B8D9",
                      isFixed: true,
                    },

                    {
                      value: "finalizing_submission",
                      label: "Finalizing Submission",
                      color: "#00B8D9",
                      isFixed: true,
                    },
                    {
                      value: "completed",
                      label: "Completed",
                      color: "#00B8D9",
                      isFixed: true,
                    },
                  ]}
                />
              </Col>
              <Col md="6">
                <DateRangeFilter
                  onChangeDateRange={onChangeDateRange}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              </Col>
              <Col md="2">
                <Button color="light" onClick={reset} className="mr-1">
                  Reset
                </Button>
                <Button color="primary" onClick={filter}>
                  Filter
                </Button>
              </Col>
            </Row>
          </div>
          <ResponsiveTables
            headCells={headCells}
            title="Companies"
            error={error || assignError}
            start={(page - 1) * PAGELIMIT + 1}
            end={(page - 1) * PAGELIMIT + list.length}
            count={count}
            orderBy={orderBy}
            setCurrentOrderBy={setCurrentOrderBy}
          >
            {!loadingRole &&
              list.map((item, index) => {
                const identifier = `${item.name}- ${item.id}`;
                return (
                  <Tr
                    key={identifier}
                    onClick={(e) => handleRouting(e, index)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td style={{ borderRight: "0px" }}>{item?.name}</Td>
                    <Td
                      style={{ minWidth: "5px", borderLeft: "0px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {handleAssignButtons(item)}
                    </Td>
                    <Td style={{ minWidth: "5px", borderLeft: "0px" }}>
                      {roleUser.is_staff && (
                        <i
                          className="fas fa-bell"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push(
                              `${location.pathname}/Reminders/${item?.id}`
                            );
                          }}
                        ></i>
                      )}
                    </Td>
                    <Td>
                      {item?.owner?.first_name} {item?.owner?.last_name}
                    </Td>
                    <Td>{item?.status?.replaceAll("_", " ")}</Td>
                    <Td>{formatDateTime(item?.created_at)}</Td>
                    <Td>{getAssignee(item)?.username}</Td>
                    {/* <Td */}
                    {/*   style={{ minWidth: "230px" }} */}
                    {/*   onClick={(e) => { */}
                    {/*     e.preventDefault(); */}
                    {/*     e.stopPropagation(); */}
                    {/*   }} */}
                    {/* > */}
                    {/*   <select */}
                    {/*     value={selectedGroup[item?.id] || item.user || ""} */}
                    {/*     className="form-control" */}
                    {/*     name={item?.id} */}
                    {/*     onChange={(e) => handleSelectGroup(e, item?.id)} */}
                    {/*     disabled={ */}
                    {/*       !roleUser.is_staff || */}
                    {/*       item.status !== "ready_for_onboarding" */}
                    {/*     } */}
                    {/*     style={{ width: "100%" }} */}
                    {/*   > */}
                    {/*     <option value="none"></option> */}
                    {/*     {listUsers.map((option) => ( */}
                    {/*       <option key={option?.id} value={option?.id}> */}
                    {/*         {option?.username} */}
                    {/*       </option> */}
                    {/*     ))} */}
                    {/*   </select> */}
                    {/* </Td> */}
                    {/* <Td */}
                    {/*   style={{ minWidth: "230px" }} */}
                    {/*   onClick={(e) => { */}
                    {/*     e.preventDefault(); */}
                    {/*     e.stopPropagation(); */}
                    {/*   }} */}
                    {/* > */}
                    {/*   <select */}
                    {/*     value={ */}
                    {/*       selectedReviewer[item?.id] || item.reviewer || "" */}
                    {/*     } */}
                    {/*     className="form-control" */}
                    {/*     name={item?.id} */}
                    {/*     onChange={(e) => handleSelectReviewer(e, item?.id)} */}
                    {/*     disabled={ */}
                    {/*       !roleUser.is_staff || */}
                    {/*       item.status !== "ready_for_review" */}
                    {/*     } */}
                    {/*   > */}
                    {/*     <option value="none"></option> */}
                    {/*     {listUsers.map((option) => ( */}
                    {/*       <option key={option?.id} value={option?.id}> */}
                    {/*         {option?.username} */}
                    {/*       </option> */}
                    {/*     ))} */}
                    {/*   </select> */}
                    {/* </Td> */}
                    {/* <Td className="container-action"> */}
                    {/*   <div className="container-select"> */}
                    {/*     {handleAction(roleUser, item, index)} */}
                    {/*   </div> */}
                    {/* </Td> */}
                  </Tr>
                );
              })}
          </ResponsiveTables>
          <div className="d-flex justify-content-center">
            <Pagination
              name="companiesPage"
              totalRecords={count}
              pageLimit={PAGELIMIT}
              currentPage={page}
              setCurrentPage={setPage}
            />
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    list: state.Companies.list,
    count: state.Companies.count,
    error: state.Companies.error,
    assignError: state.Assign.errors,
    success: state.Assign.success,
    successStartOnboarding: state.Assign.successStartOnboarding,
    companySelected: state.Assign.companySelected,
    userId: state.Assign.userId,
    roleUser: state.Companies.role,
    loadingRole: state.Companies.loadingRole,
    listUsers: state.Users.list,
    successChangeOnBoarding: state.Assign.successChangeOnBoarding,
  };
};
export default connect(mapStateToProps, {
  fetchCompanies,
  assignRequest,
  assignReviewRequest,
  startOnBoardingRequest,
  startReviewRequest,
  restStateAssign,
  fetchUsers,
  changeOnBoardingRequest,
  changeReviewerRequest,
  setActiveCompany,
})(Dashboard);

Dashboard.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  fetchCompanies: PropTypes.func,
  assignRequest: PropTypes.func,
  assignReviewRequest: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.bool,
  roleUser: PropTypes.object,
  loadingRole: PropTypes.bool,
  successStartOnboarding: PropTypes.bool,
  successChangeOnBoarding: PropTypes.bool,
  startOnBoardingRequest: PropTypes.func,
  startReviewRequest: PropTypes.func,
  restStateAssign: PropTypes.func,
  fetchUsers: PropTypes.func,
  changeOnBoardingRequest: PropTypes.func,
  companySelected: PropTypes.number,
};
