import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { logOutUser } from "../../store/auth/logout/actions.js";

import {eraseCookie} from "../../utils"
class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
    this.updateActive();
  }
  updateActive() {
    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    [].forEach.call(items, (item) => {
      item.classList.remove("active");
      item.classList.remove("mm-active");
      item.classList.remove("mm-show");
    });
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }
  initMenu() {
    new MetisMenu("#side-menu");

    this.updateActive();
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>
            <li>
              <Link to="/#" className="waves-effect">
                <i className="bx bx-home-circle"></i>
                {/* <span className="badge badge-pill badge-info float-right">
                  03
                </span> */}
                <span>Dashboards</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dashboard">Companies</Link>
                </li>
                <li>
                  <Link to="/dashboard/myCompanies">My Companies</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Pages</li>
            {this.props.roleUser?.is_staff && (
              <li>
                <Link to="/users" className="waves-effect">
                  <i className="bx bxs-user-detail"></i>

                  <span>Users</span>
                </Link>
              </li>
            )}
            {this.props.roleUser?.is_staff && (
              <li>
                <Link to="/settings" className="waves-effect">
                  <i class="fas fa-cog"></i>
                  <span>Settings</span>
                </Link>
              </li>
            )}
            <li>
              <a
                onClick={() => {
                  eraseCookie("jwt")
                  localStorage.removeItem("user");
                  this.props.history.push("/");
                }}
                className=" waves-effect"
              >
                <i className="bx bxs-log-out"></i>
                <span>Logout</span>
              </a>
            </li>
            {/* <li>
              <Link to="chat" className=" waves-effect">
                <i className="bx bx-chat"></i>
                <span className="badge badge-pill badge-success float-right">
                  New
                </span>
                <span>Chat</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-store"></i>
                <span>Ecommerce</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="ecommerce-products">Products</Link>
                </li>
                <li>
                  <Link to="ecommerce-product-detail">Product Detail</Link>
                </li>
                <li>
                  <Link to="ecommerce-orders">Order</Link>
                </li>
                <li>
                  <Link to="ecommerce-customers">Customers</Link>
                </li>
                <li>
                  <Link to="ecommerce-cart">Cart</Link>
                </li>
                <li>
                  <Link to="ecommerce-checkout">Checkout</Link>
                </li>
                <li>
                  <Link to="ecommerce-shops">Shops</Link>
                </li>
                <li>
                  <Link to="ecommerce-add-product">Add Product</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SidebarContent);
