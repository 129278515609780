import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { closeSheetViewer } from "../../store/layout/SheetViewer/actions";
import Spreadsheet from "react-spreadsheet";
import * as XLSX from "xlsx";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import CSV from "comma-separated-values";
import DataGrid from "react-data-grid";
// redux actions
const SheetViewer = ({ sheetFile, isOpen, closeSheetViewer }) => {
  const [sheets, setSheets] = useState(null);
  const [currentSheet, setCurrentSheet] = useState(null);
  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (sheetFile) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: "binary",
        });
        const names = Object.keys(workbook.Sheets);
        const sheets = names.map((name) => {
          return {
            name: name,
            sheet: XLSX.utils.sheet_to_csv(workbook.Sheets[name]),
          };
        });
        const convertSheets = sheets.map((sheet) => {
          const rows = [];
          const columns = [];

          new CSV(sheet.sheet).forEach((array) => {
            if (columns.length < 1) {
              array.forEach((cell, idx) => {
                columns.push({
                  key: `key-${idx}`,
                  name: cell,
                  resizable: true,
                  sortable: true,
                  filterable: true,
                });
              });
            } else {
              const row = {};
              array.forEach((cell, idx) => {
                row[`key-${idx}`] = cell;
              });
              rows.push(row);
            }
          });
          return { name: sheet.name, columns: columns, rows: rows };
        });
        setSheets(convertSheets);
        if (convertSheets.length >= 1) {
          setCurrentSheet(convertSheets[0]);
        }
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(sheetFile);
    }
  }, [sheetFile]);

  useEffect(() => {
    setColumns(currentSheet?.columns);
    setRows(currentSheet?.rows);
  }, [currentSheet]);
  return (
    <div>
      {isOpen && (
        <>
          <div className="warper">
            <div className="close" onClick={() => closeSheetViewer()}>
              &times;
            </div>
            {rows && (
              <DataGrid
                columns={columns}
                rows={rows}
                style={{ minWidth: "100%", minHeight: "90%" }}
              />
            )}

            <div className="row">
              {sheets?.map((sheet) => {
                return (
                  <Button
                    className="p-1 m-1"
                    color="light"
                    onClick={() => setCurrentSheet(sheet)}
                  >
                    {sheet.name}
                  </Button>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isOpen: state.SheetViewerReducer.isOpen,
    sheetFile: state.SheetViewerReducer.sheetFile,
  };
};
export default connect(mapStateToProps, { closeSheetViewer })(SheetViewer);
