import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";
import profileIcon from "../../../assets/images/profile-icon.png";
import { getOnboarder, postOnboarder } from "../../../api/common";
import { handleErrorsAllMessage } from "../../../utils";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import CompleteButton from "../../CompleteButton";
import {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import ExitButton from "../../ExitButton";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";
import debounce from "lodash.debounce";
import CostCentersGroup from "./CostCenters";

const CostCenters = ({
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const [canSave, setCanSave] = useState(false);
  const [errors, setErrors] = useState([]);
  const forms = useRef([]);
  const [costCentersLines, setCostCentersLines] = useState([]);
  const [deletedCostCenters, setDeletedCostCenters] = useState([]);
  const onValidSubmit = (e, vals) => {};
  let save = debounce(() => {
    saveAccountingCategories(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    saveAccountingCategories(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveAccountingCategories(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    saveAccountingCategories(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  let saveAccountingCategories = (cb) => {
    let bodyCostCenter = {
      cost_centers: costCentersLines.filter((line) => line.updated),
      delete: deletedCostCenters,
    };
    if (bodyCostCenter.cost_centers.length || bodyCostCenter.delete.length) {
      setCanSave(false);
      showLoader();
      postOnboarder(
        `/UserManagement/${company?.id}/cost-center/submit`,
        bodyCostCenter
      )
        .then((data) => {
          setErrors({});
          removeLoader();
          setCanSave(true);
          data.forEach((line) => {
            line.updated = false;
            line.is_deletable = true;
          });
          setCostCentersLines(data);
        })
        .catch((error) => {
          removeLoader();
          setErrors(error);
          setCanSave(true);
          throw error;
        });
    } else {
      setErrors({});
      cb();
    }
  };

  const deleteRows = debounce(() => {
    setCostCentersLines((lines) => lines.filter((line) => !line.selected));
    setDeletedCostCenters(
      costCentersLines.filter((line) => line.selected).map((line) => line.id)
    );
  }, 200);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  return (
    <div className="container-fluid">
      <ActionHeader
        canSave={canSave}
        save={save}
        deleteRows={deleteRows}
        lines={[...costCentersLines.filter((line) => line.is_deletable)]}
        saveExit={saveExit}
      />
      <div className="">
        <Row>
          <Col>
            {handleErrorsAllMessage(errors) && (
              <Alert color="danger">{handleErrorsAllMessage(errors)}</Alert>
            )}
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Card className="">
              <CardBody>
                <CardTitle>Cost Center</CardTitle>
                <AvForm
                  className="needs-validation"
                  ref={(ref) => {
                    forms.current[0] = ref;
                  }}
                  onValidSubmit={onValidSubmit}
                >
                  <CostCentersGroup
                    key="cost_center"
                    type="cost_center"
                    lines={costCentersLines}
                    deleted={deletedCostCenters}
                    setLines={setCostCentersLines}
                    setDeleted={setDeletedCostCenters}
                    setCanSave={setCanSave}
                    form={forms.current[0]}
                  />
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ActionFooter
        next={next}
        canSave={canSave}
        back={back}
        complete={saveAccountingCategories}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return { company: state.CompanyState.activeCompany };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(CostCenters);
