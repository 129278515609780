import { GET_WIZARD, REST_STATE_WIZARD } from "./actionTypes";

const initialState = {
  error: "",
  wizardPage: {}
};

const wizard = (state = initialState, action) => {
  switch (action.type) {
    case GET_WIZARD:
      state = {
        ...state,
        wizardPage: {}
      };
      break;
    case REST_STATE_WIZARD:
      state = { ...initialState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default wizard;
