import {
  NEXT_STATE,
  PREVIOUS_STATE,
  RESET_STATE,
  SET_NEXT_STEP,
  SET_ACTIVE_COMPANY,
  SET_ACTIVE_COMPANY_IS_EDITABLE,
} from "./actionTypes.js";

const INIT_STATE = {
  states: [],
  currentStateIndex: -1,
  currentState: {},
  nextStep: null,
  activeCompany: null,
  activeCompanyIsEditable: false,
};
const WizardState = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NEXT_STATE:
      if (state.currentStateIndex < state.states.length - 1) {
        let currentStateIndex = state.currentStateIndex + 1;
        for (let i = currentStateIndex; i < state.states.length; i++) {
          if (state.states[i].status != "disabled") {
            currentStateIndex = i;
            break;
          }
        }
        return {
          ...state,
          currentStateIndex: currentStateIndex,
          currentState: state.states[currentStateIndex],
          canSave: false,
        };
      }
      return {
        ...state,
      };
    case PREVIOUS_STATE:
      if (state.currentStateIndex > 0) {
        let currentStateIndex = state.currentStateIndex - 1;
        for (let i = currentStateIndex; i >= 0; i--) {
          if (state.states[i].status != "disabled") {
            currentStateIndex = i;
            break;
          }
        }
        return {
          ...state,
          currentStateIndex: currentStateIndex,
          currentState: state.states[currentStateIndex],
          canSave: false,
        };
      }
      return {
        ...state,
      };
    case RESET_STATE:
      return {
        ...state,
        states: action.states,
        currentStateIndex: action.states.findIndex((state) => {
          return state.code === action.currentState;
        }),
        currentState: action.states.find((state) => {
          return state.code === action.currentState;
        }),
      };

    case SET_NEXT_STEP:
      return {
        ...state,
        nextStep: action.nextStep,
      };

    case SET_ACTIVE_COMPANY:
      return {
        ...state,
        activeCompany: action.company,
      };
    case SET_ACTIVE_COMPANY_IS_EDITABLE:
      return {
        ...state,
        activeCompanyIsEditable: action?.isEditable,
      };
    default:
      return state;
  }
};

export default WizardState;
