import { format } from "date-fns";
function getFilenameFromUrl(url) {
  const pathname = new URL(url).pathname;
  const index = pathname.lastIndexOf("/");
  return -1 !== index ? pathname.substring(index + 1) : pathname;
}

const handleErrorsAllMessage = (errors) => {
  if (typeof errors === "string" || errors instanceof String) {
    return errors;
  } else if (Array.isArray(errors)) {
    return errors.reduce((a, b) => {
      return a + handleErrorsAllMessage(b) + "\n";
    }, "");
  } else if (typeof errors === "object" || errors instanceof Object) {
    let errorsMessage = Object.entries(errors).map(([key, value]) => {
      if (key === "non_field_errors" || key === "detail") {
        return `${handleErrorsAllMessage(value)} \n`;
      } else {
        return `${key}:  ${handleErrorsAllMessage(value)} \n`;
      }
    });
    return errorsMessage.toString();
  }
};

const handleErrorsMessage = (errors) => {
  if (typeof errors === "string" || errors instanceof String) {
    return errors;
  } else if (Array.isArray(errors)) {
    return errors.reduce((a, b) => {
      return a + handleErrorsMessage(b) + "\n";
    }, "");
  } else if (typeof errors === "object" || errors instanceof Object) {
    let errorsMessage = Object.entries(errors).map(([key, value]) => {
      if (key === "non_field_errors" || key === "detail") {
        return `${handleErrorsMessage(value)} \n`;
      }
    });
    errorsMessage = errorsMessage.filter((error) => error);
    return errorsMessage.toString();
  }
};
const monthDiff = (d1, d2) => {
  if (d1 > d2) {
    d2 = [d1, (d1 = d2)][0];
  }
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};
const isLastDayOnMonth = (dt) => {
  var test = new Date(dt.getTime()),
    month = test.getMonth();

  test.setDate(test.getDate() + 1);
  return test.getMonth() !== month;
};

const isFirstDayOnMonth = (dt) => {
  var test = new Date(dt.getTime()),
    month = test.getMonth();

  test.setDate(test.getDate() - 1);
  return test.getMonth() !== month;
};
const formatNumber = (number) => {
  let formatedNumber = parseFloat(number);
  let res = number;
  if (!isNaN(number) && !isNaN(formatedNumber)) {
    res = formatedNumber.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  }
  return res;
};
const formatDate = (date) => {
  if (date) return format(new Date(date), "dd/MM/yyyy");
  else return "";
};

const formatDateTime = (date) => {
  console.log(date);
  if (date)
    return format(
      new Date(
        new Date(date).toLocaleString("en-US", {
          timeZone: "Asia/Dubai",
        })
      ),
      "dd/MM/yyyy hh:mm:ss a"
    );
  else return "";
};
const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
export {
  getFilenameFromUrl,
  handleErrorsMessage,
  handleErrorsAllMessage,
  monthDiff,
  isLastDayOnMonth,
  isFirstDayOnMonth,
  formatNumber,
  formatDate,
  formatDateTime,
  setCookie,
  getCookie,
  eraseCookie,
};
