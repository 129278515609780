import React, { useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";

const Login = (props) => {
  const history = useHistory();
  const { error, loginUser, apiError } = props;
  const handleValidSubmit = (event, values) => {
    loginUser(values, history);
  };
  useEffect(() => {
    apiError("");
    return () => {
      history.push({ requireLogin: false });
    };
  }, []);

  return (
    <>
      <div className="account-pages my-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div>
                <Row className="justify-content-center">
                  <div className="mb-4">
                    <img src={logo} alt="" height="100" />
                  </div>
                </Row>
                <Row className="justify-content-center">
                  <div className="text-primary">
                    <h5 className="text-primary">
                      Welcome to the On-boarding Wizard!
                    </h5>
                  </div>
                </Row>
              </div>
              <Card className="overflow-hidden">
                <CardBody className="pt-12">
                  <div>
                    <Link to="/"></Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleValidSubmit}
                      autoComplete={true}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      {history?.location?.state?.requireLogin ? (
                        <Alert color="danger">login to continue</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="userName"
                          label="User name"
                          className="form-control"
                          placeholder="Enter user name"
                          type="text"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="custom-control custom-checkbox">
                        <AvInput
                          type="checkbox"
                          name="rememberMe"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-4">
                        <Link to="/forgetPassword" className="text-muted">
                          <i className="mdi mdi-lock mr-1"></i> Forgot your
                          password?
                        </Link>
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} The Accounter Technologies Ltd.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default connect(mapStateToProps, { loginUser, apiError })(Login);
