import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  FormGroup,
  Alert,
  Card,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import { getOnboarder, putOnboarder } from "../../../api/common";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import ShareEquityLine from "./shareEquityLine";
import { handleErrorsMessage, formatDate } from "../../../utils";
import {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import debounce from "lodash.debounce";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";
import Max2DigitsNumberInput from "../../Inputs/Max2DigitsNumberInput";
import NumberInput from "../../Inputs/NumberInput";
import { formatNumber } from "../../../utils";

const ShareEquity = ({
  type,
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const [canSave, setCanSave] = useState(false);
  const [errors, setErrors] = useState([]);
  const [lines, setLines] = useState([]);
  const [totalNumberOfShare, setTotalNumberOfShare] = useState(0);
  const [companyCapital, setCompanyCapital] = useState(0);
  const [equity, setEquity] = useState(null);

  const form = useRef(null);
  const form_equity = useRef(null);

  const onValidSubmit = (e, vals) => {};

  let save = debounce(() => {
    saveDebits(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    saveDebits(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveDebits(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    saveDebits(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  const getData = async () => {
    showLoader();
    const data = await getOnboarder(
      `/UserManagement/${company?.id}/shareholders-equity/`
    );
    data.forEach((line) => {
      line.updated = false;
    });
    setLines(data);
    const data_equit = await getOnboarder(
      `/UserManagement/${company?.id}/equity/`
    );
    data_equit.updated = false;
    setEquity(data_equit);
    removeLoader();
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  let saveDebits = (cb) => {
    setCanSave(false);
    const updatdLines = lines.filter((line) => {
      return line.updated;
    });
    if (updatdLines.length || equity.updated) {
      showLoader();
      putOnboarder(
        `/UserManagement/${company?.id}/shareholders-equity/`,
        updatdLines
      )
        .then((data) => {
          setErrors([]);
          data.forEach((line) => {
            line.updated = false;
          });
          setLines(data);

          putOnboarder(`/UserManagement/${company?.id}/equity/`, equity)
            .then((data) => {
              setErrors([]);
              data.updated = false;
              setEquity(data);
              cb();
              setCanSave(true);
              removeLoader();
            })
            .catch((error) => {
              setErrors(error);
              removeLoader();
              setCanSave(true);
              throw error;
            });
        })
        .catch((error) => {
          setErrors(error);
          removeLoader();
          setCanSave(true);
          throw error;
        });
    } else {
      setErrors([]);
      cb();
    }
  };

  useEffect(() => {
    if (form.current) {
      form.current.setTouched(Object.keys(form.current._inputs));
      const values = form.current.getValues();
      form.current.validateAll(values).then((errors) => {
        if (form.current) {
          setCanSave(
            !form.current?.hasError() && !form_equity.current?.hasError()
          );
        }
      });
    }

    if (form_equity.current) {
      form_equity.current.setTouched(Object.keys(form_equity.current._inputs));
      const values = form_equity.current.getValues();
      form_equity.current.validateAll(values).then((errors) => {
        if (form_equity.current) {
          setCanSave(
            !form.current.hasError() && !form_equity.current.hasError()
          );
        }
      });
    }
    const numberOfShares =
      lines.reduce((sum, line) => {
        return sum + parseFloat(line?.number_of_shares || 0);
      }, 0) || 0.0;
    setTotalNumberOfShare(numberOfShares);
    setCompanyCapital(equity?.share_par_value * numberOfShares);
  }, [lines, equity]);

  useEffect(() => {
    const linesCopy = lines.slice();
    linesCopy.forEach((line) => {
      line.shareholding_percentage =
        (parseFloat(line?.number_of_shares || 0) / totalNumberOfShare) * 100;
    });
    setLines(linesCopy);
  }, [totalNumberOfShare]);

  const handleOnChangeNumber = (value, name) => {
    let vals = { ...equity };
    vals[name] = value || null;
    vals.updated = true;
    setEquity(vals);
  };
  return (
    <div className="container-fluid">
      <ActionHeader canSave={canSave} save={save} saveExit={saveExit} />
      <div className="">
        <Row>
          <Col>
            {handleErrorsMessage(errors) && (
              <Alert color="danger">{handleErrorsMessage(errors)}</Alert>
            )}
          </Col>
        </Row>
        <Card style={{ marginBottom: "0px" }}>
          <CardTitle>
            <h3>Account Balance as Of: {formatDate(company.starting_date)}</h3>
            <u>Company Shareholders Equity</u>
          </CardTitle>
          <CardBody
            className="table-container-input-without-title-total"
            style={{ paddingBottom: "0px" }}
          >
            <Row>
              <AvForm
                className="needs-validation"
                ref={form}
                onValidSubmit={onValidSubmit}
              >
                <Table>
                  <thead>
                    <tr>
                      <th>Shareholder Name</th>
                      <th>Number of Shares Held</th>
                      <th>Shareholding Percentage</th>
                      <th>Owner's Account Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lines.map((line, index) => {
                      return (
                        <ShareEquityLine
                          key={index}
                          index={index}
                          line={line}
                          setLines={setLines}
                          lines={lines}
                        />
                      );
                    })}
                    <tr>
                      <td></td>
                      <td>
                        Total Number of Share:{" "}
                        {formatNumber(totalNumberOfShare)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </AvForm>
            </Row>
            <hr />
            {equity && (
              <AvForm
                className="needs-validation"
                ref={form_equity}
                onValidSubmit={onValidSubmit}
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Max2DigitsNumberInput
                        name={`share_par_value`}
                        label="Share par value"
                        onChangeValue={handleOnChangeNumber}
                        value={equity?.share_par_value}
                        disabled={!activeCompanyIsEditable}
                        grid={{ xs: 7 }}
                      />
                      {!!errors?.share_par_value && (
                        <Alert color="danger">
                          {errors?.share_par_value[0]}
                        </Alert>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Max2DigitsNumberInput
                        name={`legal_reserve`}
                        label="Legal Reserve"
                        onChangeValue={handleOnChangeNumber}
                        value={equity?.legal_reserve?.toString() || ""}
                        disabled={!activeCompanyIsEditable}
                        grid={{ xs: 7 }}
                        required={false}
                      />
                      {!!errors?.legal_reserve && (
                        <Alert color="danger">{errors?.legal_reserve[0]}</Alert>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="5">
                        <label>Company Capital</label>
                      </Col>
                      <Col md="7">
                        <span>{formatNumber(companyCapital || 0)} AED</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Max2DigitsNumberInput
                        name={`general_other_reserve`}
                        label="General & Other Reserve"
                        onChangeValue={handleOnChangeNumber}
                        value={equity?.general_other_reserve?.toString() || ""}
                        disabled={!activeCompanyIsEditable}
                        grid={{ xs: 7 }}
                        required={false}
                      />
                      {!!errors?.general_other_reserve && (
                        <Alert color="danger">
                          {errors?.general_other_reserve[0]}
                        </Alert>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <NumberInput
                        name={`current_profit_loss`}
                        label="Current Period Net Profit/Loss"
                        value={equity?.current_profit_loss?.toString() || ""}
                        onChangeValue={handleOnChangeNumber}
                        grid={{ xs: 7 }}
                        disabled={!activeCompanyIsEditable}
                        required={false}
                      />
                      {!!errors?.current_profit_loss && (
                        <Alert color="danger">
                          {errors?.current_profit_loss[0]}
                        </Alert>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <NumberInput
                        name={`retained_earning_loss`}
                        label="Retained Earnings/Loss"
                        value={equity?.retained_earning_loss?.toString() || ""}
                        onChangeValue={handleOnChangeNumber}
                        grid={{ xs: 7 }}
                        disabled={!activeCompanyIsEditable}
                        required={false}
                      />
                      {!!errors?.retained_earning_loss && (
                        <Alert color="danger">
                          {errors?.retained_earning_loss[0]}
                        </Alert>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
            )}
          </CardBody>
        </Card>
      </div>
      <ActionFooter
        next={next}
        canSave={canSave}
        back={back}
        complete={false}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(ShareEquity);
