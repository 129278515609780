import React, { useState } from "react";
import { connect } from "react-redux";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  FormGroup,
  Label,
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import { handleErrorsMessage } from "../../utils";
import { postOnboarder } from "../../api/common";

const AddReminder = ({ company, currentState }) => {
  const [errors, setErrors] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setErrors({});
  };

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const onValidSubmit = (e, vals) => {
    vals["wizard_state"] = currentState.code;
    postOnboarder(`/UserManagement/${company.id}/reminder`, vals)
      .then(() => {
        toggle();
        setErrors({});
        toast.success(
          `a reminder on ${currentState.name} has been added to your list of reminders`
        );
      })
      .catch((error) => {
        setErrors(error);
        throw error;
      });
  };
  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <AvForm onValidSubmit={onValidSubmit}>
          <ModalHeader toggle={toggle} close={closeBtn}>
            Add Reminder
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        id="statusfield"
                        type="select"
                        name="status"
                        value="open"
                        label="Status"
                      >
                        <option key="open" value="open">
                          Open
                        </option>
                        <option key="in_progress" value="in_progress">
                          In-Progress
                        </option>
                        <option key="closed" value="closed">
                          Closed
                        </option>
                      </AvField>
                      {!!errors?.status && (
                        <Alert color="danger">{errors?.status[0]}</Alert>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="description*">Notes</Label>
                      <AvInput
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder="description"
                        rows="10"
                        required
                      />
                      {!!errors?.description && (
                        <Alert color="danger">{errors?.description[0]}</Alert>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Add New
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>

      <Button
        type="button"
        color="primary"
        style={{
          marginRight: 10,
        }}
        title="Add Reminder"
        onClick={toggle}
      >
        Add Reminder
      </Button>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    currentState: state.CompanyState.currentState,
  };
};
export default connect(mapStateToProps, {})(AddReminder);
