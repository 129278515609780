import React, { useState } from "react";
import { connect } from "react-redux";
import WrapperContainer from "../../components/WrapperContainer";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";

const SelectUserOnFly = ({
  company,
  listUsers,
  roleUser,
  handleOnchange,
  label,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openWrapper = () => {
    setIsOpen(!isOpen);
  };
  return (
    <React.Fragment>
      <i
        class="fas fa-user-plus"
        onClick={openWrapper}
        title={`Assign ${label}`}
      ></i>
      <WrapperContainer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div style={{ width: "200px" }}>
          <FormGroup>
            <Label htmlFor={company?.id}>{label}</Label>
            <Select
              value={
                (label === "Onboarder" ? company.user : company.reviewer) || ""
              }
              name={company?.id}
              onChange={(newValue, actionMeta) => {
                handleOnchange(newValue, company?.id);
                setIsOpen(false);
              }}
              isSearchable
              isDisabled={!roleUser.is_staff}
              options={listUsers
                .filter(
                  (user) =>
                    (label === "Onboarder" && user.is_onboarder) ||
                    (label === "Reviewer" && user.is_reviewer)
                )
                .map((option) => ({
                  label: option?.username,
                  value: option?.id,
                }))}
            />
          </FormGroup>
        </div>
      </WrapperContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    roleUser: state.Companies.role,
    listUsers: state.Users.list,
  };
};
export default connect(mapStateToProps, {})(SelectUserOnFly);
