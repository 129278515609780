import React, { useState, useEffect } from "react";
import Board, { moveCard } from "@asseinfo/react-kanban";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { getOnboarder, putOnboarder } from "../../api/common";
import "@asseinfo/react-kanban/dist/styles.css";
import "./style.css";
import DefaultCard from "./DefaultCard";
import { resetState } from "../../store/CompanyState/actions.js";
import { showLoader, removeLoader } from "../../store/Loader/actions";
const STATUS = ["open", "in_progress", "closed"];
const Reminders = ({ showLoader, removeLoader, roleUser }) => {
  const params = useParams();
  const [company, setCompany] = useState();
  const [cards, setCards] = useState([]);
  const [board, setBoard] = useState(false);
  const companyId = params.company_id;
  const getState = (states, state) => {
    return states.find((stateF) => stateF.code === state).name;
  };
  const getData = async () => {
    try {
      showLoader();
      const data = await getOnboarder(`/UserManagement/company/${companyId}`);
      setCompany(data);
      const cards = await getOnboarder(`/UserManagement/${companyId}/reminder`);
      setBoard({
        columns: [
          {
            id: 0,
            title: "Open",
            cards: cards
              .filter((card) => card.status === "open")
              .map((card) => {
                return {
                  id: card.id,
                  title: `${getState(data.wizard_states, card.wizard_state)}`,
                  description: card.description,
                  status: card.status,
                };
              }),
          },
          {
            id: 1,
            title: "In-Progress",
            cards: cards
              .filter((card) => card.status === "in_progress")
              .map((card) => {
                return {
                  id: card.id,
                  title: `${getState(data.wizard_states, card.wizard_state)}`,
                  description: card.description,
                  status: card.status,
                };
              }),
          },
          {
            id: 2,
            title: "Closed",
            cards: cards
              .filter((card) => card.status === "closed")
              .map((card) => {
                return {
                  id: card.id,
                  title: `${getState(data.wizard_states, card.wizard_state)}`,
                  description: card.description,
                  status: card.status,
                };
              }),
          },
        ],
      });
      removeLoader();
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    getData();
  }, [companyId]);
  const handleChangeStatus = async (card, source, destination) => {
    try {
      if (destination) {
        setBoard(moveCard(board, source, destination));
        const vals = { status: STATUS[destination.toColumnId] };
        const data = await putOnboarder(
          `/UserManagement/reminder/${card.id}`,
          vals
        );
      }
    } catch (error) {
      throw error;
    }
  };
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Col>
          <Card style={{ minHeight: "400px" }}>
            <CardTitle>Reminders Added On {company?.name}</CardTitle>
            <CardBody>
              {board && (
                <Board
                  disableColumnDrag
                  disableCardDrag={roleUser.id != company.user}
                  onCardDragEnd={handleChangeStatus}
                  renderCard={(content, { removeCard, dragging }) => {
                    return (
                      <DefaultCard
                        dragging={dragging}
                        card={content}
                        company={company}
                        afterSave={() => {
                          getData();
                        }}
                      ></DefaultCard>
                    );
                  }}
                >
                  {board}
                </Board>
              )}
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roleUser: state.Companies.role,
  };
};
export default connect(mapStateToProps, {
  showLoader,
  removeLoader,
})(Reminders);
