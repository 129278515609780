import React, { useState, useRef } from "react";
import { AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { FormGroup, Button } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import Max2DigitsPositiveNumberInput from "../../Inputs/Max2DigitsPositiveNumberInput";
import { postOnboarder } from "../../../api/common";
import { Tr, Td } from "react-super-responsive-table";

const DebitLine = ({
  index,
  line,
  setLines,
  lines,
  accounts,
  setAccounts,
  company,
  activeCompanyIsEditable,
  setCanSave,
}) => {
  const [errorAccount, setErrorAccount] = useState(null);
  const errorAccountDiv = useRef(null);
  const onChangeLine = (line) => {
    if (
      line.deposits_type == "deposits" ||
      line?.account?.group == "Other Current Assets"
    ) {
      line.maturity = "less_than_year";
    } else if (line?.account?.group == "Long Term Asset") {
      line.maturity = "more_than_year";
    }
  };
  const handleOnChangeInput = (e) => {
    if (e.target.name.split("-")[0] == "deposits_type") {
      line["account"] = "";
    }
    line[e.target.name.split("-")[0]] = e.target.value;
    onChangeLine(line);
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };

  const handleOnChangeNumber = (value, name) => {
    line[name.split("-")[0]] = value;
    line.updated = true;
    let items = [...lines];
    onChangeLine(line);
    items[index] = line;
    setLines(items);
  };
  const handleOnDelete = (e) => {
    line.selected = e.target.checked;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const createOption = (option) => ({ label: option?.name, value: option?.id });
  const handleChange = (newValue, actionMeta) => {
    if (newValue) {
      line[actionMeta.name.split("-")[0]] = newValue.value;
      line.updated = true;
      const account = accounts.filter((account) => {
        return account.id == line.account;
      })[0];
      if (account?.group == "Other Current Assets") {
        line.maturity = "less_than_year";
      } else if (account?.group == "Long Term Asset") {
        line.maturity = "more_than_year";
      }
      let items = [...lines];
      items[index] = line;
      setLines(items);
    }
  };
  const handleCreate = async (inputValue, actionMeta) => {
    if (inputValue.length > 30) {
      errorAccountDiv.current.style.display = "block";
      setErrorAccount("maximum number of chars 30");
      line.account = null;
      let items = [...lines];
      setLines(items);
      setCanSave(false);
    } else if (!line.deposits_type) {
      errorAccountDiv.current.style.display = "block";
      setErrorAccount("please select type first");
      line.account = null;
      let items = [...lines];
      setLines(items);
      setCanSave(false);
      line.account = null;
    } else {
      errorAccountDiv.current.style.display = "none";
      await postOnboarder(`/UserManagement/${company?.id}/accounts`, [
        {
          name: inputValue,
          group:
            line.deposits_type === "deposits"
              ? "Deposits"
              : "Other Current Assets",
        },
      ]).then((data) => {
        setAccounts([...accounts, data[0]]);
        line["account"] = data[0].id;
        line.updated = true;
        let items = [...lines];
        items[index] = line;
        setLines(items);
        setCanSave(true);
      });
    }
  };
  return (
    <Tr>
      <Td style={{ minWidth: "10px" }}>
        <FormGroup hidden>
          <AvField name="id" type="text" value={line?.id || ""} />
        </FormGroup>

        <FormGroup>
          <AvField
            type="checkbox"
            name="selected"
            checked={line?.selected || ""}
            onChange={handleOnDelete}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            type="select"
            name={`deposits_type-${index}`}
            value={line?.deposits_type || ""}
            onChange={handleOnChangeInput}
            errorMessage="Enter Type"
            validate={{
              required: { value: true },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            <option key="deposits" value="deposits">
              Deposit
            </option>
            <option key="other" value="other">
              Other
            </option>
            ))}
          </AvField>
        </FormGroup>
      </Td>
      <Td style={{ minWidth: "250px" }}>
        <FormGroup>
          <CreatableSelect
            isClearable
            name={`account-${index}`}
            isDisabled={!activeCompanyIsEditable}
            onChange={handleChange}
            onCreateOption={handleCreate}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            options={accounts
              .filter((account) => {
                if (line.deposits_type === "deposits") {
                  return account.group === "Deposits";
                } else if (line.deposits_type === "other") {
                  return (
                    account.group === "Other Current Assets" ||
                    account.group === "Long Term Asset"
                  );
                } else {
                  return false;
                }
              })
              .map((option) => createOption(option))}
            value={
              accounts
                .filter((account) => account.id == line?.account)
                .map((account) => createOption(account)) || ""
            }
          />
          <div class="invalid-feedback text-danger" ref={errorAccountDiv}>
            {errorAccount}
          </div>
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            type="select"
            name={`maturity-${index}`}
            value={line?.maturity || ""}
            onChange={handleOnChangeInput}
            errorMessage="Enter Maturity"
            validate={{
              required: { value: true },
            }}
            disabled={
              line.deposits_type == "deposits" ||
              accounts.filter((account) => {
                return account.id == line.account;
              })[0]?.code ||
              !activeCompanyIsEditable
            }
          >
            <option></option>
            <option key="less_than_year" value="less_than_year">
              Less Than Year
            </option>
            <option key="more_than_year" value="more_than_year">
              More Than Year
            </option>
            ))}
          </AvField>
        </FormGroup>
      </Td>

      <Td>
        <FormGroup>
          <Max2DigitsPositiveNumberInput
            name={`amount-${index}`}
            type="text"
            value={line?.amount || ""}
            onChangeValue={handleOnChangeNumber}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(DebitLine);
