import React, {useState} from 'react'
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
} from "reactstrap";

const DropDownList = ({children}) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropDown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
            <DropdownToggle tag="span">
                <i
                    className="fas fa-ellipsis-v"
                    state="other_assets"
                    style={{cursor: "pointer"}}
                ></i>
            </DropdownToggle>
            <DropdownMenu left>
                {children}
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropDownList
