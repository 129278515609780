import React, {useRef, useEffect, useState} from 'react'
import "./style.css"
const WrapperContainer = ({children, isOpen, setIsOpen}) => {
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <React.Fragment>
      {isOpen &&
        <div className="wrapper-container" ref={ref}>
          <div>
            {children}
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default WrapperContainer
