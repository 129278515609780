import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Tr, Td } from "react-super-responsive-table";
import { format } from "date-fns";
import { fetchUsers } from "../../store/auth/Users/actions";
import { openPdfViewer } from "../../store/layout/PdfViewer/actions.js";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
} from "reactstrap";
import { getOnboarder } from "../../api/common";
import HistoryCard from "../../components/History/HistoryCard";

import ResponsiveTables from "../../components/Table";
import Pagination from "../../components/Pagination";
import { formatDateTime } from "../../utils";
import "./style.css";
const PAGELIMIT = 20;

const headCells = [
  {
    id: "agreement",
    label: "Agreement",
  },
  {
    id: "created_at",
    label: "Created Date",
  },

  {
    id: "send_at",
    label: "Send Date",
  },

  {
    id: "status",
    label: "Status",
  },

  {
    id: "action_date",
    label: "Action Date",
  },
  {
    id: "owner",
    label: "Owner",
  },
  {
    id: "user",
    label: "User",
  },
];
const History = ({ company, listUsers, fetchUsers, openPdfViewer }) => {
  const params = useParams();
  const [histories, setHistories] = useState([]);
  const [agreementHistories, setAgreementHistories] = useState([]);
  const [count, setCount] = useState(1);
  const [activeTab, setActiveTab] = useState("1");
  const [page, setPage] = useState(1);

  const companyId = params.company_id;
  const getHistory = () => {
    getOnboarder(
      `/UserManagement/company/${companyId}/history?page=${page}`
    ).then((data) => {
      setHistories(data["results"]);
      setCount(data["count"]);
    });
    getOnboarder(`/UserManagement/${companyId}/agreement`).then((data) => {
      setAgreementHistories(data);
    });
  };
  useEffect(() => {
    if (page) getHistory();
  }, [page]);
  useEffect(() => {
    if (!listUsers?.length) fetchUsers();
  }, []);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab == "1" ? "active" : ""}
              onClick={() => setActiveTab("1")}
            >
              Company History
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab == "2" ? "active" : ""}
              onClick={() => setActiveTab("2")}
            >
              Agreement History
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Col>
              <Card>
                <CardBody>
                  <div className="history-title">Company History</div>
                  <div className="history-list-page">
                    <div className="list-group">
                      {histories.map((history, index) => {
                        return <HistoryCard history={history} />;
                      })}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      name="HistoryPage"
                      totalRecords={count}
                      pageLimit={PAGELIMIT}
                      currentPage={page}
                      setCurrentPage={setPage}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className="history-title">Agreement History</div>
                    <ResponsiveTables headCells={headCells} title="">
                      {agreementHistories.map((history, index) => {
                        return (
                          <Tr>
                            <Td>
                              <Button
                                color="link"
                                onClick={() => {
                                  openPdfViewer(history.agreement, "agreement");
                                }}
                              >
                                View
                              </Button>
                            </Td>
                            <Td>{formatDateTime(history?.created_at)}</Td>
                            <Td>
                              {history?.send_date &&
                                formatDateTime(history?.send_date)}
                            </Td>
                            <Td>{history.status}</Td>

                            <Td>
                              {history?.action_date &&
                                formatDateTime(history?.action_date)}
                            </Td>
                            <Td>
                              {company?.owner?.first_name +
                                " " +
                                company?.owner?.last_name}
                            </Td>
                            <Td>
                              {
                                listUsers.filter((user) => {
                                  return user.id == history?.user;
                                })[0]?.username
                              }
                            </Td>
                          </Tr>
                        );
                      })}
                    </ResponsiveTables>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    listUsers: state.Users.list,
    company: state.CompanyState.activeCompany,
  };
};

export default connect(mapStateToProps, {
  fetchUsers,
  openPdfViewer,
})(History);
