import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "reactstrap";
// @material-ui/core components
// redux actions
import { openImageViewer } from "../../store/layout/ImageViewer/actions.js";
import { openPdfViewer } from "../../store/layout/PdfViewer/actions.js";
// core components
import { convertToBase64 } from "./helper";
import "./styles.css";

const FileUpload = ({
  currentFile,
  openImageViewer,
  openPdfViewer,
  title,
  subtitle,
  nameField,
  handleOnChange,
  disabled,
}) => {
  const [file, setFile] = React.useState(currentFile);
  const [error, setError] = React.useState(false);
  const [errorSize, setErrorSize] = React.useState(false);

  const handleSpecificPDFFile = (event) => {
    if (event.target.files.length === 0) return;
    if (
      /\.(jpg|jpeg|jpe|png|webp|svg|pdf)$/i.test(event.target.files[0].name)
    ) {
      let fileSize = Math.round(event.target.files[0].size / 1024);
      if (fileSize <= 10000) {
        setError(false);
        setErrorSize(false);
        let currentfile = event.target.files[0];
        handleOnChange(currentfile);
        setFile(currentfile);
      } else {
        setErrorSize(true);
        setError(false);
      }
    } else {
      setError(true);
      setErrorSize(false);
    }
  };
  useEffect(() => {
    setFile(currentFile);
  }, [currentFile]);
  return (
    <div className="filecontainer">
      <div className="imagesContainer">
        {file && (
          <div>
            <a
              href="/"
              onClick={(event) => {
                event.preventDefault();
                if (file.type == "application/pdf") {
                  const url = window.URL.createObjectURL(file);
                  openPdfViewer(url, file.name);
                } else {
                  convertToBase64(file, (result) => {
                    openImageViewer(result);
                  });
                }
              }}
            >
              {file.name}
            </a>
            {!disabled && (
              <Button
                color="link"
                onClick={(e) => {
                  handleOnChange("");
                }}
                style={{ color: "red" }}
              >
                X
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="columnFlexContainer">
        <h3 className="lightFontTitle">{title}</h3>
        <p className="lightFont">{subtitle || "Upload PDF File or Images"}</p>

        <input
          type="file"
          id={title}
          label={title}
          name={nameField}
          onChange={handleSpecificPDFFile}
          margin="normal"
          variant="outlined"
          style={{ display: "none" }}
          className="textFieldFile"
          disabled={disabled}
        />
        <label htmlFor={title} className="textFieldFile">
          <span>Upload</span>
        </label>
      </div>

      {error && !errorSize && (
        <p style={{ marginLeft: "48px", color: "red" }}>
          Upload a pdf or images
        </p>
      )}
      {errorSize && !error && (
        <p style={{ marginLeft: "48px", marginTop: "10px" }}>
          Upload a .pdf with max size of 10MB
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  openImageViewer,
  openPdfViewer,
})(FileUpload);
