import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, useRouteMatch } from "react-router-dom";
import Companies from "../Companies";
import MyCompanies from "../MyCompanies";
import Company from "../Company";
import Reminders from "../Reminders";
import { fetchRole } from "../../store/auth/Companies/actions";
// fetchRole;
function Dashboard(props) {
  let match = useRouteMatch();
  const { fetchRole } = props;

  useEffect(() => {
    fetchRole();
  }, []);

  return (
    <>
      <Route
        exact
        path={match.path}
        render={(routeProps) => <Companies {...routeProps} />}
      />
      <Route
        exact
        path={[`${match.path}/myCompanies`]}
        render={(routeProps) => <MyCompanies {...routeProps} />}
      />
      <Route
        exact
        path={[
          `${match.path}/Reminders/:company_id`,
          `${match.path}/MyCompanies/Reminders/:company_id`,
        ]}
        render={(routeProps) => <Reminders {...routeProps} />}
      />
      <Route
        path={[
          `${match.path}/createCompany`,
          `${match.path}/company/:company_id`,
          `${match.path}/myCompanies/company/:company_id`,
        ]}
        component={Company}
      />
    </>
  );
}

export default connect(null, { fetchRole })(Dashboard);
Dashboard.propTypes = {
  match: PropTypes.object,
  fetchRole: PropTypes.func,
};
