import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  CREATE_USER,
  API_ERROR,
  CREATE_USER_SUCCESS,
  REST_ERROR
} from "./actionTypes";

const initialState = {
  errors: {},
  list: [],
  loading: false,
  error: ""
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS:
      state = {
        ...state,
        loading: true
      };
      break;
    case FETCH_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        list: action.payload
      };
      break;
    case CREATE_USER:
      state = {
        ...state,
        loading: true
      };
      break;
    case CREATE_USER_SUCCESS:
      state = {
        ...state,
        loading: false
      };
      break;
    case API_ERROR:
      if (typeof action.payload === "object") {
        state = { ...state, errors: action.payload, loading: false };
      } else {
        state = { ...state, error: action.payload, loading: false };
      }
      break;
    case REST_ERROR:
      state = { ...state, errors: {}, error: "" };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default users;
