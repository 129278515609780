import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Alert,
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import AddNew from "./AddNew";
import { getUsersAndEmployees } from "../../../api/usersAndEmployees";
import { getOnboarder, postOnboarder } from "../../../api/common";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import { deleteOnboarder } from "../../../api/common";
import {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";

const UsersAndEmployees = ({
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const [canSave, setCanSave] = useState(false);
  const [modal, setModal] = useState(false);
  const [parent, setParent] = useState(false);
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [deleteLines, setDeleteLines] = useState([]);
  const [line, setLine] = useState({});

  const toggle = () => {
    if (modal) {
      getUsersAndEmployees(`/${company?.id}/employee`).then((data) => {
        setUsers(data);
      });
      setParent(null);
    }
    setModal(!modal);
  };

  const handleAddNewModal = (e) => {
    setLine(null);
    toggle();
  };
  const handleAddNewLine = (user) => {
    setUsers((users) => [...users, user]);
  };

  let saveEmployees = (cb) => {
    let body = {
      delete: deleteLines || [],
    };
    if (body.delete.length > 0) {
      postOnboarder(`/UserManagement/${company?.id}/employee/submit`, body)
        .then((data) => {
          setUsers(data);
          cb();
        })
        .catch((error) => {
          setErrors(error);
          throw error;
        });
    } else {
      cb();
    }
  };

  let save = debounce(() => {
    saveEmployees(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    saveEmployees(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveEmployees(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    saveEmployees(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);

  useEffect(() => {
    showLoader();
    getUsersAndEmployees(`/${company?.id}/employee`)
      .then((data) => {
        setUsers(data);
        removeLoader();
      })
      .catch(() => {
        removeLoader();
      });
    setCanSave(true);
  }, []);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  const checkIcon = (flag) => {
    return flag ? (
      <i className="fas fa-check"></i>
    ) : (
      <i className="fas fa-times"></i>
    );
  };
  const onEdit = (ev) => {
    const user_id = parseInt(ev.target.getAttribute("id"));
    const user = users.find((x) => x.id === user_id);
    setLine(user);
    toggle();
  };

  const onDelete = (ev) => {
    const user_id = parseInt(ev.target.getAttribute("id"));
    deleteOnboarder(`/UserManagement/employee/${user_id}`);

    getUsersAndEmployees(`/${company?.id}/employee`).then((data) => {
      setUsers(data);
    });
  };
  const deleteRows = debounce(() => {
    setUsers(users.filter((user) => !user.selected));
    setDeleteLines(
      users
        .filter((line) => line.selected && line?.employee_type !== "owner")
        .map((line) => line.id)
    );
  }, 200);
  const toggleSelectAll = (e) => {
    const checked = e.target.checked;
    let items = [...users];
    items.forEach((item) => {
      if (item.employee_type !== "owner") item.selected = checked;
    });
    setUsers(items);
  };
  const handleOnDelete = (e) => {
    let items = [...users];
    items.forEach((item) => {
      if (item.id == e.target.name) {
        item.selected = e.target.checked;
      }
    });
    setUsers(items);
  };
  return (
    <div className="container-fluid">
      <ActionHeader
        canSave={canSave}
        save={save}
        deleteRows={deleteRows}
        lines={users.filter((line) => line.employee_type !== "owner")}
        saveExit={saveExit}
      />
      <Card style={{ marginBottom: "0rem" }}>
        <CardBody style={{ padding: "0.75rem", paddingBottom: "0.75rem" }}>
          <AddNew
            modal={modal}
            toggle={toggle}
            handleAddNewLine={handleAddNewLine}
            parent={parent}
            line={line}
          />
          <Row>
            <div className="container-fluid">
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <div></div>
              </div>
            </div>
          </Row>
          <AvForm className="needs-validation">
            <div className="table-container-input-without-title-total">
              <Table className="table-responsive-input">
                <Thead>
                  <Tr>
                    <Th style={{ minWidth: "10px" }}>
                      <FormGroup>
                        <AvField
                          type="checkbox"
                          name="selected"
                          onChange={toggleSelectAll}
                          disabled={!activeCompanyIsEditable}
                        />
                      </FormGroup>
                    </Th>
                    <Th>#</Th>
                    <Th>First Name</Th>
                    <Th>Last Name</Th>
                    <Th>Mobile</Th>
                    <Th>Email</Th>
                    <Th>User Type</Th>
                    <Th>User Employee</Th>
                    <Th>Co-Owner</Th>
                    <Th>Needs Approval(U)</Th>
                    <Th>Needs Approval(P.V)</Th>
                    <Th>View Reports</Th>
                    <Th colspan="2">Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.map((user, cnt) => {
                    return (
                      <Tr key={user.id}>
                        <Td>
                          <FormGroup>
                            <AvField
                              type="checkbox"
                              name={user?.id || "selected"}
                              checked={user?.selected || ""}
                              onChange={handleOnDelete}
                              disabled={
                                !activeCompanyIsEditable ||
                                user.employee_type === "owner"
                              }
                            />
                          </FormGroup>
                        </Td>
                        <Td>{cnt + 1}</Td>
                        <Td>{user?.first_name}</Td>
                        <Td>{user?.last_name}</Td>
                        <Td>{user?.mobile}</Td>
                        <Td>{user?.email}</Td>
                        <Td>{user?.employee_type}</Td>
                        <Td>{checkIcon(user?.is_employee)}</Td>
                        <Td>{checkIcon(user?.is_co_owner)}</Td>
                        <Td>{checkIcon(user?.needs_approval)}</Td>
                        <Td>{checkIcon(user?.needs_payment_approval)}</Td>
                        <Td>{checkIcon(user?.view_reports)}</Td>
                        <Td>
                          {activeCompanyIsEditable && (
                            <div>
                              {user?.employee_type != "sub" && (
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={() => {
                                    setParent(user);
                                    handleAddNewModal();
                                  }}
                                >
                                  Add Child
                                </Button>
                              )}
                            </div>
                          )}
                        </Td>
                        <Td>
                          {activeCompanyIsEditable && (
                            <div>
                              <Button
                                type="button"
                                color="primary"
                                id={user.id}
                                onClick={onEdit}
                              >
                                Edit
                              </Button>
                            </div>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </div>
          </AvForm>
        </CardBody>
      </Card>
      <ActionFooter
        next={next}
        canSave={canSave}
        back={back}
        complete={saveEmployees}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(UsersAndEmployees);
