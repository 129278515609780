import { OPEN_SHEET_VIEWER, CLOSE_SHEET_VIEWER } from "./actionTypes";
const initialState = {
  isOpen: false,
  sheetFile: null,
};
const SheetViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SHEET_VIEWER:
      return {
        ...state,
        isOpen: true,
        sheetFile: action.payload,
      };

    case CLOSE_SHEET_VIEWER:
      return {
        ...state,
        isOpen: false,
        sheetFile: null,
      };
    default:
      return state;
  }
};
export default SheetViewerReducer;
