export const convertToBase64 = (file, cb) => {
  let reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = function(event) {
    cb(event.target.result);
  };

  reader.onerror = function(error) {
    console.log("Error: ", error);
  };
};
