import React, { memo } from "react";
import { connect } from "react-redux";
import "./style.css";
import BasicInfo from "../BasicInfo";
import AccountingProfile from "../AccountingProfile";
import UsersAndEmployees from "../UsersAndEmployees";
import EmployeesSalaries from "../EmployeesSalaries";
import StaffCash from "../StaffCash";
import PaymentMethods from "../PaymentMethods";
import Debits from "../Debits";
import AccountingCategories from "../AccountingCategories";
import Items from "../Items";
import Assets from "../Assets";
import Deposits from "../Deposits";
import Contracts from "../Contracts";
import Liabilities from "../Liabilities";
import TaxesItems from "../TaxesItems";
import Preference from "../Preference";
import ShareEquity from "../ShareEquity";
import ViewBalance from "../ViewBalance";
import CostCenters from "../CostCenters";

const Content = memo(({ currentState }) => {
  const handleState = (state) => {
    let element;
    switch (state?.code) {
      case "basic_info":
        element = <BasicInfo />;
        break;
      case "accounting_profile":
        element = <AccountingProfile />;
        break;

      case "cost_center":
        element = <CostCenters />;
        break;
      case "users_employees":
        element = <UsersAndEmployees />;
        break;

      case "salary_details":
        element = <EmployeesSalaries />;
        break;

      case "users_cash":
        element = <StaffCash />;
        break;

      case "payment_method":
        element = <PaymentMethods />;
        break;

      case "assets":
        element = <Assets />;
        break;

      case "taxes_items":
        element = <TaxesItems type="debts" key="debts" />;
        break;
      case "receivables":
        element = <Debits type="receivables" key="debts" />; // key for create new instance
        break;

      case "payables":
        element = <Debits type="payables" key="credits" />; // key for create new instance
        break;

      case "revenue":
        element = <AccountingCategories />; // key for create new instance
        break;

      case "purchases":
        element = <Items type="purchase" key="purchase" />; // key for create new instance
        break;

      case "sales":
        element = <Items type="sale" key="sale" />; // key for create new instance
        break;

      case "other_assets":
        element = <Deposits />;
        break;
      case "contracts":
        element = <Contracts />;
        break;
      case "liabilities":
        element = <Liabilities />;
        break;

      case "shareholders_equity":
        element = <ShareEquity />;
        break;
      case "preference":
        element = <Preference />;
        break;
      case "review_balance_sheet":
        element = <ViewBalance />;
        break;

      default:
        break;
    }
    return element;
  };
  return <div className="content-content">{handleState(currentState)}</div>;
});
const mapStateToProps = (state) => {
  return { currentState: state.CompanyState.currentState };
};
export default connect(mapStateToProps, {})(Content);
