import axios from "axios";
import { BASEURL, ONBOARDINGBASEURL, ONBOARDERURL } from ".";
import getFormData from "./helper.js";
const getFile = (url) => {
  return axios({
    url: `${ONBOARDERURL}${url}`, //your url
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      return response.data;
    })
    .catch(async (err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              err?.response?.data ||
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              err?.response?.data ||
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = err?.response?.data || "Invalid credentials";
            break;
          default:
            if (err.response.data instanceof Blob) {
              const blob = new Blob([err.response.data]);
              const data = await blob.text();
              message = JSON.parse(data);
            } else {
              message = err?.response?.data || "Sorry! something went wrong";
            }
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const downloadFile = (url, filename) => {
  return axios({
    url: `${ONBOARDERURL}${url}`, //your url
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              err?.response?.data ||
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              err?.response?.data ||
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = err?.response?.data || "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};

const getOnboarder = (url) => {
  return axios
    .get(`${ONBOARDERURL}${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              err?.response?.data ||
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              err?.response?.data ||
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = err?.response?.data || "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const deleteOnboarder = (url) => {
  return axios
    .delete(`${ONBOARDERURL}${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              err?.response?.data ||
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              err?.response?.data ||
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = err?.response?.data || "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const putOnboarder = (url, data) => {
  return axios
    .put(`${ONBOARDERURL}${url}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              err?.response?.data ||
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              err?.response?.data ||
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = err?.response?.data || "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const postFormOnboarder = (url, data) => {
  return axios
    .post(`${ONBOARDERURL}${url}`, getFormData(data), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              err?.response?.data ||
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              err?.response?.data ||
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = err?.response?.data || "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const putFormOnboarder = (url, data) => {
  return axios
    .put(`${ONBOARDERURL}${url}`, getFormData(data), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              err?.response?.data ||
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              err?.response?.data ||
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = err?.response?.data || "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const postOnboarder = (url, data) => {
  return axios
    .post(`${ONBOARDERURL}${url}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              err?.response?.data ||
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              err?.response?.data ||
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = err?.response?.data || "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const getSectors = (url) => {
  return axios
    .get(`${ONBOARDINGBASEURL}${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message =
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};

const getAccountingSystems = (url) => {
  return axios
    .get(`${ONBOARDINGBASEURL}${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message =
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const postAccountingSystems = (url, data) => {
  return axios
    .post(`${ONBOARDINGBASEURL}${url}`, data, {})
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
export {
  getOnboarder,
  deleteOnboarder,
  postOnboarder,
  putOnboarder,
  putFormOnboarder,
  postFormOnboarder,
  getSectors,
  getAccountingSystems,
  postAccountingSystems,
  downloadFile,
  getFile,
};
