import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { getOnboarder } from "../../api/common";
import { formatNumber } from "../../utils";
import { Spinner } from "reactstrap";

const InfoPopover = ({ company }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [balance, setBalance] = useState(null);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  useEffect(() => {
    if (show) {
      setLoading(true);
      getOnboarder(`/UserManagement/${company?.id}/company_balance_sheet`)
        .then((data) => {
          setBalance(data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [show]);

  return (
    <>
      <Button
        id="Popover1"
        color="light"
        onMouseEnter={(e) => e.target.focus()}
        onMouseLeave={(e) => e.target.blur()}
      >
        <i class="fas fa-info"></i>
      </Button>
      <Popover
        placement="bottom"
        isOpen={show}
        target="Popover1"
        toggle={handleClick}
        trigger="focus"
        placement="right"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;" }}
      >
        <PopoverHeader>Balance Sheet</PopoverHeader>
        {loading && (
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <PopoverBody>
          {!loading && balance && (
            <div>
              <p>
                <strong>Debit:</strong>{" "}
                {formatNumber(balance.total_debits || 0.0)}
              </p>
              <p>
                <strong>Credit:</strong>{" "}
                {formatNumber(balance.total_credits || 0.0)}
              </p>
              <p>
                <strong>Difference:</strong>
                <span
                  style={{
                    color: balance.difference ? "red" : "green",
                  }}
                >
                  {" "}
                  {formatNumber(balance.difference || 0.0)}
                </span>{" "}
              </p>
            </div>
          )}
        </PopoverBody>
      </Popover>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
  };
};
export default connect(mapStateToProps, {})(InfoPopover);
