import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Alert,
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import { toast } from "react-toastify";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import { getOnboarder, postOnboarder, downloadFile } from "../../../api/common";
import ItemLine from "./ItemLine.js";
import {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import TotalLines from "../../TotalLines";
import CopyFromSales from "./copyFromSales";
import Import from "../../ImportModal";
import { handleErrorsAllMessage } from "../../../utils";
import debounce from "lodash.debounce";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";

const Items = ({
  type,
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,
  showLoader,
  removeLoader,
}) => {
  const [copyFromSalesModal, setCopyFromSalesModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  let history = useHistory();
  const [taxesItems, setTaxesItems] = useState({});
  const [canSave, setCanSave] = useState(false);
  const [errors, setErrors] = useState([]);
  const [lines, setLines] = useState([]);
  const [deleteLines, setDeleteLines] = useState([]);
  const [copyItems, setCopyItems] = useState([]);
  const [salesLines, setSalesLines] = useState([]);
  const [categories, setCategories] = useState([]);
  const [uoms, setUoms] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [totalInventory, setTotalInventory] = useState(0.0);

  const form = useRef([]);

  const onValidSubmit = (e, vals) => {};

  let save = debounce(() => {
    saveDebits(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    saveDebits(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveDebits(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    saveDebits(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  const getUoms = () => {
    getOnboarder(`/OnboardingBase/product_uom/`).then((data) => {
      if (activeCompanyIsEditable)
        setUoms(data.filter((line) => line?.erp_id && line?.is_active));
      else {
        setUoms(data);
      }
    });
  };
  const getData = () => {
    showLoader();
    getOnboarder(`/UserManagement/${company?.id}/${type}_product`)
      .then((data) => {
        data.forEach((line) => {
          line.updated = false;
          line.selected = false;
        });
        setLines(data);
        removeLoader();
      })
      .catch(() => {
        removeLoader();
      });
    if (type == "purchase") {
      getOnboarder(`/UserManagement/${company?.id}/sale_product`).then(
        (data) => {
          setSalesLines(
            data.filter((line) => {
              return line?.type == "product";
            })
          );
        }
      );
    }
    getOnboarder(
      `/UserManagement/${company?.id}/${
        type == "purchase" ? "cost" : "revenue"
      }`
    ).then((data) => {
      setCategories(data);
    });

    getUoms();

    getOnboarder(`/OnboardingBase/${company?.id}/taxes`).then((data) => {
      setTaxes(
        data.filter((tax) => {
          return tax.scope === type;
        })
      );
    });

    getOnboarder(`/UserManagement/${company?.id}/tax-item`).then((data) => {
      setTaxesItems(data);
    });
  };
  useEffect(() => {
    getData();
    if (form.current) {
      form.current.setTouched(Object.keys(form.current._inputs));
      setCanSave(!form.current.hasError());
    }
  }, []);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  let saveDebits = (cb) => {
    const body = {
      products: lines.filter((line) => line.updated),
      delete: deleteLines || [],
    };
    if (body.products.length || body.delete.length) {
      setCanSave(false);
      showLoader();
      postOnboarder(`/UserManagement/${company?.id}/${type}/submit`, body)
        .then((data) => {
          setErrors([]);
          data.forEach((line) => {
            line.updated = false;
          });
          setLines(data);
          cb();
          setCanSave(true);
          removeLoader();
        })
        .catch((error) => {
          setErrors(error);
          removeLoader();
          setCanSave(true);
          throw error;
        });
    } else {
      setErrors({});
      cb();
    }
  };
  const downloadTemplate = () => {
    const url =
      type === "purchase"
        ? "exportPurchaseProductTemplate"
        : "exportSaleProductTemplate";
    const filename =
      type === "purchase"
        ? "Purchase items template.xlsx"
        : "Sales items template.xlsx";
    downloadFile(`/UserManagement/${url}`, filename).catch((error) => {
      setErrors(error);
    });
  };
  useEffect(() => {
    if (form.current) {
      form.current.setTouched(Object.keys(form.current._inputs));
      const values = form.current.getValues();
      form.current.validateAll(values).then((errors) => {
        if (form.current) {
          setCanSave(!form.current.hasError());
        }
      });
    }
    lines.forEach((line) => {
      if (type === "sale") {
        line.value = (
          parseFloat(line.price || 0) * parseFloat(line.qty || 0)
        ).toFixed(2);
      } else {
        line.value = (
          parseFloat(line.cost || 0) * parseFloat(line.qty || 0)
        ).toFixed(2);
      }
    });
    let sumOfLines = lines
      .filter((line) => {
        return line.type == "product";
      })
      .reduce((sum, line) => sum + parseFloat(line.value), 0);
    setTotalInventory(sumOfLines.toFixed(2));
  }, [lines]);
  const handleAddNew = () => {
    let default_vals = { updated: true };
    if (type == "purchase") {
      default_vals["type"] = "product";
      default_vals[`${type}_uom`] = taxesItems.default_purchase_uom;
    } else {
      debugger;
      default_vals["type"] = taxesItems.default_sale_items_type;
      default_vals[`${type}_uom`] = taxesItems.default_sale_uom || "";
      default_vals["price_include_tax"] =
        taxesItems.sales_tax_preference == "inclusive";
      default_vals["tax"] = taxesItems.default_sales_tax;
    }
    setLines((lines) => [...lines, default_vals]);
  };

  const toggleImport = () => {
    if (importModal) {
      getData();
    }
    setImportModal(!importModal);
  };
  const toggleCopyFromSales = () => {
    setCopyFromSalesModal(!copyFromSalesModal);
  };
  const copyDone = () => {
    getData();
  };

  const toggleSelectAll = (e) => {
    const checked = e.target.checked;
    let items = [...lines];
    items.forEach((item) => {
      if (type === "sale") item.selected = checked;
      else {
        if (!item?.is_auto_copied) item.selected = checked;
      }
    });
    setLines(items);
  };
  const deleteRows = debounce(() => {
    const selectedLines = lines.filter((line) => line.selected);
    setDeleteLines(
      lines.filter((line) => line.selected).map((line) => line.id)
    );
    setLines((lines) => lines.filter((line) => !line.selected));
    if (type === "sale") {
      const isReflected = selectedLines.some(
        (line) => line.is_purchase && line.is_sale
      );
      if (isReflected) {
        const lines = selectedLines.filter(
          (line) => line.is_purchase && line.is_sale
        );
        toast.warn(
          `${
            lines.length > 1
              ? "The Corresponding items in Purchase item page are deleted"
              : "The Corresponding item in Purchase item page is deleted"
          } `
        );
      }
    }
  }, 200);
  return (
    <div className="container-fluid">
      <ActionHeader
        canSave={canSave}
        save={save}
        deleteRows={deleteRows}
        lines={
          type == "purchase"
            ? lines.filter((line) => !line?.is_auto_copied)
            : lines
        }
        saveExit={saveExit}
      />
      <Card style={{ marginBottom: "0rem" }}>
        <CardBody style={{ padding: "0.75rem", paddingBottom: "0rem" }}>
          <CopyFromSales
            modal={copyFromSalesModal}
            company={company}
            toggle={toggleCopyFromSales}
            copyDone={copyDone}
            salesLines={salesLines}
            uomList={uoms}
          />
          <Import
            modal={importModal}
            toggle={toggleImport}
            endpoint={`/UserManagement/${company.id}/import_${type}_product`}
          />
          <Row>
            <Col>
              {handleErrorsAllMessage(errors) && (
                <Alert color="danger">{handleErrorsAllMessage(errors)}</Alert>
              )}
            </Col>
          </Row>
          <AvForm
            className="needs-validation"
            ref={form}
            onValidSubmit={onValidSubmit}
          >
            <div
              className={
                type === "purchase"
                  ? "table-container-input"
                  : "table-container-input-without-title-total"
              }
            >
              <Table
                className="table-responsive-input"
                style={{ maxHeight: type === "purchase" ? "275px" : "310px" }}
              >
                <Thead>
                  <Tr>
                    <Th style={{ minWidth: "10px" }}>
                      <FormGroup>
                        <AvField
                          type="checkbox"
                          name="selected"
                          onChange={toggleSelectAll}
                          disabled={!activeCompanyIsEditable}
                        />
                      </FormGroup>
                    </Th>
                    <Th>Item Name</Th>
                    <Th>Code</Th>
                    <Th>
                      {type == "purchase"
                        ? "Purchase Category"
                        : "Sales Category"}
                    </Th>
                    <Th hidden={type == "purchase"}>Type</Th>
                    <Th>{type == "purchase" ? "Purchase UoM" : "Sales UoM"}</Th>
                    <Th>{type == "purchase" ? "Cost" : "Selling Price"}</Th>
                    <Th hidden={type == "purchase"}>
                      Tax & Inclusive of Price
                    </Th>
                    <Th>QTY</Th>
                    {type == "purchase" && <Th>Value</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {lines.map((line, index) => {
                    return (
                      <ItemLine
                        type={type}
                        index={index}
                        line={line}
                        categories={categories}
                        uoms={uoms}
                        taxes={taxes}
                        setLines={setLines}
                        lines={lines}
                      />
                    );
                  })}
                </Tbody>
              </Table>
            </div>
            {activeCompanyIsEditable && (
              <Button color="link" onClick={handleAddNew}>
                Add New Line
              </Button>
            )}
          </AvForm>
          {type === "purchase" && (
            <Row>
              <Col md="12">
                <TotalLines
                  title="Total Inventory Valuation"
                  total={totalInventory}
                />
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      <ActionFooter
        next={next}
        canSave={canSave}
        back={back}
        complete={saveDebits}
        importToggle={toggleImport}
        downloadTemplate={downloadTemplate}
        copyFromSales={type == "purchase" ? toggleCopyFromSales : null}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(Items);
