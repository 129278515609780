import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Alert,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import RejectModal from "./rejectModal";
import ActionHeader from "../ActionHeader";
import { getOnboarder, putOnboarder } from "../../../api/common";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import { handleErrorsAllMessage, formatDate } from "../../../utils";
import {
  setNextStep,
  nextState,
  previousState,
  setActiveCompany,
} from "../../../store/CompanyState/actions";
import { toast } from "react-toastify";

import { resetState } from "../../../store/CompanyState/actions.js";
import TotalLines from "../../TotalLines";
import { formatNumber } from "../../../utils";
import ExitButton from "../../ExitButton";
import AlertModal from "../../Modal/AlertModal";
import useModal from "../../Modal/useModal";

const ViewBalance = ({
  type,
  setNextStep,
  nextState,
  previousState,
  states,
  resetState,
  company,
  setActiveCompany,
  showLoader,
  removeLoader,
  roleUser,
}) => {
  let history = useHistory();
  const [modal, setModal] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenCurrentAssets, setDropdownOpenCurrentAssets] =
    useState(false);
  const [dropdownOpenCurrentLiabilities, setDropdownOpenCurrentLiabilities] =
    useState(false);
  const [canComplete, setCanComplete] = useState(false);
  const [errors, setErrors] = useState([]);
  const [balance, setBalance] = useState({});
  const [totalAssets, setTotalAssets] = useState(0);
  const [totalLialibities, setTotalLialibities] = useState(0);
  const [totalEquity, setTotalEquity] = useState(0);
  const callPromptModal = useModal(AlertModal);

  const toggle = () => {
    setModal(!modal);
  };
  const back = () => {
    setNextStep("back");
    previousState();
  };

  const afterComplete = async () => {
    const res = await callPromptModal({
      title: "Completing On-boarding",
      message: `Thank you for completing on-boarding, ${company.name} is now Ready for Review`,
    });
    history.push("/dashboard");
  };

  const afterFinilizingSubmission = async () => {
    const res = await callPromptModal({
      title: "approving On-boarding",
      message: `We will let you know once ${company.name} is completed`,
    });
    history.push("/dashboard");
  };

  const afterApprove = async () => {
    toast.success("company has been approved");
    const res = await callPromptModal({
      title: "approving On-boarding",
      message: `Great! Company is now getting mapped to Odoo`,
    });
    history.push("/dashboard");
  };

  const complete = () => {
    showLoader();
    putOnboarder(`/UserManagement/${company?.id}/complete_onboarding`)
      .then((data) => {
        setErrors({});
        setActiveCompany({ ...company, ...data });
        afterComplete();
        removeLoader();
      })
      .catch((errors) => {
        setErrors(errors);
        removeLoader();
      });
  };

  const approve = () => {
    showLoader();
    putOnboarder(`/UserManagement/${company?.id}/approve_onboarding`)
      .then((data) => {
        setErrors({});
        setActiveCompany({ ...company, ...data });
        if (data.status === "completed") {
          afterApprove();
        } else if (data.status === "finalizing_submission") {
          afterFinilizingSubmission();
        }
        removeLoader();
      })
      .catch((errors) => {
        setErrors(errors);
        removeLoader();
      });
  };

  const reject = () => {
    toggle();
  };
  useEffect(() => {
    showLoader();
    getOnboarder(`/UserManagement/${company?.id}/company_balance_sheet`)
      .then((data) => {
        setBalance(data);
        setTotalAssets({
          credit: 0.0,
          debit:
            parseFloat(data.total_receivables || 0) +
            parseFloat(data.total_bank_and_cash || 0) +
            parseFloat(data.total_current_assets || 0) +
            parseFloat(data.total_non_current_assets || 0) +
            parseFloat(data.total_fixed_assets || 0) +
            parseFloat(data.total_inventory || 0) -
            parseFloat(data.total_fixed_assets_accumulated_depreciation || 0),
        });
        setTotalLialibities({
          debit: 0.0,
          credit:
            parseFloat(data.total_payables || 0) +
            parseFloat(data.total_current_liabilities || 0) +
            parseFloat(data.total_non_current_liabilities || 0) +
            parseFloat(data.total_end_of_service || 0),
        });
        setTotalEquity({
          debit: 0.0,
          credit:
            parseFloat(data.company_capital || 0) +
            parseFloat(data.legal_reserve || 0) +
            parseFloat(data.current_profit_loss || 0) +
            parseFloat(data.retained_earning_loss || 0) +
            parseFloat(data.general_other_reserve || 0) +
            parseFloat(data.total_account_balance || 0),
        });
        removeLoader();
      })
      .catch(() => {
        removeLoader();
      });
  }, []);
  useEffect(() => {
    setCanSave(true);
    setCanComplete(
      states.every((state) => {
        return (
          state.status == "completed" ||
          state.status == "disabled" ||
          state.code == "review_balance_sheet"
        );
      }) &&
        !balance.difference &&
        (company.status == "in_process" || company.status == "in_review")
    );
  }, [balance]);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  const onClickEdit = (e) => {
    const state_code = e.target.getAttribute("state");
    const values = {
      current_wizard_state: state_code,
    };
    putOnboarder(`/UserManagement/${company?.id}/change_company_state`, values)
      .then((data) => {
        resetState(data.wizard_states, state_code);
      })
      .catch((error) => {
        throw error;
      });
  };
  const toggleDropDown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleDropDownCurrentAssets = () => {
    setDropdownOpenCurrentAssets(!dropdownOpenCurrentAssets);
  };

  const toggleDropDownCurrentLiabilities = () => {
    setDropdownOpenCurrentLiabilities(!dropdownOpenCurrentLiabilities);
  };
  return (
    <div className="container-fluid">
      <RejectModal modal={modal} toggle={toggle} />
      <ActionHeader />

      <div className="">
        <div className="container-fluid">
          <Row>
            <Col>
              {handleErrorsAllMessage(errors) && (
                <Alert color="danger">
                  <ul>
                    {handleErrorsAllMessage(errors)
                      .split(/\n/)
                      .map((line) =>
                        line && line != " " ? <li>{line}</li> : ""
                      )}
                  </ul>
                </Alert>
              )}
            </Col>
          </Row>
          <Row>
            <h3>Account Balance as Of: {formatDate(company.starting_date)}</h3>
          </Row>

          <Row>
            <div>Account /Parent Account</div>
          </Row>
          <Row>
            <div className="table-container-input" style={{ width: "100%" }}>
              <Table className="table">
                <Thead>
                  <Tr>
                    <Th>Assets</Th>
                    <Th></Th>
                    <Th>Type</Th>
                    <Th>Debit</Th>
                    <Th>Credit</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr className="table-primary">
                    <Td>Assets</Td>
                    <Td></Td>
                    <Td>Assets</Td>
                    <Td>
                      {formatNumber(totalAssets?.debit?.toFixed(2) || 0.0)}
                    </Td>
                    <Td>
                      {formatNumber(totalAssets?.credit?.toFixed(2) || 0.0)}
                    </Td>
                  </Tr>
                  {(balance.total_receivables || "") && (
                    <Tr>
                      <Td>Receivables </Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="receivables"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Assets>Receivables</Td>
                      <Td>{formatNumber(balance.total_receivables || 0.0)}</Td>
                      <Td>0.00</Td>
                    </Tr>
                  )}
                  {(balance.total_bank_and_cash || "") && (
                    <Tr>
                      <Td>Bank and Cash</Td>
                      <Td>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
                          <DropdownToggle tag="span">
                            <i
                              className="fas fa-edit"
                              state="other_assets"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu left>
                            <DropdownItem
                              state="payment_method"
                              onClick={onClickEdit}
                            >
                              Payment Methods
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                              state="users_cash"
                              onClick={onClickEdit}
                            >
                              Users & Cash
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Td>
                      <Td>Assets>Bank and Cash</Td>
                      <Td>
                        {formatNumber(balance.total_bank_and_cash || 0.0)}
                      </Td>
                      <Td>0.00</Td>
                    </Tr>
                  )}
                  {(balance.total_current_assets || "") && (
                    <Tr>
                      <Td>Current Assets</Td>
                      <Td>
                        <Dropdown
                          isOpen={dropdownOpenCurrentAssets}
                          toggle={toggleDropDownCurrentAssets}
                        >
                          <DropdownToggle tag="span">
                            <i
                              className="fas fa-edit"
                              state="other_assets"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu left>
                            <DropdownItem
                              state="contracts"
                              onClick={onClickEdit}
                            >
                              Contracts
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                              state="other_assets"
                              onClick={onClickEdit}
                            >
                              Deposits & Other Assets
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Td>
                      <Td>Assets>Total Prepayment, Deposits & Other Assets</Td>
                      <Td>
                        {formatNumber(balance.total_current_assets || 0.0)}
                      </Td>
                      <Td>0.00</Td>
                    </Tr>
                  )}
                  {(balance.total_inventory || "") && (
                    <Tr>
                      <Td>Inventory</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="purchases"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Purchase Items> Total Inventory</Td>
                      <Td>{formatNumber(balance.total_inventory || 0.0)}</Td>
                      <Td>0.00</Td>
                    </Tr>
                  )}
                  {(balance.total_non_current_assets || "") && (
                    <Tr>
                      <Td>Non-Current Assets</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="other_assets"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>assets>Total Prepayment, Deposits & Other Assets</Td>
                      <Td>
                        {formatNumber(balance.total_non_current_assets || 0.0)}
                      </Td>
                      <Td>0.00</Td>
                    </Tr>
                  )}
                  {(balance.total_fixed_assets || "") && (
                    <Tr>
                      <Td>Fixed Assets</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="assets"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Total Fixed Assets</Td>
                      <Td>{formatNumber(balance.total_fixed_assets || 0.0)}</Td>
                      <Td>0.00</Td>
                    </Tr>
                  )}
                  {(balance.total_fixed_assets_accumulated_depreciation ||
                    "") && (
                    <Tr>
                      <Td>Fixed Assets Accumulated Depreciation</Td>
                      <Td></Td>
                      <Td>Total Fixed Assets Accumulated Depreciation</Td>
                      <Td>
                        {formatNumber(
                          -balance.total_fixed_assets_accumulated_depreciation ||
                            0.0
                        )}
                      </Td>
                      <Td>0.00</Td>
                    </Tr>
                  )}
                  <Tr className="table-primary">
                    <Td>Liabilities</Td>
                    <Td></Td>
                    <Td>Liabilities</Td>
                    <Td>
                      {formatNumber(totalLialibities?.debit?.toFixed(2) || 0.0)}
                    </Td>
                    <Td>
                      {formatNumber(
                        totalLialibities?.credit?.toFixed(2) || 0.0
                      )}
                    </Td>
                  </Tr>
                  {(balance.total_payables || "") && (
                    <Tr>
                      <Td>Payables</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="payables"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Liabilities>Payables</Td>
                      <Td>0.00</Td>
                      <Td>{formatNumber(balance.total_payables || 0.0)}</Td>
                    </Tr>
                  )}
                  {(balance.total_current_liabilities || "") && (
                    <Tr>
                      <Td>Current Liabilities</Td>
                      <Td>
                        <Dropdown
                          isOpen={dropdownOpenCurrentLiabilities}
                          toggle={toggleDropDownCurrentLiabilities}
                        >
                          <DropdownToggle tag="span">
                            <i
                              className="fas fa-edit"
                              state="liabilities"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu left>
                            <DropdownItem
                              state="liabilities"
                              onClick={onClickEdit}
                            >
                              Liabilities
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                              state="contracts"
                              onClick={onClickEdit}
                            >
                              Contracts
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                              state="payment_method"
                              onClick={onClickEdit}
                            >
                              Payment Methods
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Td>
                      <Td>Liabilities>Current Liabilities</Td>
                      <Td>0.00</Td>
                      <Td>
                        {formatNumber(balance.total_current_liabilities || 0.0)}
                      </Td>
                    </Tr>
                  )}
                  {(balance.total_non_current_liabilities || "") && (
                    <Tr>
                      <Td>Non-current liabilities</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="liabilities"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Liabilities>Non-current liabilities</Td>
                      <Td>0.00</Td>
                      <Td>
                        {formatNumber(
                          balance.total_non_current_liabilities || 0.0
                        )}
                      </Td>
                    </Tr>
                  )}
                  {(balance.total_end_of_service || "") && (
                    <Tr>
                      <Td>Total Employees End of Service</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="salary_details"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Liabilities>Non-current liabilities</Td>
                      <Td>0.00</Td>
                      <Td>
                        {formatNumber(balance.total_end_of_service || 0.0)}
                      </Td>
                    </Tr>
                  )}
                  <Tr className="table-primary">
                    <Td>Shareholder’s Equity</Td>
                    <Td></Td>
                    <Td>Shareholder’s Equity</Td>
                    <Td>
                      {formatNumber(totalEquity?.debit?.toFixed(2) || 0.0)}
                    </Td>
                    <Td>
                      {formatNumber(totalEquity?.credit?.toFixed(2) || 0.0)}
                    </Td>
                  </Tr>
                  {(balance.company_capital || "") && (
                    <Tr>
                      <Td>Company Capital</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="shareholders_equity"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Equity>Company Capital</Td>
                      <Td>0.00</Td>
                      <Td>{formatNumber(balance.company_capital || 0.0)}</Td>
                    </Tr>
                  )}
                  {(parseFloat(balance.legal_reserve) || "") && (
                    <Tr>
                      <Td>Legal Reserve</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="shareholders_equity"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Equity>Legal Reserve</Td>
                      <Td>0.00</Td>
                      <Td>{formatNumber(balance.legal_reserve || 0.0)}</Td>
                    </Tr>
                  )}
                  {(parseFloat(balance.general_other_reserve) || "") && (
                    <Tr>
                      <Td>General and Other reserves</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="shareholders_equity"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Equity>General and Other reserves</Td>
                      <Td>0.00</Td>
                      <Td>
                        {formatNumber(balance.general_other_reserve || 0.0)}
                      </Td>
                    </Tr>
                  )}
                  {(parseFloat(balance.current_profit_loss) || "") && (
                    <Tr>
                      <Td>Current Year Net Profit/Loss</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="shareholders_equity"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Equity>Current Year Net Profit/Loss</Td>
                      <Td>0.00</Td>
                      <Td>
                        {formatNumber(balance.current_profit_loss || 0.0)}
                      </Td>
                    </Tr>
                  )}
                  {(parseFloat(balance.retained_earning_loss) || "") && (
                    <Tr>
                      <Td>Retained Earnings/losses</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="shareholders_equity"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Equity>Retained Earnings/losses</Td>
                      <Td>0.00</Td>
                      <Td>
                        {formatNumber(balance.retained_earning_loss || 0.0)}
                      </Td>
                    </Tr>
                  )}
                  {(balance.total_account_balance || "") && (
                    <Tr>
                      <Td>Total Owners Accounts’ Balances</Td>
                      <Td>
                        <i
                          className="fas fa-edit"
                          onClick={onClickEdit}
                          state="shareholders_equity"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </Td>
                      <Td>Equity>Total Owners Accounts’ Balances</Td>
                      <Td>0.00</Td>
                      <Td>
                        {formatNumber(balance.total_account_balance || 0.0)}
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </div>
          </Row>
          <hr />
          <Row>
            <Col md="3">
              <TotalLines
                title="Total Debits"
                total={parseFloat(balance.total_debits || 0.0)}
              />
            </Col>

            <Col md="3">
              <TotalLines
                title="Total Credits"
                total={parseFloat(balance.total_credits || 0.0)}
              />
            </Col>
            <Col md="6">
              <TotalLines
                title="Difference Between Debits & Credits"
                total={parseFloat(balance.difference || 0.0)}
                totalColorStyle={{
                  color: balance.difference ? "red" : "green",
                }}
              />
            </Col>
          </Row>

          <div className="action-footer">
            <div className="main-content">
              <div className="content" style={{ marginTop: "0px" }}>
                <div className="container-fluid">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "5px",
                    }}
                  >
                    <div>
                      <Button
                        type="button"
                        color="light"
                        onClick={back}
                        disabled={!canSave}
                        title="Back"
                      >
                        <i class="fas fa-arrow-left"></i>
                      </Button>
                    </div>
                    <div>
                      {company.status === "in_process" &&
                        (roleUser.is_staff || company.user === roleUser.id) && (
                          <Button
                            type="button"
                            color="success"
                            onClick={complete}
                            disabled={!canComplete}
                            title="Complete Onboarding"
                          >
                            Complete Onboarding
                          </Button>
                        )}
                      {company.status === "in_review" &&
                        (roleUser.is_staff ||
                          company.reviewer === roleUser.id) && (
                          <Button
                            type="button"
                            color="danger"
                            onClick={reject}
                            style={{ marginRight: "5px" }}
                          >
                            Reject
                          </Button>
                        )}
                      {company.status === "in_review" &&
                        (roleUser.is_staff ||
                          company.reviewer === roleUser.id) && (
                          <Button
                            type="button"
                            color="success"
                            onClick={approve}
                            disabled={!canComplete}
                          >
                            Approve
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    states: state.CompanyState.states,
    company: state.CompanyState.activeCompany,
    roleUser: state.Companies.role,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  resetState,
  setActiveCompany,
  showLoader,
  removeLoader,
})(ViewBalance);
