import { all } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import SagaUsers from "./auth/Users/saga";
import sagaCompanies from "./auth/Companies/saga";
import sagaAssign from "./auth/AssignTome/saga";
import LayoutSaga from "./layout/saga";
import sagaBasicInfo from "../pages/Companies/company/forms/BasicInfo/saga";
import sagaState from "./CompanyState/saga";
export default function* rootSaga() {
  yield all([
    AuthSaga(),
    LayoutSaga(),
    SagaUsers(),
    sagaCompanies(),
    sagaAssign(),
    sagaBasicInfo(),
    sagaState(),
  ]);
}
