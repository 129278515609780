import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  FormGroup,
  Alert,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { setActiveCompany } from "../../../store/CompanyState/actions";
import { fetchUsers } from "../../../store/auth/Users/actions";

import { putOnboarder } from "../../../api/common";
import { handleErrorsMessage } from "../../../utils";
import { toast } from "react-toastify";
import AlertModal from "../../Modal/AlertModal";
import useModal from "../../Modal/useModal";

const RejectModal = ({
  modal,
  toggle,
  company,
  setActiveCompany,
  fetchUsers,
  listUsers,
}) => {
  const [errors, setErrors] = useState({});

  const callPromptModal = useModal(AlertModal);
  const toggleModal = () => {
    setErrors({});
    toggle();
  };

  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const getAssignee = (company) => {
    return listUsers.find((user) => {
      return company.user === user.id;
    });
  };
  const afterReject = async () => {
    const res = await callPromptModal({
      title: "Rejecting On-boarding",
      message: `a notification has been sent to "${
        getAssignee(company)?.username
      }" to amend your comments, we will notify once company is assigned back to you for review`,
    });
  };
  const onValidSubmit = (e, vals) => {
    putOnboarder(`/UserManagement/${company?.id}/reject_onboarding`, vals)
      .then((data) => {
        toast.error("company has been rejected");
        setErrors({});
        setActiveCompany({ ...company, ...data });
        toggleModal();
        afterReject();
      })
      .catch((errors) => {
        setErrors(errors);
      });
  };

  useEffect(() => {
    if (!listUsers?.length) fetchUsers();
  }, []);
  return (
    <Modal isOpen={modal} toggle={toggleModal} size="lg">
      <ModalHeader
        toggle={() => {
          toggleModal();
        }}
        close={closeBtn}
      >
        Reject Company
      </ModalHeader>
      <AvForm onValidSubmit={onValidSubmit}>
        <ModalBody>
          <h3>Rejection Reason</h3>
          <Row>
            <Col>
              {handleErrorsMessage(errors) && (
                <Alert color="danger">{handleErrorsMessage(errors)}</Alert>
              )}
            </Col>
          </Row>
          <FormGroup>
            <FormGroup>
              <AvField
                name="comment"
                placeholder="comment"
                type="text"
                errorMessage="Enter comment"
                className="form-control"
                validate={{
                  required: { value: false },
                }}
              />
            </FormGroup>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <FormGroup>
            <Button color="primary">Reject</Button>
          </FormGroup>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    listUsers: state.Users.list,
  };
};
export default connect(mapStateToProps, { setActiveCompany, fetchUsers })(
  RejectModal
);
