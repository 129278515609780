import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  FormGroup,
  Button,
  Label,
  Alert,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { toast } from "react-toastify";
import { postOnboarder, getOnboarder } from "../../api/common";
import { showLoader, removeLoader } from "../../store/Loader/actions";
import { handleErrorsMessage } from "../../utils";

const Settings = ({ showLoader, removeLoader }) => {
  const [errors, setErrors] = useState("");
  const [config, setConfig] = useState(null);
  const handleValidSubmit = (event, values) => {
    saveData();
  };
  const getData = async () => {
    try {
      showLoader();
      const data = await getOnboarder("/configurations/");
      setConfig(data);
      removeLoader();
      setErrors({});
    } catch (error) {
      setErrors(error);
      removeLoader();
      throw error;
    }
  };
  const saveData = async () => {
    try {
      showLoader();
      const data = await postOnboarder("/configurations/", config);
      toast.info(
        "Any set/updates in the SLA will only reflect on the newly created companies"
      );
      setConfig(data);
      setErrors({});
      removeLoader();
    } catch (error) {
      removeLoader();
      setErrors(error);
      throw error;
    }
  };
  const handleOnchange = (e) => {
    const data = { ...config };
    data[e.target.name] = e.target.value || 0;
    setConfig(data);
  };
  useEffect(() => {
    getData();
    return () => {
      setConfig(null);
    };
  }, []);
  return (
    <div className="page-content">
      <div className="container-fluid">
        {handleErrorsMessage(errors) && (
          <Alert color="danger">{handleErrorsMessage(errors)}</Alert>
        )}
        {config && (
          <AvForm
            className="needs-validation"
            onValidSubmit={handleValidSubmit}
            onChange={handleOnchange}
            autoComplete
          >
            <Row>
              <Col md="6">
                <Card>
                  <CardBody>
                    <CardTitle>Onboarding SLA</CardTitle>

                    <FormGroup>
                      <Label htmlFor="validationCustom01">Days*</Label>
                      <AvField
                        name="sla_days"
                        placeholder="Days"
                        type="text"
                        value={config?.sla_days || ""}
                        errorMessage="Enter Days"
                        className="form-control"
                        validate={{
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: "Invalid Days",
                          },
                          required: { value: !config?.sla_hours },
                        }}
                        id="validationCustom01"
                      />
                      {errors?.sla_days && (
                        <Alert color="danger">{errors?.sla_days[0]}</Alert>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="validationCustom02">Hours*</Label>
                      <AvField
                        name="sla_hours"
                        placeholder="Hours"
                        type="text"
                        value={config?.sla_hours || ""}
                        errorMessage="Enter Hours"
                        className="form-control"
                        validate={{
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: "Invalid Hours",
                          },
                          required: { value: !config?.sla_days },
                        }}
                        id="validationCustom02"
                      />
                      {errors?.sla_hours && (
                        <Alert color="danger">{errors?.sla_hours[0]}</Alert>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="validationCustom03">Day Start at</Label>
                      <AvField
                        name="working_day_start"
                        placeholder="Hours"
                        type="time"
                        value={config?.working_day_start || ""}
                        errorMessage="Enter Hours"
                        className="form-control"
                        validate={{
                          required: { value: false },
                        }}
                        id="validationCustom03"
                      />
                      {errors?.working_day_start && (
                        <Alert color="danger">
                          {errors?.working_day_start[0]}
                        </Alert>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="validationCustom04">Day End at</Label>
                      <AvField
                        name="working_day_end"
                        placeholder="Hours"
                        type="time"
                        value={config?.working_day_end || ""}
                        errorMessage="Enter Hours"
                        className="form-control"
                        validate={{
                          required: { value: false },
                        }}
                        id="validationCustom04"
                      />
                      {errors?.working_day_end && (
                        <Alert color="danger">
                          {errors?.working_day_end[0]}
                        </Alert>
                      )}
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="10"></Col>
              <Col md="2">
                <div className="mt-3">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  showLoader,
  removeLoader,
})(Settings);
