import { OPEN_SHEET_VIEWER, CLOSE_SHEET_VIEWER } from "./actionTypes";

export const openSheetViewer = (file) => ({
  type: OPEN_SHEET_VIEWER,
  payload: file,
});

export const closeSheetViewer = () => ({
  type: CLOSE_SHEET_VIEWER,
});
