import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ImageViewer from "react-simple-image-viewer";
import {
  openImageViewer,
  closeImageViewer,
} from "../../store/layout/ImageViewer/actions.js";

// redux actions

const ImagesViewer = ({ imageViewed, isOpen, closeImageViewer }) => {
  return (
    <div>
      {isOpen && (
        <ImageViewer
          backgroundStyle={{ zIndex: 2000, background: "rgba(0, 0, 0, 0.7)" }}
          src={[imageViewed]}
          currentIndex={0}
          onClose={() => {
            closeImageViewer();
          }}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isOpen: state.ImageViewerReducer.isOpen,
    imageViewed: state.ImageViewerReducer.imageViewed,
  };
};
export default connect(mapStateToProps, { closeImageViewer })(ImagesViewer);
