import React, { useState, useEffect } from "react";
import { AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import { connect } from "react-redux";

import { Tr, Td } from "react-super-responsive-table";
const CategoryLine = ({
  index,
  line,
  setLines,
  deleteLine,
  lines,
  type,
  form,
  activeCompanyIsEditable,
}) => {
  const isDublicated = (name, ctx, input, cb) => {
    const names = lines.filter((line) => {
      return name === line.name;
    });
    if (names.length > 1) {
      cb("Name Should be Unique");
    } else {
      cb(true);
    }
  };
  const handleOnChangeInput = (e) => {
    line[e.target.name.match(/[^0-9]+/)] = e.target.value;
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleOnDelete = (e) => {
    line.selected = e.target.checked;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  return (
    <Tr>
      <Td style={{ maxWidth: "5px", minWidth: "5px" }}>
        <FormGroup hidden>
          <AvField name="id" type="text" value={line?.id || ""} />
        </FormGroup>
        <FormGroup hidden>
          <AvField name="type" type="text" value={type} />
        </FormGroup>

        <FormGroup>
          <AvField
            type="checkbox"
            name="selected"
            checked={line?.selected || ""}
            onChange={handleOnDelete}
            disabled={!line.is_deletable || !activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`name${index}`}
            placeholder="name"
            type="text"
            value={line?.name || ""}
            errorMessage="Enter name"
            validate={{
              pattern: {
                value: "^[A-Za-z 0-9]+$",
                errorMessage: "Invalid alphanumeric only",
              },
              required: { value: true },
              custom: isDublicated,
            }}
            onChange={handleOnChangeInput}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state) => {
  return {
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(CategoryLine);
