import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker, DateInput } from "react-date-range";

import { parse, isValid, isEqual } from "date-fns";
import { formatDate } from "../../utils";
import { addDays } from "date-fns";
import "./style.css";

const DateRangeFilter = ({ onChangeDateRange, dateRange, setDateRange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const onClickInput = (e) => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  useEffect(() => {
    onChangeDateRange(dateRange);
  }, dateRange);
  const formatDate = (value) => {
    if (value && isValid(value)) {
      return formatDate(value);
    }
    return "";
  };
  return (
    <div>
      <div class="rdrDateDisplayWrapper">
        <div
          class="rdrDateDisplay"
          style={{ color: "rgb(61, 145, 255)", margin: "0px" }}
        >
          <span class="rdrDateInput rdrDateDisplayItem" onClick={onClickInput}>
            <input
              readOnly={true}
              disabled={false}
              value={formatDate(dateRange[0].startDate)}
            />
          </span>
          <span class="rdrDateInput rdrDateDisplayItem" onClick={onClickInput}>
            <input
              readOnly={true}
              disabled={false}
              value={formatDate(dateRange[0].endDate)}
            />
          </span>
        </div>
      </div>
      {isOpen && (
        <div className="date-picker-wrapper" ref={ref}>
          <div className="date-picker">
            <DateRangePicker
              onChange={(item) => setDateRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              direction="horizontal"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeFilter;
