import React, { useState, useRef, useEffect } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

import { AvField } from "availity-reactstrap-validation";
const ColorInput = ({ value, name, disabled }) => {
  const field = useRef(null);

  const [state, setState] = useState({
    displayColorPicker: false,
    color: {
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    },
    value: value,
  });
  useEffect(() => {
    if (value) {
      setState({ ...state, color: hex2rgba(value), value: value });
    }
  }, [value]);

  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return { r: r, g: g, b: b, a: alpha };
  };
  const handleClick = () => {
    if (!disabled)
      setState({ ...state, displayColorPicker: !state.displayColorPicker });
  };

  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
  };

  const handleChange = (color) => {
    setState({ ...state, color: color.rgb, value: color.hex });
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${state.color.r}, ${state.color.g}, ${state.color.b}, ${state.color.a})`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-flex",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <React.Fragment>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {state.displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={state.color} onChange={handleChange} />
        </div>
      ) : null}
      <AvField name={name} value={state.value} ref={field} type="text" hidden />
    </React.Fragment>
  );
};

export default ColorInput;
