import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Row, Col, FormGroup, Alert, Card, CardBody } from "reactstrap";
import {
  nextState,
  previousState,
  setNextStep,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import { handleErrorsMessage } from "../../../utils";
import { getOnboarder, putOnboarder } from "../../../api/common";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import debounce from "lodash.debounce";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";

const TaxesItems = ({
  setNextStep,
  nextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,

  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const form = useRef(null);
  const [taxesItems, setTaxesItems] = useState(null);
  const [errors, setErrors] = useState({});
  const [taxesList, setTaxesList] = useState([]);
  const [taxesSalesList, setTaxesSalesList] = useState([]);
  const [taxesPurchaseList, setTaxesPurchaseList] = useState([]);
  const [saleItems, setSaleItems] = useState([]);
  const [purchaseItems, setPurchaseItems] = useState([]);
  const [uoms, setUoms] = useState([]);
  const onValidSubmit = (e, vals) => {};

  let savePaymentMethods = async (cb) => {
    const values = form.current.getValues();
    const res = await form.current.validateAll(values);
    if (!res.isValid) {
      form.current.setTouched(Object.keys(form.current._inputs));
    } else {
      if (taxesItems.updated) {
        showLoader();
        putOnboarder(`/UserManagement/${company?.id}/tax-item`, taxesItems)
          .then((data) => {
            setErrors({});
            data.updated = false;
            setTaxesItems(data);
            cb();
            removeLoader();
          })
          .catch((error) => {
            setErrors(error);
            removeLoader();
            throw error;
          });
      } else {
        setErrors({});
        cb();
      }
    }
  };
  let save = debounce(() => {
    savePaymentMethods(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    savePaymentMethods(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    savePaymentMethods(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    savePaymentMethods(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  const handleOnChange = (e) => {
    if (e.target.name.includes("purchase")) {
      if (purchaseItems.length)
        toast.warn("Change won’t be reflected on the old purchase items");
    } else {
      if (saleItems.length)
        toast.warn("Change won’t be reflected on the old sales items");
    }
    const values = form.current.getValues();
    if (e.target.name === "sales_tax_preference") {
      form.current._inputs.default_sales_tax.value = "";
    }
    setTaxesItems((taxesItems) => {
      return {
        ...taxesItems,
        updated: true,
        [e.target.name]: e.target.value,
        sales_tax_preference: values.sales_tax_preference,
        ...(e.target.name === "sales_tax_preference" && {
          default_sales_tax: null,
        }),
      };
    });
  };

  const getUoms = async () => {
    const data = await getOnboarder(`/OnboardingBase/product_uom/`);
    if (activeCompanyIsEditable)
      setUoms(data.filter((line) => line?.erp_id && line?.is_active));
    else {
      setUoms(data);
    }
  };
  const getData = async () => {
    showLoader();
    let data = await getOnboarder(`/UserManagement/${company?.id}/tax-item`);
    data.updated = false;
    setTaxesItems(data);

    data = await getOnboarder(`/OnboardingBase/${company?.id}/taxes/`);
    setTaxesList(data);

    getUoms();

    data = await getOnboarder(`/UserManagement/${company?.id}/sale_product`);
    setSaleItems(data);
    data = await getOnboarder(
      `/UserManagement/${company?.id}/purchase_product`
    );
    setPurchaseItems(data);
    removeLoader();
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  useEffect(() => {
    if (taxesItems?.sales_tax_preference == "inclusive") {
      setTaxesSalesList(
        taxesList.filter((tax) => {
          return tax.price_include_tax && tax.scope === "sale";
        })
      );
    } else if (taxesItems?.sales_tax_preference == "exclusive") {
      setTaxesSalesList(
        taxesList.filter((tax) => {
          return !tax.price_include_tax && tax.scope === "sale";
        })
      );
    }
    setTaxesPurchaseList(
      taxesList.filter((tax) => {
        return tax.scope === "purchase";
      })
    );
  }, [taxesItems, taxesList]);
  const onClickDefaultSalesTax = (e) => {
    if (!taxesItems.sales_tax_preference) {
      e.preventDefault();
      form.current.setError(
        "default_sales_tax",
        true,
        "Please Select sales price preference"
      );
    }
  };

  return (
    <div className="container-fluid">
      <ActionHeader canSave={true} save={save} saveExit={saveExit} />
      <div className="">
        <Row>
          <Col>
            {handleErrorsMessage(errors) && (
              <Alert color="danger">{handleErrorsMessage(errors)}</Alert>
            )}
          </Col>
        </Row>
        <AvForm
          ref={form}
          name="current"
          onChange={handleOnChange}
          onValidSubmit={onValidSubmit}
        >
          <Row>
            <Col>
              {!!errors?.detail && (
                <Alert color="danger">{errors?.detail}</Alert>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <AvRadioGroup
                          inline
                          label="Default Item Sales Price Preference"
                          name="sales_tax_preference"
                          value={taxesItems?.sales_tax_preference || null}
                          grid={{ xs: 7 }}
                          validate={{
                            required: {
                              value: true,
                              errorMessage:
                                "Preference of Sales Price-Tax is Required",
                            },
                          }}
                          disabled={!activeCompanyIsEditable}
                        >
                          <AvRadio label="Inclusive Of Tax" value="inclusive" />
                          <AvRadio label="Exclusive Of Tax" value="exclusive" />
                        </AvRadioGroup>

                        {!!errors?.sales_tax_preference && (
                          <Alert color="danger">
                            {errors?.sales_tax_preference[0]}
                          </Alert>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup onClick={onClickDefaultSalesTax}>
                        <AvField
                          type="select"
                          label="Default Sales Tax"
                          name="default_sales_tax"
                          value={taxesItems?.default_sales_tax || ""}
                          validate={{
                            required: {
                              value: false,
                              errorMessage: "Mandatory field",
                            },
                          }}
                          disabled={
                            !taxesItems?.sales_tax_preference ||
                            !activeCompanyIsEditable
                          }
                          grid={{ xs: 7 }}
                        >
                          <option></option>
                          {taxesSalesList.map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          ))}
                        </AvField>

                        {!!errors?.default_sales_tax && (
                          <Alert color="danger">
                            {errors?.default_sales_tax[0]}
                          </Alert>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <AvField
                          type="select"
                          label="Default Purchase Tax"
                          name="default_purchase_tax"
                          value={taxesItems?.default_purchase_tax || ""}
                          validate={{
                            required: {
                              value: false,
                              errorMessage: "Mandatory field",
                            },
                          }}
                          grid={{ xs: 7 }}
                          disabled={!activeCompanyIsEditable}
                        >
                          <option></option>
                          {taxesPurchaseList.map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          ))}
                        </AvField>

                        {!!errors?.default_purchase_tax && (
                          <Alert color="danger">
                            {errors?.default_purchase_tax[0]}
                          </Alert>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <AvField
                          type="select"
                          label="Default Sale Items Type"
                          name="default_sale_items_type"
                          value={taxesItems?.default_sale_items_type || ""}
                          errorMessage="Select Type"
                          grid={{ xs: 7 }}
                          disabled={!activeCompanyIsEditable}
                        >
                          <option></option>
                          <option key="inventory" value="product">
                            Inventory
                          </option>
                          <option key="service" value="service">
                            Service
                          </option>
                          <option key="consumable" value="consu">
                            Consumable
                          </option>
                          ))}
                        </AvField>

                        {!!errors?.default_sale_items_type && (
                          <Alert color="danger">
                            {errors?.default_sale_items_type[0]}
                          </Alert>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <AvField
                          type="select"
                          label="Default Sales uom"
                          name="default_sale_uom"
                          value={taxesItems?.default_sale_uom || ""}
                          validate={{
                            required: {
                              value: false,
                              errorMessage: "Mandatory field",
                            },
                          }}
                          grid={{ xs: 7 }}
                          disabled={!activeCompanyIsEditable}
                        >
                          <option></option>
                          {uoms.map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          ))}
                        </AvField>

                        {!!errors?.default_sale_uom && (
                          <Alert color="danger">
                            {errors?.default_sale_uom[0]}
                          </Alert>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <AvField
                          type="select"
                          label="Default purchase uom"
                          name="default_purchase_uom"
                          value={taxesItems?.default_purchase_uom || ""}
                          validate={{
                            required: {
                              value: false,
                              errorMessage: "Mandatory field",
                            },
                          }}
                          grid={{ xs: 7 }}
                          disabled={!activeCompanyIsEditable}
                        >
                          <option></option>
                          {uoms.map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          ))}
                        </AvField>

                        {!!errors?.default_purchase_uom && (
                          <Alert color="danger">
                            {errors?.default_purchase_uom[0]}
                          </Alert>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </AvForm>
      </div>
      <ActionFooter
        next={next}
        canSave={true}
        back={back}
        complete={savePaymentMethods}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    nextStep: state.CompanyState.nextStep,
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(TaxesItems);
