import { LOGIN_USER, LOGIN_SUCCESS, API_ERROR } from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (token, history, rememberMe) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { token, history, rememberMe },
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
