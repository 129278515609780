import React, { memo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.css";
// import ContentForm from "./ContentForm";

import { resetState } from "../../../store/CompanyState/actions.js";
import { putOnboarder } from "../../../api/common";
const States = memo(({ states, currentStateIndex, resetState, company }) => {
  let history = useHistory();

  const onClickState = (ev) => {
    const state_code = ev.target
      .closest(".wizard-container")
      .getAttribute("code");
    const values = {
      current_wizard_state: state_code,
    };
    putOnboarder(`/UserManagement/${company?.id}/change_company_state`, values)
      .then((data) => {
        resetState(data.wizard_states, state_code);
      })
      .catch((error) => {
        throw error;
      });
  };
  const handleState = (state, index) => {
    let element;
    switch (state?.status) {
      case "no_data":
        element = (
          <div>
            <div style={{ width: "100%", height: "20px", paddingTop: "2px" }}>
              {currentStateIndex === index && (
                <span>
                  <i className="fas fa-angle-down"></i>
                </span>
              )}
            </div>
            <div
              className="wizard-container"
              onClick={onClickState}
              code={state?.code}
            >
              <span
                className="wizard-circle"
                style={{ border: "1px solid #000" }}
              >
                <span style={{ backgroundColor: "#000" }}></span>
              </span>
              <p style={{ color: "#000" }}>{state?.name}</p>
            </div>
          </div>
        );
        break;
      case "disabled":
        element = (
          <div>
            <div style={{ width: "100%", height: "20px", paddingTop: "2px" }}>
              {currentStateIndex === index && (
                <span>
                  <i className="fas fa-angle-down"></i>
                </span>
              )}
            </div>
            <div className="wizard-container">
              <span
                className="wizard-circle"
                style={{ border: "1px solid #cccccc" }}
              >
                <span style={{ backgroundColor: "#cccccc" }}></span>
              </span>
              <p style={{ color: "#cccccc" }}>{state?.name}</p>
            </div>
          </div>
        );
        break;
      case "updated":
        element = (
          <div>
            <div style={{ width: "100%", height: "20px", paddingTop: "2px" }}>
              {currentStateIndex === index && (
                <span>
                  <i className="fas fa-angle-down"></i>
                </span>
              )}
            </div>
            <div
              className="wizard-container"
              onClick={onClickState}
              code={state?.code}
            >
              <span
                className="wizard-circle"
                style={{ border: "1px solid #2c78e4" }}
              >
                <span style={{ backgroundColor: "#2c78e4" }}></span>
              </span>
              <p style={{ color: "#2c78e4" }}>{state?.name}</p>
            </div>
          </div>
        );
        break;
      case "completed":
        element = (
          <div>
            <div style={{ width: "100%", height: "20px", paddingTop: "2px" }}>
              {currentStateIndex === index && (
                <span>
                  <i className="fas fa-angle-down"></i>
                </span>
              )}
            </div>
            <div
              className="wizard-container"
              onClick={onClickState}
              code={state?.code}
            >
              <span
                className="wizard-circle"
                style={{ border: "1px solid #029e10" }}
              >
                <span style={{ backgroundColor: "#029e10" }}></span>
              </span>
              <p style={{ color: "#029e10" }}>{state?.name}</p>
            </div>
          </div>
        );
        break;
      default:
        break;
    }

    return element;
  };
  return (
    <div className="content-states">
      <div className="main-content">
        <div className="content" style={{ marginTop: "0px" }}>
          <div className="container-fluid">
            <div className="box">
              <div className="states">
                <ul className="nav">
                  {states.map((state, i) => {
                    return (
                      <li key={i}>
                        <div
                          className={
                            i === currentStateIndex ? "active-state" : ""
                          }
                        >
                          {handleState(state, i)}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const mapStateToProps = (state) => {
  return {
    states: state.CompanyState.states,
    currentStateIndex: state.CompanyState.currentStateIndex,
    company: state.CompanyState.activeCompany,
  };
};
export default connect(mapStateToProps, { resetState })(States);
