import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { handleErrorsMessage } from "../../../utils";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import {
  nextState,
  previousState,
  setNextStep,
  setActiveCompany,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import FileUpload from "../../FileUpload";
import { openPdfViewer } from "../../../store/layout/PdfViewer/actions";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import { openSheetViewer } from "../../../store/layout/SheetViewer/actions";
import { openWordViewer } from "../../../store/layout/WordViewer/actions";
import {
  getSectors,
  getAccountingSystems,
  getOnboarder,
} from "../../../api/common";
import { putFormOnboarder } from "../../../api/common";
import { getAccountingProfile } from "../../../api/accountingProfile";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";
import {
  getFilenameFromUrl,
  isLastDayOnMonth,
  isFirstDayOnMonth,
  monthDiff,
} from "../../../utils";
import AddNewAccountingSystem from "./addNewAccountingSystem";
import debounce from "lodash.debounce";
import Max2DigitsNumberInput from "../../Inputs/Max2DigitsNumberInput";

const durations = { monthly: 1, bi_monthly: 2, quarterly: 3 };
const AccountingProfile = ({
  setNextStep,
  nextStep,
  nextState,
  previousState,
  company,
  setActiveCompany,
  saveActiveCompany,
  activeCompanyIsEditable,
  openSheetViewer,
  openWordViewer,
  openPdfViewer,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const form = useRef(null);
  const [accountingProfile, setAccountingProfile] = useState(null);
  const [showAgreementMessage, setShowAgreementMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [sectorList, setSectorList] = useState([]);
  const [packages, setPackages] = useState([]);
  const [accountingSystemList, setAccountingSystemList] = useState([]);
  const [trnCertificate, setTrnCertificate] = useState(null);
  const [businessDescription, setBusinessDescription] = useState(null);
  const onValidSubmit = (e, vals) => {
    if (accountingProfile.updated) {
      showLoader();
      vals = {
        ...vals,
        ...(accountingProfile?.trn_certificate_update && {
          trn_certificate: trnCertificate || "",
        }),
        ...(accountingProfile?.business_description_update && {
          business_description: businessDescription || "",
        }),
        discount_amount: accountingProfile.discount_amount,
      };

      return putFormOnboarder(
        `/UserManagement/accountingProfile/${company?.id}`,
        vals
      )
        .then((data) => {
          data.updated = false;
          setAccountingProfile(data);
          const companyData = { ...company, ...data };
          if (showAgreementMessage) toast.info("agreement document is updated");
          setShowAgreementMessage(false);
          setErrors({});
          if (nextStep === "exit") {
            history.goBack();
          } else if (nextStep === "back") {
            previousState();
            setActiveCompany(companyData);
          } else if (nextStep === "next") {
            nextState();
            setActiveCompany(companyData);
          } else {
            setActiveCompany(companyData);
            saveActiveCompany();
          }
          removeLoader();
        })
        .catch((error) => {
          setErrors(error);
          removeLoader();
          throw error;
        });
    } else {
      setErrors({});
      if (nextStep === "exit") {
        history.goBack();
      } else if (nextStep === "back") {
        previousState();
      } else if (nextStep === "next") {
        nextState();
      }
    }
  };

  const save = debounce(() => {
    setNextStep(null);
    form.current.submit();
  }, 200);
  const saveExit = debounce(() => {
    setNextStep("exit");
    form.current.submit();
  }, 200);
  const next = debounce(() => {
    setNextStep("next");
    form.current.submit();
  }, 200);
  const back = debounce(() => {
    setNextStep("back");
    form.current.submit();
  }, 200);
  const handleOnChange = (e) => {
    if (
      [
        "package",
        "discount_amount",
        "starting_date",
        "payment_start_date",
      ].includes(e.target.name)
    ) {
      setShowAgreementMessage(true);
    }
    setAccountingProfile((accountProfile) => {
      return { ...accountProfile, updated: true };
    });
  };
  useEffect(() => {
    showLoader();
    getAccountingProfile(`/accountingProfile/${company?.id}`).then((data) => {
      data.updated = false;
      setAccountingProfile(data);
      if (data.trn_certificate) {
        fetch(data.trn_certificate)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File(
              [blob],
              getFilenameFromUrl(data.trn_certificate),
              { type: blob.type }
            );
            setTrnCertificate(file);
          });
      }
      if (data.business_description) {
        fetch(data.business_description)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File(
              [blob],
              getFilenameFromUrl(data.business_description),
              { type: blob.type }
            );
            setBusinessDescription(file);
          });
      }
      removeLoader();
    });
    getSectors(`/sector/`).then((data) => {
      if (activeCompanyIsEditable)
        setSectorList(data.filter((line) => line?.erp_id && line?.is_active));
      else {
        setSectorList(data);
      }
    });

    getOnboarder(`/OnboardingBase/package/`).then((data) => {
      if (activeCompanyIsEditable)
        setPackages(data.filter((line) => line?.erp_id && line?.is_active));
      else {
        setPackages(data);
      }
    });
    getAccountingSystems(`/accountingSystem/`).then((data) => {
      if (activeCompanyIsEditable)
        setAccountingSystemList(
          data.filter((line) => line?.erp_id && line?.is_active)
        );
      else {
        setAccountingSystemList(data);
      }
    });
  }, []);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleAddNewAccountingSystem = (accountingSystem) => {
    setAccountingSystemList((accountingSystems) => [
      ...accountingSystems,
      accountingSystem,
    ]);
  };
  const validateStartDate = (value, ctx, input, cb) => {
    if (!isFirstDayOnMonth(new Date(value))) {
      cb("should the first day on month");
    } else if (
      ctx.is_tax_registered &&
      monthDiff(new Date(value), new Date(ctx?.tax_return_date)) + 1 <
        durations[ctx.tax_return_frequency]
    ) {
      cb(
        `Service Start Date Must be at least ${
          durations[ctx.tax_return_frequency]
        } months before the Tax Date`
      );
    } else {
      return true;
    }
  };

  const handleOnChangeBusinessDescription = (event) => {
    let currentfile = event.target.files[0];
    setBusinessDescription(currentfile);
    setAccountingProfile({
      ...accountingProfile,
      business_description_update: true,
      updated: true,
    });
  };
  const validateNextReturnDueDate = (value, ctx, input, cb) => {
    if (!isLastDayOnMonth(new Date(value))) {
      cb("should the last day on month");
    } else if (new Date(value) < new Date(ctx?.starting_date)) {
      cb("date must be after the starting service date");
    } else {
      return true;
    }
  };
  const handleOnChangeNumber = (value, name) => {
    let item = { ...accountingProfile, updated: true };
    item[name] = value;
    setAccountingProfile(item);
  };
  const openViewer = (e) => {
    e.preventDefault();
    if (
      businessDescription.type == "application/pdf" ||
      businessDescription.name.endsWith("pdf")
    ) {
      const url = window.URL.createObjectURL(businessDescription);
      openPdfViewer(url, businessDescription.name);
    } else if (
      businessDescription.type == "application/msword" ||
      businessDescription.type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      businessDescription.name.endsWith("doc") ||
      businessDescription.name.endsWith("docx")
    ) {
      const url = window.URL.createObjectURL(businessDescription);
      openWordViewer(businessDescription);
    } else {
      const url = window.URL.createObjectURL(businessDescription);
      openSheetViewer(businessDescription);
    }
  };
  return (
    <div className="container-fluid">
      <ActionHeader canSave={true} save={save} saveExit={saveExit} />

      <AddNewAccountingSystem
        modal={modal}
        toggle={toggle}
        handleAddNew={handleAddNewAccountingSystem}
      />
      <div className="">
        <AvForm
          ref={form}
          name="current"
          onChange={handleOnChange}
          onValidSubmit={onValidSubmit}
        >
          {accountingProfile && (
            <>
              <Row>
                <Col>
                  {handleErrorsMessage(errors) && (
                    <Alert color="danger">{handleErrorsMessage(errors)}</Alert>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card>
                    <CardBody>
                      <CardTitle>Accounting Profile</CardTitle>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <AvField
                              type="select"
                              name="sector"
                              value={accountingProfile?.sector || ""}
                              label="Company Sector*"
                              disabled={!activeCompanyIsEditable}
                            >
                              <option></option>
                              {sectorList.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                  {option?.name}
                                </option>
                              ))}
                            </AvField>
                            {!!errors?.sector && (
                              <Alert color="danger">{errors?.sector[0]}</Alert>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="d-flex justify-content-start">
                        <div>
                          <FormGroup>
                            <Label>Business Description*</Label>
                            <input
                              id="business_description"
                              type="file"
                              name="business_description"
                              onChange={handleOnChangeBusinessDescription}
                              disabled={!activeCompanyIsEditable}
                              hidden
                            />
                            <Label
                              htmlFor="business_description"
                              className="px-5"
                            >
                              <h4>
                                <i class="fas fa-upload"></i>
                              </h4>
                            </Label>
                            <div>
                              <small
                                style={{ position: "relative", top: "-20px" }}
                              >
                                upload PDF/EXCEL/WORD
                              </small>
                            </div>
                          </FormGroup>
                        </div>
                        <div
                          style={{
                            maxWidth: "40%",
                            marginRight: "auto",
                          }}
                        >
                          {businessDescription && (
                            <span>
                              <div>
                                <a href="" onClick={openViewer}>
                                  {businessDescription?.name}
                                </a>
                                {activeCompanyIsEditable && (
                                  <Button
                                    color="link"
                                    onClick={(e) => {
                                      const input = document.querySelector(
                                        "#business_description"
                                      );
                                      input.value = "";
                                      setBusinessDescription("");
                                      setAccountingProfile({
                                        ...accountingProfile,
                                        business_description_update: true,
                                        updated: true,
                                      });
                                    }}
                                    style={{ color: "red" }}
                                  >
                                    X
                                  </Button>
                                )}
                              </div>
                            </span>
                          )}
                        </div>
                        {!!errors?.business_description && (
                          <Alert color="danger">
                            {errors?.business_description[0]}
                          </Alert>
                        )}
                      </div>
                      <FormGroup>
                        <FormGroup>
                          <Label>Accounting System*</Label>
                          <Row>
                            <Col md={activeCompanyIsEditable ? "10" : "12"}>
                              <AvField
                                type="select"
                                name="accounting_system"
                                value={
                                  accountingProfile?.accounting_system || ""
                                }
                                disabled={!activeCompanyIsEditable}
                              >
                                <option></option>
                                {accountingSystemList.map((option) => (
                                  <option key={option?.id} value={option?.id}>
                                    {option?.name}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                            {activeCompanyIsEditable && (
                              <Col md="2">
                                <Button
                                  onClick={() => {
                                    toggle();
                                  }}
                                >
                                  Add
                                </Button>
                              </Col>
                            )}
                          </Row>
                          {!!errors?.accounting_system && (
                            <Alert color="danger">
                              {errors?.accounting_system[0]}
                            </Alert>
                          )}
                        </FormGroup>
                      </FormGroup>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <AvField
                              type="select"
                              name="package"
                              value={accountingProfile?.package || ""}
                              label="Package*"
                              disabled={!activeCompanyIsEditable}
                            >
                              <option></option>
                              {packages.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                  {option?.name}
                                </option>
                              ))}
                            </AvField>
                            {!!errors?.package && (
                              <Alert color="danger">{errors?.package[0]}</Alert>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup>
                        <Max2DigitsNumberInput
                          name={`discount_amount`}
                          label="Discount Amount"
                          onChangeValue={handleOnChangeNumber}
                          value={accountingProfile?.discount_amount}
                          disabled={!activeCompanyIsEditable}
                          required={false}
                        />

                        {!!errors?.discount_amount && (
                          <Alert color="danger">
                            {errors?.discount_amount[0]}
                          </Alert>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <AvField
                          name="starting_date"
                          label="Starting Date With The Accounter*"
                          value={accountingProfile?.starting_date || ""}
                          placeholder="Starting Date"
                          type="date"
                          validate={{
                            required: { value: false },
                            custom: validateStartDate,
                          }}
                          disabled={!activeCompanyIsEditable}
                        />
                        {!!errors?.starting_date && (
                          <Alert color="danger">
                            {errors?.starting_date[0]}
                          </Alert>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <AvField
                          name="payment_start_date"
                          label="Payment Start Date*"
                          value={accountingProfile?.payment_start_date || ""}
                          placeholder="Starting Date"
                          type="date"
                          disabled={!activeCompanyIsEditable}
                        />
                        {!!errors?.payment_start_date && (
                          <Alert color="danger">
                            {errors?.payment_start_date[0]}
                          </Alert>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <AvField
                          name="last_financial_available"
                          label="Last Financial Available"
                          value={
                            accountingProfile?.last_financial_available || ""
                          }
                          placeholder="Last Financial Available"
                          type="date"
                          validate={{
                            required: { value: false },
                          }}
                          disabled={!activeCompanyIsEditable}
                        />
                        {!!errors?.last_financial_available && (
                          <Alert color="danger">
                            {errors?.last_financial_available[0]}
                          </Alert>
                        )}
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="6">
                  <Card>
                    <CardBody>
                      <CardTitle>Tax & Periodicity</CardTitle>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <AvRadioGroup
                              inline
                              label="Tax Registered"
                              name="is_tax_registered"
                              value={
                                accountingProfile.is_tax_registered
                                  ? "true"
                                  : "false"
                              }
                              onChange={(e) => {
                                setAccountingProfile({
                                  ...accountingProfile,
                                  is_tax_registered: e.target.value === "true",
                                  tax_return_frequency: "quarterly",
                                });
                              }}
                              disabled={!activeCompanyIsEditable}
                            >
                              <AvRadio label="Yes" value="true" />
                              <AvRadio label="No" value="false" />
                            </AvRadioGroup>

                            {!!errors?.is_tax_registered && (
                              <Alert color="danger">
                                {errors?.is_tax_registered[0]}
                              </Alert>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <AvField
                              name="tax_name"
                              placeholder="Tax name"
                              type="text"
                              value={accountingProfile?.tax_name || ""}
                              label="Tax Name"
                              className="form-control"
                              validate={{
                                required: {
                                  value: accountingProfile?.is_tax_registered,
                                  errorMessage: "Mandatory field",
                                },
                                maxLength: {
                                  value: 150,
                                  errorMessage: "Max 150 chars.",
                                },
                              }}
                              disabled={
                                !activeCompanyIsEditable ||
                                !accountingProfile?.is_tax_registered
                              }
                            />

                            {!!errors?.tax_name && (
                              <Alert color="danger">
                                {errors?.tax_name[0]}
                              </Alert>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <AvField
                              name="tax_reg_number"
                              label="Tax Reg Number"
                              placeholder="Tax Reg Number"
                              value={
                                (accountingProfile?.tax_reg_number &&
                                  String(accountingProfile?.tax_reg_number)) ||
                                ""
                              }
                              type="text"
                              className="form-control"
                              validate={{
                                required: {
                                  value: accountingProfile?.is_tax_registered,
                                  errorMessage: "Mandatory field",
                                },
                                pattern: {
                                  value: "^[0-9]+$",
                                  errorMessage:
                                    "Invalid Trade License Number only",
                                },
                                maxLength: {
                                  value: 25,
                                  errorMessage: "Max 25 chars.",
                                },
                              }}
                              disabled={
                                !activeCompanyIsEditable ||
                                !accountingProfile?.is_tax_registered
                              }
                            />

                            {!!errors?.tax_reg_number && (
                              <Alert color="danger">
                                {errors?.tax_reg_number[0]}
                              </Alert>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <AvField
                              name="tax_return_date"
                              label="Next VAT Return Period"
                              placeholder="Next VAT Return Period"
                              value={accountingProfile?.tax_return_date || ""}
                              type="date"
                              validate={{
                                required: {
                                  value: accountingProfile?.is_tax_registered,
                                  errorMessage: "Mandatory field",
                                },
                                custom: validateNextReturnDueDate,
                              }}
                              disabled={
                                !activeCompanyIsEditable ||
                                !accountingProfile?.is_tax_registered
                              }
                            />

                            {!!errors?.tax_return_date && (
                              <Alert color="danger">
                                {errors?.tax_return_date[0]}
                              </Alert>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label>Frequency of Tax Return</Label>
                            <AvField
                              type="select"
                              name="tax_return_frequency"
                              value={
                                accountingProfile?.tax_return_frequency || ""
                              }
                              validate={{
                                required: {
                                  value: accountingProfile?.is_tax_registered,
                                  errorMessage: "Mandatory field",
                                },
                              }}
                              disabled={
                                !activeCompanyIsEditable ||
                                !accountingProfile?.is_tax_registered
                              }
                            >
                              <option value="quarterly">Quarterly</option>
                              <option value="bi_monthly">Bi-Monthly</option>
                              <option value="monthly">Monthly</option>
                            </AvField>

                            {!!errors?.tax_return_frequency && (
                              <Alert color="danger">
                                {errors?.tax_return_frequency[0]}
                              </Alert>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="">
                        <FileUpload
                          title="TRN Certificate"
                          nameField="trn_certificate"
                          currentFile={trnCertificate}
                          handleOnChange={(file) => {
                            setTrnCertificate(file);
                            setAccountingProfile({
                              ...accountingProfile,
                              trn_certificate_update: true,
                              updated: true,
                            });
                          }}
                          disabled={
                            !activeCompanyIsEditable ||
                            !accountingProfile?.is_tax_registered
                          }
                        />
                      </Row>

                      {!!errors?.trn_certificate && (
                        <Alert color="danger">{errors?.trn_certificate}</Alert>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </AvForm>
      </div>
      <ActionFooter next={next} canSave={true} back={back} complete={false} />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    nextStep: state.CompanyState.nextStep,
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  setActiveCompany,
  saveActiveCompany,
  openSheetViewer,
  openWordViewer,
  openPdfViewer,
  showLoader,
  removeLoader,
})(AccountingProfile);
