import {
  FETCH_USERS,
  CREATE_USER,
  API_ERROR,
  CREATE_USER_SUCCESS,
  FETCH_USERS_SUCCESS,
  REST_ERROR
} from "./actionTypes";

export const fetchUsers = () => {
  return {
    type: FETCH_USERS
  };
};

export const fetchUsersSuccess = data => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: data
  };
};

export const createUser = (user, history) => {
  return {
    type: CREATE_USER,
    payload: { user, history }
  };
};

export const createUserSuccess = user => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: user
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error
  };
};

export const restError = () => {
  return {
    type: REST_ERROR
  };
};
