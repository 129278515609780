import { OPEN_PDF_VIEWER, CLOSE_PDF_VIEWER } from "./actionTypes";
const initialState = {
  isOpen: false,
  pdfFile: null,
  fileName: null,
  sendAgreement: false,
  disabledSendAgreement: false,
  agreementActions: false,
  token: false,
};
const PdfViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_PDF_VIEWER:
      return {
        ...state,
        isOpen: true,
        pdfFile: action.payload,
        fileName: action.fileName,
        sendAgreement: action.sendAgreement,
        disabledSendAgreement: action.disabledSendAgreement,
        agreementActions: action.agreementActions,
        token: action.token,
      };

    case CLOSE_PDF_VIEWER:
      return {
        ...state,
        isOpen: false,
        pdfFile: null,
        fileName: null,
        sendAgreement: false,
        agreementActions: false,
        token: false,
      };
    default:
      return state;
  }
};
export default PdfViewerReducer;
