import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Alert,
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import { getOnboarder, postOnboarder } from "../../../api/common";
import {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";

import TotalLines from "../../TotalLines";
import { handleErrorsAllMessage, formatDate } from "../../../utils";
import AssetsLine from "./assetsLine.js";
import debounce from "lodash.debounce";
import { showLoader, removeLoader } from "../../../store/Loader/actions";

const Assets = ({
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const [canSave, setCanSave] = useState(false);
  const [errors, setErrors] = useState([]);
  const [lines, setLines] = useState([]);
  const [deleteLines, setDeleteLines] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState(0.0);
  const form = useRef([]);

  const onValidSubmit = (e, vals) => {};
  const deleteLine = (id) => {
    setDeleted((deleted) => [...deleted, id]);
  };

  let save = debounce(() => {
    saveAssets(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    saveAssets(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveAssets(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    saveAssets(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  useEffect(() => {
    showLoader();
    getOnboarder(`/UserManagement/${company?.id}/assets/`)
      .then((data) => {
        data.forEach((line) => {
          line.updated = false;
          line.selected = false;
        });
        setLines(data);
        removeLoader();
      })
      .catch((error) => {
        removeLoader();
      });

    getOnboarder(`/OnboardingBase/${company?.id}/assetsCategories/`).then(
      (data) => {
        setCategories(data);
      }
    );
    if (form.current) {
      form.current.setTouched(Object.keys(form.current._inputs));
      setCanSave(!form.current.hasError());
    }
  }, []);
  let saveAssets = (cb) => {
    const body = {
      assets: lines.filter((line) => line.updated),
      delete: deleteLines || [],
    };
    if (body.assets.length || body.delete.length) {
      setCanSave(false);
      showLoader();
      postOnboarder(`/UserManagement/${company?.id}/assets/submit`, body)
        .then((data) => {
          setErrors([]);
          data.forEach((line) => {
            line.updated = false;
            line.selected = false;
          });
          setLines(data);
          cb();
          setCanSave(true);
          removeLoader();
        })
        .catch((error) => {
          setErrors(error);
          removeLoader();
          setCanSave(true);
          throw error;
        });
    } else {
      setErrors({});
      cb();
    }
  };

  useEffect(() => {
    if (form.current) {
      form.current.setTouched(Object.keys(form.current._inputs));
      const values = form.current.getValues();
      form.current.validateAll(values).then((errors) => {
        if (form.current) {
          setCanSave(!form.current.hasError());
        }
      });
    }
    setTotal(
      lines
        .reduce(
          (a, b) => a + parseFloat(b["accumulated_depreciation_amount"] || 0),
          0
        )
        .toFixed(2)
    );
  }, [lines]);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  const handleAddNew = () => {
    setLines((lines) => [...lines, { update: true }]);
  };
  const deleteRows = debounce(() => {
    setLines((lines) => lines.filter((line) => !line.selected));
    setDeleteLines(
      lines.filter((line) => line.selected).map((line) => line.id)
    );
  }, 200);

  const toggleSelectAll = (e) => {
    const checked = e.target.checked;
    let items = [...lines];
    items.forEach((item) => {
      item.selected = checked;
    });
    setLines(items);
  };
  return (
    <div className="container-fluid">
      <ActionHeader
        canSave={canSave}
        save={save}
        deleteRows={deleteRows}
        lines={lines}
        saveExit={saveExit}
      />
      <Card style={{ marginBottom: "0rem" }}>
        <CardBody style={{ padding: "0.75rem", paddingBottom: "0rem" }}>
          <CardTitle>
            <Row>Service Start Date: {formatDate(company.starting_date)}</Row>
          </CardTitle>
          <Row>
            <Col>
              {handleErrorsAllMessage(errors) && (
                <Alert color="danger">{handleErrorsAllMessage(errors)}</Alert>
              )}
            </Col>
          </Row>

          <AvForm
            className="needs-validation"
            ref={form}
            onValidSubmit={onValidSubmit}
          >
            <div className="table-container-input">
              <Table className="table-responsive-input">
                <Thead>
                  <Tr>
                    <Th style={{ minWidth: "10px" }}>
                      <FormGroup>
                        <AvField
                          type="checkbox"
                          name="selected"
                          onChange={toggleSelectAll}
                          disabled={!activeCompanyIsEditable}
                        />
                      </FormGroup>
                    </Th>
                    <Th>Asset Name</Th>
                    <Th className="text-center" style={{ minWidth: "185px" }}>
                      Category
                    </Th>
                    <Th>Purchase Cost</Th>
                    <Th>Non Depreciable Amount</Th>
                    <Th>Purchase Date</Th>
                    <Th>Useful Life(Months)</Th>
                    <Th>Monthly Depreciation Amount</Th>
                    <Th>
                      Accumulated Depreciation Amount
                      <br /> as {formatDate(company.starting_date)}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lines.map((line, index) => {
                    return (
                      <AssetsLine
                        index={index}
                        line={line}
                        setLines={setLines}
                        deleteLine={deleteLine}
                        lines={lines}
                        categories={categories}
                      />
                    );
                  })}
                </Tbody>
              </Table>
            </div>
            {activeCompanyIsEditable && (
              <Button color="link" onClick={handleAddNew}>
                Add New Line
              </Button>
            )}
          </AvForm>
          <Row>
            <Col md="12">
              <TotalLines
                title="Total Accumulated Depreciation for all Assets"
                total={total}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ActionFooter
        next={next}
        canSave={canSave}
        back={back}
        complete={saveAssets}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(Assets);
