import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.css";
// import ContentForm from "./ContentForm";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ExitButton from "../../ExitButton";
import AlertModal from "../../Modal/AlertModal";
import useModal from "../../Modal/useModal";
import InfoPopover from "../../InfoPopover";

const ActionHeader = ({
  canSave,
  save,
  saveExit,
  deleteRows,
  lines,
  activeCompanyIsEditable,
}) => {
  const [modal, setModal] = useState(false);
  const callPromptModal = useModal(AlertModal);

  const toggle = async () => {
    if (lines?.some((line) => line.selected)) setModal(!modal);
    else {
      const res = await callPromptModal({
        title: "Deleting items",
        message: `no items to delete, please select a one at least`,
      });
    }
  };
  return (
    <div className="action-header">
      <div className="main-content">
        <div className="content" style={{ marginTop: "0px" }}>
          <div className="container-fluid">
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} charCode="Y">
                Confirm Delete
              </ModalHeader>
              <ModalBody>
                Are you sure you want to delete{" "}
                {lines?.every((line) => line.selected) &&
                lines?.filter((line) => line.selected)?.length > 1
                  ? "all"
                  : "selected"}{" "}
                {lines?.filter((line) => line.selected)?.length > 1
                  ? "items"
                  : "item"}
                ?
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    deleteRows();
                    toast.info(`Selected ${lines?.filter((line) => line.selected)?.length > 1 ? "items are" : "item is"}  deleted successfully`);
                    toggle();
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <div>
                <ExitButton />
                <InfoPopover />
              </div>
              <div>
                {activeCompanyIsEditable && deleteRows && (
                  <Button
                    type="button"
                    color="light"
                    onClick={toggle}
                    style={{
                      marginRight: 10,
                    }}
                    title="Delete"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </Button>
                )}
                {activeCompanyIsEditable && save && (
                  <Button
                    type="button"
                    color="light"
                    onClick={save}
                    style={{
                      marginRight: 10,
                    }}
                    disabled={!canSave}
                    title="Save"
                  >
                    <i class="fas fa-save"></i>
                  </Button>
                )}
                {activeCompanyIsEditable && saveExit && (
                  <Button
                    type="button"
                    color="primary"
                    onClick={saveExit}
                    disabled={!canSave}
                    title="Save & Exit"
                  >
                    Save & Exit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(ActionHeader);
