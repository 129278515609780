import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import uploadFile from "./layout/file/uploadFileReducer";
import imageFile from "./layout/file/uploadImageReducer";
import ImageViewerReducer from "./layout/ImageViewer/reducer";
import PdfViewerReducer from "./layout/PdfViewer/reducer";
import SheetViewerReducer from "./layout/SheetViewer/reducer";
import WordViewerReducer from "./layout/WordViewer/reducer";
import Files from "./Files/reducer";
import Loader from "./Loader/reducer";
// Authentication
import Login from "./auth/login/reducer";
import Users from "./auth/Users/reducer";
import Companies from "./auth/Companies/reducer";
import Assign from "./auth/AssignTome/reducer";
import Wizard from "./auth/WizardStatus/reducer";
import CompanyState from "./CompanyState/reducer";
import BasicInfo from "../pages/Companies/company/forms/BasicInfo/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Users,
  Companies,
  Assign,
  BasicInfo,
  Wizard,
  CompanyState,
  uploadFile,
  imageFile,
  Files,
  Loader,
  ImageViewerReducer,
  PdfViewerReducer,
  SheetViewerReducer,
  WordViewerReducer,
});

export default rootReducer;
