export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";

export const CREATE_COMPANY = "CREATE_COMPANY";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const API_ERROR = "COMPANIES_API_ERROR";
export const REST_ERROR = "COMPANIES_REST_ERROR";

export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
