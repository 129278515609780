import {
  NEXT_STATE,
  PREVIOUS_STATE,
  RESET_STATE,
  SET_NEXT_STEP,
  SET_ACTIVE_COMPANY,
  SET_ACTIVE_COMPANY_IS_EDITABLE,
  SAVE_ACTIVE_COMPANY,
} from "./actionTypes.js";
export const nextState = (company) => {
  return {
    company: company,
    type: NEXT_STATE,
  };
};

export const previousState = (company) => {
  return {
    company: company,
    type: PREVIOUS_STATE,
  };
};

export const saveActiveCompany = (company) => {
  return {
    company: company,
    type: SAVE_ACTIVE_COMPANY,
  };
};

export const resetState = (states, currentState) => {
  return {
    type: RESET_STATE,
    states: states,
    currentState: currentState,
  };
};

export const setNextStep = (nextStep) => {
  return {
    type: SET_NEXT_STEP,
    nextStep: nextStep,
  };
};
export const setActiveCompany = (company) => {
  return {
    type: SET_ACTIVE_COMPANY,
    company: company,
  };
};
export const setActiveCompanyIsEditable = (isEditable) => {
  return {
    type: SET_ACTIVE_COMPANY_IS_EDITABLE,
    isEditable: isEditable,
  };
};
