import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { Tr, Td } from "react-super-responsive-table";

import ResponsiveTables from "../../../components/Table";

import { fetchUsers, restError } from "../../../store/auth/Users/actions";

const headCells = [
  {
    id: "first-name",
    label: "First Name",
  },
  {
    id: "last-name",
    label: "Last Name",
  },
  {
    id: "usename",
    label: "User Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone-number",
    label: "Phone Number",
  },
  {
    id: "status",
    label: "Status",
  },
];
const ListUsers = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { fetchUsers, list, error, restError } = props;
  const handleRouting = () => {
    history.push(`${location.pathname}/createUser`);
  };
  const editUser = (e, user) => {
    history.push(`${location.pathname}/createUser`, { user: user });
  };
  useEffect(() => {
    fetchUsers();
    return () => {
      restError();
    };
  }, []);

  return (
    <div className="page-content">
      <ResponsiveTables
        headCells={headCells}
        title="Users"
        btnText="Add new User"
        handleRouting={handleRouting}
        error={error}
      >
        {list.map((item) => {
          const identifier = `${item.first_name}- ${item.username}`;

          return (
            <Tr
              key={identifier}
              onClick={(e) => {
                editUser(e, item);
              }}
              style={{ cursor: "pointer" }}
            >
              <Td>{item.first_name}</Td>
              <Td>{item.last_name}</Td>
              <Td>{item.username}</Td>
              <Td>{item.email}</Td>
              <Td>{item.mobile}</Td>
              <Td>
                {item.is_active ? (
                  <div className="font-size-12 badge-soft-success badge badge-secondary badge-pill">
                    <span>Active</span>
                  </div>
                ) : (
                  <div className="font-size-12 badge-soft-danger badge badge-secondary badge-pill">
                    <span>Inactive</span>
                  </div>
                )}
              </Td>
            </Tr>
          );
        })}
      </ResponsiveTables>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.Users.list,
    error: state.Users.error,
  };
};

export default connect(mapStateToProps, { fetchUsers, restError })(ListUsers);

ListUsers.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  fetchUsers: PropTypes.func,
  restError: PropTypes.func,
  error: PropTypes.string,
};
