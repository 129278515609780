import {
  FETCH_COMPANIES,
  CREATE_COMPANY,
  API_ERROR,
  CREATE_COMPANY_SUCCESS,
  FETCH_COMPANIES_SUCCESS,
  REST_ERROR,
  FETCH_ROLE,
  FETCH_ROLE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errors: {},
  list: [],
  count: 0,
  loading: false,
  error: "",
  loadingRole: false,
  role:
    (localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))) ||
    {},
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLE:
      state = {
        ...state,
        loadingRole: true,
      };
      break;
    case FETCH_ROLE_SUCCESS:
      state = {
        ...state,
        loadingRole: false,
        error: "",
        role: action.payload,
      };
      break;
    case FETCH_COMPANIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_COMPANIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        list: action.payload.results,
        count: action.payload.count,
      };
      break;
    case CREATE_COMPANY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_COMPANY_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;
    case API_ERROR:
      if (typeof action.payload === "object") {
        state = { ...state, errors: action.payload, loading: false };
      } else {
        state = { ...state, error: action.payload, loading: false };
      }
      break;
    case REST_ERROR:
      state = { ...state, errors: {}, error: "" };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default users;
