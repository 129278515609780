import { ADD_FILE, DELETE_FILE } from "./actionTypes";

const initialState = {
  files: null,
};

const Files = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILE:
      return {
        ...state,
        files: { ...state.files, [action.file.name]: action.file },
      };
    case DELETE_FILE:
      return {
        ...state,
        files: { ...state.files, [action.file_name]: null },
      };
    default:
      return state;
  }
};
export default Files;
