import React from "react";
import { formatNumber } from "../../utils";

const TotalLines = ({ title, total, totalColorStyle }) => {
  return (
    <div className="text-center">
      <h4>
        {title}: <span style={totalColorStyle}>{formatNumber(total)} AED</span>
      </h4>
    </div>
  );
};
export default TotalLines;
