import { OPEN_WORD_VIEWER, CLOSE_WORD_VIEWER } from "./actionTypes";

export const openWordViewer = (file) => ({
  type: OPEN_WORD_VIEWER,
  payload: file,
});

export const closeWordViewer = () => ({
  type: CLOSE_WORD_VIEWER,
});
