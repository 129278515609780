import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import { connect } from "react-redux";
import { Tr, Td } from "react-super-responsive-table";
import Max2DigitsNumberInput from "../../Inputs/Max2DigitsNumberInput";

const DebitLine = ({
  index,
  line,
  setLines,
  lines,
  type,
  activeCompanyIsEditable,
}) => {
  const handleOnChangeInput = (e) => {
    line[e.target.name.split("-")[0]] = e.target.value;
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleOnDelete = (e) => {
    line.selected = e.target.checked;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const onChangeTotalValue = (value, name) => {
    line[name.split("-")[0]] = value;
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  return (
    <Tr>
      <Td style={{ minWidth: "10px" }}>
        <FormGroup hidden>
          <AvField name="id" type="text" value={line?.id || ""} />
        </FormGroup>

        <FormGroup>
          <AvField
            type="checkbox"
            name="selected"
            checked={line?.selected || ""}
            onChange={handleOnDelete}
            disabled={line?.is_retail || !activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`partner_name-${index}`}
            placeholder={`${
              type == "receivables" ? "Customer" : "Supplier"
            } Name`}
            type="text"
            value={line?.partner_name || ""}
            errorMessage={`Enter ${
              type == "receivables" ? "Customer" : "Supplier"
            } Name`}
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
              pattern: {
                value: "^[A-Za-z ]+$",
                errorMessage: "input accepts only characters",
              },
              minLength: {
                value: 3,
                errorMessage: "Min 3 chars.",
              },
              maxLength: {
                value: 30,
                errorMessage: "Max 30 chars.",
              },
            }}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`partner_tax_id-${index}`}
            placeholder={`${
              type == "receivables" ? "Customer" : "Supplier"
            } Tax ID`}
            type="text"
            value={line?.partner_tax_id || ""}
            errorMessage={`Enter ${
              type == "receivables" ? "Customer" : "Supplier"
            } Tax ID`}
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: false },

              pattern: {
                value: "^[0-9]+$",
                errorMessage: "input accepts only numbers",
              },
              maxLength: {
                value: 25,
                errorMessage: "Max 25 chars.",
              },
            }}
            disabled={line?.is_retail || !activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>

      <Td>
        <FormGroup>
          <Max2DigitsNumberInput
            name={`total-${index}`}
            value={line?.total || ""}
            onChangeValue={onChangeTotalValue}
            disabled={line?.is_retail || !activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
    </Tr>
  );
};
const mapStateToProps = (state) => {
  return {
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(DebitLine);
