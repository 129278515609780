import axios from "axios";
import { BASEURL } from ".";

const postUser = (url, data) => {
  return axios
    .post(`${BASEURL}${url}`, data)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const getUsers = url => {
  return axios
    .get(`${BASEURL}${url}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message =
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};

export { postUser, getUsers };
