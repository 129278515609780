import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
const ExitButton = ({ className }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  let history = useHistory();
  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle} charCode="Y">
          Confirm Exit
        </ModalHeader>
        <ModalBody>Are you sure you want to exit?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              history.goBack();
            }}
          >
            Exit
          </Button>
        </ModalFooter>
      </Modal>
      <Button
        type="button"
        color="light"
        onClick={toggle}
        style={{
          marginRight: 10,
        }}
        title="Exit"
      >
        <i class="fas fa-sign-out-alt fa-flip-horizontal"></i>
      </Button>
    </React.Fragment>
  );
};
export default ExitButton;
