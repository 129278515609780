import {
  BASIC_INFO_GET_REQUEST,
  API_ERROR,
  BASIC_INFO_GET_SUCCESS,
  REST_ERROR,
  REST_STATE,
  BASIC_INFO_GET_STATE_REQUEST,
  BASIC_INFO_GET_STATE_SUCCESS,
  BASIC_INFO_GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  SET_BASIC_INFO_ITEM,
  UPDATE_COMPANY_BASIC_INFO,
  UPDATE_COMPANY_BASIC_INFO_SUCCESS,
} from "./actionTypes";

export const basicInfoGetRequest = (companyId) => {
  return {
    type: BASIC_INFO_GET_REQUEST,
    payload: { companyId },
  };
};

export const basicInfoGetSuccess = (data) => {
  return {
    type: BASIC_INFO_GET_SUCCESS,
    payload: data,
  };
};

export const basicInfoGetStateRequest = () => {
  return {
    type: BASIC_INFO_GET_STATE_REQUEST,
  };
};

export const basicInfoGetStateSuccess = (data) => {
  return {
    type: BASIC_INFO_GET_STATE_SUCCESS,
    payload: data,
  };
};

export const basicInfoGetCountryRequest = () => {
  return {
    type: BASIC_INFO_GET_COUNTRY_REQUEST,
  };
};

export const basicInfoGetCountrySuccess = (data) => {
  return {
    type: GET_COUNTRY_SUCCESS,
    payload: data,
  };
};

export const setBasicInfoItem = (item) => {
  return {
    type: SET_BASIC_INFO_ITEM,
    payload: item,
  };
};

export const updateCompanyBasicInfo = (data) => {
  return {
    type: UPDATE_COMPANY_BASIC_INFO,
    payload: data,
  };
};

export const updateBasicInfoSuccess = ({ type, basicInfoItem }) => {
  return {
    type: UPDATE_COMPANY_BASIC_INFO_SUCCESS,
    payload: { type, basicInfoItem },
  };
};
export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const restError = () => {
  return {
    type: REST_ERROR,
  };
};
export const restStateAssign = () => {
  return {
    type: REST_STATE,
  };
};
