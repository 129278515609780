import { OPEN_IMAGE_VIEWER, CLOSE_IMAGE_VIEWER } from "./actionType";

export const openImageViewer = (image) => ({
  type: OPEN_IMAGE_VIEWER,
  payload: image,
});

export const closeImageViewer = () => ({
  type: CLOSE_IMAGE_VIEWER,
});
