import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  ASSIGN_TO_ME_REQUEST,
  ASSIGN_TO_ME_REVIEW_REQUEST,
  START_ON_BOARDING_REQUEST,
  START_REVIEW_REQUEST,
  CHANGE_ON_BOARDING_REQUEST,
  CHANGE_REVIEWER_REQUEST,
} from "./actionTypes";
import {
  apiError,
  createAssignSuccess,
  createAssignReviewSuccess,
  startOnBoardingSuccess,
  changeOnBoardingSuccess,
  startReviewSuccess,
} from "./actions";

import {
  postAssign,
  postStartOnBoarding,
  postChangeOnBoarding,
} from "../../../api/assignApI";

function* createAssign({ payload: { companyId } }) {
  try {
    yield call(postAssign, `/company/assignToMe/${companyId}`);
    yield put(createAssignSuccess());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* createAssignReview({ payload: { companyId } }) {
  try {
    yield call(postStartOnBoarding, `/company/assignToMeReview/${companyId}`);
    yield put(createAssignReviewSuccess());
  } catch (error) {
    yield put(apiError(error));
  }
}
function* createStartOnBoarding({ payload: { companyId } }) {
  try {
    yield call(postStartOnBoarding, `/company/startOnboarding/${companyId}`);
    yield put(startOnBoardingSuccess());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* createStartReview({ payload: { companyId } }) {
  try {
    yield call(postStartOnBoarding, `/company/startReview/${companyId}`);
    yield put(startReviewSuccess());
  } catch (error) {
    yield put(apiError(error));
  }
}
function* createChangeOnBoarding({ payload: { companyId, userId } }) {
  try {
    yield call(postChangeOnBoarding, `/company/assign/${companyId}`, userId);
    yield put(changeOnBoardingSuccess());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* createChangeReviewer({ payload: { companyId, userId } }) {
  try {
    yield call(
      postChangeOnBoarding,
      `/company/assignReviewer/${companyId}`,
      userId
    );
    yield put(changeOnBoardingSuccess());
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchCreateAssign() {
  yield takeEvery(ASSIGN_TO_ME_REQUEST, createAssign);
  yield takeEvery(ASSIGN_TO_ME_REVIEW_REQUEST, createAssignReview);
  yield takeEvery(START_ON_BOARDING_REQUEST, createStartOnBoarding);
  yield takeEvery(START_REVIEW_REQUEST, createStartReview);
  yield takeEvery(CHANGE_ON_BOARDING_REQUEST, createChangeOnBoarding);
  yield takeEvery(CHANGE_REVIEWER_REQUEST, createChangeReviewer);
}

function* sagaAssign() {
  yield all([fork(watchCreateAssign)]);
}

export default sagaAssign;
