import React from "react";

import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { putOnboarder } from "../../api/common";

import { resetState } from "../../store/CompanyState/actions.js";
const CompleteButton = ({
  nextAction,
  backAction,
  currentState,
  states,
  resetState,
  company,
  canSave,
  save,
}) => {
  let history = useHistory();

  const onClickComplete = () => {
    save(() => {
      putOnboarder(`/UserManagement/${company?.id}/complete_company_state`, {})
        .then((data) => {
          resetState(data.wizard_states, currentState.code);
        })
        .catch((error) => {
          throw error;
        });
    });
  };
  return (
    <Button
      type="button"
      color="success"
      style={{
        marginRight: 10,
      }}
      disabled={!canSave}
      onClick={onClickComplete}
      title={`Complete ${currentState.name}`}
    >
      Complete {currentState.name}
    </Button>
  );
};

const mapStateToProps = (state) => {
  return {
    currentState: state.CompanyState.currentState,
    nextAction: state.CompanyState.nextAction,
    backAction: state.CompanyState.backAction,
    states: state.CompanyState.states,
    company: state.CompanyState.activeCompany,
  };
};
export default connect(mapStateToProps, { resetState })(CompleteButton);
