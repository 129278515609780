import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { getOnboarder } from "../../api/common";
import NotificationCard from "./NotificationCard";
import Indicator from "./indicator";
import "./style.css";
const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const getNotifications = () => {
    getOnboarder(`/notifications/notifications/`).then((data) => {
      setNotifications(data["results"]);
    });
  };
  const toggle = () => {
    if (!dropdownOpen) getNotifications();
    setDropdownOpen((prevState) => !prevState);
  };
  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} inNavbar size="lg">
        <DropdownToggle nav>
          <Indicator />
          <span style={{ fontSize: "x-large" }} title="notifications">
            <i className="fas fa-bell"></i>
          </span>
        </DropdownToggle>
        <DropdownMenu right className="notification-dropdown" nav>
          <div className="notification-title">Notifications</div>
          <div className="notification-list">
            <div className="list-group">
              {notifications.slice(0, 4).map((notification, index) => {
                return (
                  <NotificationCard
                    notification={notification}
                    updateNotification={getNotifications}
                  />
                );
              })}
            </div>
          </div>
          <div class="list-footer">
            <Link to="/notifications">View all notifications</Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Notification;
