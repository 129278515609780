import React from "react";
import { Button } from "reactstrap";
import { postOnboarder } from "../../api/common";
import { formatDateTime } from "../../utils";

const NotificationCard = ({ notification, updateNotification }) => {
  const readNotification = () => {
    postOnboarder(`/notifications/notifications/${notification?.id}/read`)
      .then((data) => {
        updateNotification();
      })
      .catch((error) => {
        throw error;
      });
  };
  return (
    <div
      className={
        "list-group-item list-group-item-action " +
        (notification.is_read ? "" : "unread")
      }
    >
      <div className="notification-info">
        {/* <div className="notification-list-user-img"><img src="../assets/images/avatar-2.jpg" alt="" className="user-avatar-md rounded-circle" /></div> */}
        <div>
          <div className="notification-list-user-img">
            <span
              style={{
                fontSize: "x-large",
                color: notification.is_read ? "gray" : "blue",
              }}
            >
              <i className="fas fa-bell"></i>
            </span>
          </div>
          <div className="notification-list-user-block">
            <span className="notification-list-user-name">
              {notification.title}
            </span>
            <span>{notification?.message}.</span>
            <div className="notification-date">
              {formatDateTime(notification.created_at)}
            </div>
          </div>
        </div>
        <div className="d-flex">
          {!notification.is_read && (
            <Button
              color="light"
              style={{ height: "40px" }}
              onClick={readNotification}
            >
              <i class="fas fa-check"></i>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
