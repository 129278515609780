import React, { useState, useEffect } from "react";
import { AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { FormGroup, Button } from "reactstrap";
import { monthDiff } from "../../../utils";
import { formatNumber } from "../../../utils";
import Max2DigitsNumberInput from "../../Inputs/Max2DigitsNumberInput";
import Max2DigitsPositiveNumberInput from "../../Inputs/Max2DigitsPositiveNumberInput";
const ContractLine = ({
  index,
  line,
  users,
  banks,
  setLines,
  deleteLine,
  lines,
  accounts,
  company,
  activeCompanyIsEditable,
}) => {
  const onChangeLine = (line) => {
    if (line?.useful_life && line?.amount) {
      line["amortization"] = (line.amount / line.useful_life).toFixed(2);
    }

    if (
      line?.contract_date &&
      company?.starting_date &&
      line?.amortization &&
      line?.paid_amount
    ) {
      const date1 = new Date(line.contract_date);
      const date2 = new Date(company.starting_date);
      date1.setDate(1); // going to 1st of the month
      date1.setHours(-1); // going to last hour before this date even started.
      date2.setDate(1); // going to 1st of the month
      date2.setHours(-1); // going to last hour before this date even started.
      const diffMonths = monthDiff(date1, date2);
      const calc = (
        line?.paid_amount -
        line?.amortization * diffMonths
      ).toFixed(2);
      line["balance_amount"] = Math.max(calc, 0);
      line["liability_amount"] = Math.abs(Math.min(calc, 0));
    }
  };
  const handleOnChangeInput = (e) => {
    line[e.target.name.split("-")[0]] = e.target.value;
    line.updated = true;
    let items = [...lines];
    onChangeLine(line);
    items[index] = line;
    setLines(items);
  };
  const handleOnChangeNumber = (value, name) => {
    line[name.split("-")[0]] = value;
    line.updated = true;
    let items = [...lines];
    onChangeLine(line);
    items[index] = line;
    setLines(items);
  };
  const handleOnDelete = (e) => {
    line.selected = e.target.checked;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };

  const isOver = (startingDate, purchaseDate, useful_life) => {
    const usefulTime = new Date(
      purchaseDate.setMonth(purchaseDate.getMonth() + parseFloat(useful_life))
    );
    if (usefulTime <= startingDate) {
      return true;
    } else {
      return false;
    }
  };
  const isOverDate = (value, ctx, input, cb) => {
    const startingDate = new Date(company.starting_date);
    if (isOver(startingDate, new Date(value), line?.useful_life)) {
      cb("This contract is over, it should be added through Labilities page");
    } else {
      return true;
    }
  };

  const isOverUsefulLife = (value, ctx, input, cb) => {
    const startingDate = new Date(company.starting_date);
    if (isOver(startingDate, new Date(line?.contract_date), value)) {
      cb("This contract is over, it should be added through Labilities page");
    } else {
      return true;
    }
  };
  return (
    <tr>
      <td style={{ minWidth: "10px" }}>
        <FormGroup hidden>
          <AvField name="id" type="text" value={line?.id || ""} />
        </FormGroup>

        <FormGroup>
          <AvField
            type="checkbox"
            name="selected"
            checked={line?.selected || ""}
            onChange={handleOnDelete}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <AvField
            name={`name-${index}`}
            placeholder=" Name"
            type="text"
            value={line?.name || ""}
            errorMessage="Enter Name"
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
              maxLength: {
                value: 30,
                errorMessage: "Max 30 chars.",
              },
            }}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </td>

      <td>
        <FormGroup>
          <AvField
            type="select"
            name={`account-${index}`}
            value={line?.account || ""}
            onChange={handleOnChangeInput}
            validate={{
              required: {
                value: true,
                errorMessage: "Mandatory field",
              },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            {accounts.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.name.replace("Prepaid ", "")}
              </option>
            ))}
          </AvField>
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <Max2DigitsPositiveNumberInput
            name={`amount-${index}`}
            type="text"
            value={line?.amount || ""}
            onChangeValue={handleOnChangeNumber}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <Max2DigitsNumberInput
            name={`paid_amount-${index}`}
            placeholder="Paid Amount"
            type="text"
            onChangeValue={handleOnChangeNumber}
            value={line?.paid_amount || ""}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <AvField
            name={`contract_date-${index}`}
            value={line?.contract_date || ""}
            placeholder="Contract Date"
            type="date"
            onChange={handleOnChangeInput}
            errorMessage="Enter Contract Date"
            validate={{
              required: { value: true },
              custom: isOverDate,
            }}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <AvField
            type="text"
            name={`useful_life-${index}`}
            value={line?.useful_life || ""}
            onChange={handleOnChangeInput}
            errorMessage="Enter Useful Life"
            validate={{
              required: { value: true },
              pattern: {
                value: "^[0-9]+$",
                errorMessage: "input accepts only positive numbers",
              },
              custom: isOverUsefulLife,
            }}
            disabled={!activeCompanyIsEditable}
          ></AvField>
        </FormGroup>
      </td>
      <td>{formatNumber(line.amortization)} AED</td>
      <td>{formatNumber(line.balance_amount)} AED</td>
      <td>{formatNumber(line.liability_amount)} AED</td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(ContractLine);
