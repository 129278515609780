import React, { useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AlertModal = ({ title, message, onResolve, onReject }) => {
  const backRef = useRef(null);

  return (
    <Modal ref={backRef} isOpen={backRef}>
      <ModalHeader toggle={() => onReject()} charCode="X">
        {title}
      </ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="success" onClick={() => onResolve(true)}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AlertModal;
