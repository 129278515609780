import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  FormGroup,
  Label,
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { postAccountingSystems } from "../../../api/common";
const AddNewAccountingSystem = ({ modal, toggle, handleAddNew }) => {
  const [errors, setErrors] = useState({});
  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        setErrors({});
        toggle();
      }}
    >
      &times;
    </button>
  );

  const onValidSubmit = (e, vals) => {
    postAccountingSystems(`/accountingSystem/`, vals)
      .then((data) => {
        handleAddNew(data);
        setErrors({});
        toggle();
      })
      .catch((error) => {
        setErrors(error);
        throw error;
      });
  };
  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <AvForm onValidSubmit={onValidSubmit}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Modal title
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <Row>
              <Card>
                <CardBody>
                  <Row>
                    <FormGroup>
                      <Label htmlFor="validationCustom01">Name*</Label>
                      <AvField
                        name="name"
                        placeholder="Name"
                        type="text"
                        errorMessage="Enter Name"
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="validationCustom01"
                      />
                      {errors?.name && (
                        <Alert color="danger">{errors.name[0]}</Alert>
                      )}
                    </FormGroup>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Add New
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              setErrors({});
              toggle();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(AddNewAccountingSystem);
