import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FETCH_COMPANIES, CREATE_COMPANY, FETCH_ROLE } from "./actionTypes";
import {
  fetchCompaniesSuccess,
  apiError,
  createCompanySuccess,
  fetchRoleSuccess,
} from "./actions";

import { getCompanies, postCompany, getRoles } from "../../../api/companiesAPI";

function* fetchCompanies({ url }) {
  try {
    const response = yield call(getCompanies, url);
    yield put(fetchCompaniesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* createCompany({ payload: { user, history } }) {
  try {
    const response = yield call(postCompany, "/company/", user);
    yield put(createCompanySuccess(response));
    history.push("/companies");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRole() {
  try {
    const response = yield call(getRoles, "/users/me");
    localStorage.setItem("user", JSON.stringify(response));
    yield put(fetchRoleSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchFetchCompanies() {
  yield takeEvery(FETCH_COMPANIES, fetchCompanies);
}

export function* watchCreateCompany() {
  yield takeEvery(CREATE_COMPANY, createCompany);
}

export function* watchGetRoles() {
  yield takeEvery(FETCH_ROLE, getRole);
}

function* sagaCompanies() {
  yield all([
    fork(watchFetchCompanies),
    fork(watchCreateCompany),
    fork(watchGetRoles),
  ]);
}

export default sagaCompanies;
