import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { } from "../../store/actions";

//Simple bar
import SimpleBar from "simplebar-react";

//i18n
import SidebarContent from "./SidebarContent";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleMenu = this.toggleMenu.bind(this);

  }
/**
   * Toggle sidebar
   */
 toggleMenu() {
  this.props.toggleMenuCallback();
}
  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {this.props.type !== "condensed" ? (
              <SimpleBar style={{ maxHeight: "100%" }}>
                <div>
                  <button
                  type="button"
                  onClick={this.toggleMenu}
                  className="btn btn-sm font-size-16 header-item waves-effect"
                  id="vertical-menu-btn"
                  style={{padding: "15px 20px", widows: "100%"}}
                >
                  <i className="fa fa-fw fa-bars"></i>
                </button>
                </div>
                <SidebarContent roleUser={this.props.roleUser} />
              </SimpleBar>
            ) : (
              <>
              <div>
                  <button
                  type="button"
                  onClick={this.toggleMenu}
                  className="btn btn-sm font-size-16 header-item waves-effect"
                  id="vertical-menu-btn"
                  style={{padding: "15px 20px", width: "100%"}}                >
                  <i className="fa fa-fw fa-bars"></i>
                </button>
                </div>
              <SidebarContent roleUser={this.props.roleUser} 
              />
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    roleUser: state.Companies.role
  };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
