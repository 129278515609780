import axios from "axios";
import { BASEURL } from ".";

const postAssign = url => {
  return axios
    .put(`${BASEURL}${url}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};

const postStartOnBoarding = url => {
  return axios
    .put(`${BASEURL}${url}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};
const postChangeOnBoarding = (url, user) => {
  return axios
    .put(`${BASEURL}${url}`, { user })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      let message;
      if (err?.response?.status) {
        switch (err.response.status) {
          case 404:
            message =
              "Sorry! the server you are calling for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err?.response?.data || "Sorry! something went wrong";
            break;
        }
      }
      throw message || "Sorry! something went wrong";
    });
};

export { postAssign, postStartOnBoarding, postChangeOnBoarding };
