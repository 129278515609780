import { SHOW_LOADER, REMOVE_LOADER } from "./actionTypes";

const initialState = {
  isLoading: false,
};

const Loader = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
export default Loader;
