import {
  BASIC_INFO_GET_REQUEST,
  API_ERROR,
  BASIC_INFO_GET_SUCCESS,
  REST_ERROR,
  REST_STATE,
  BASIC_INFO_GET_STATE_REQUEST,
  BASIC_INFO_GET_STATE_SUCCESS,
  BASIC_INFO_GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  SET_BASIC_INFO_ITEM,
  UPDATE_COMPANY_BASIC_INFO,
  UPDATE_COMPANY_BASIC_INFO_SUCCESS,
} from "./actionTypes";
const initialState = {
  errors: {},
  loading: false,
  error: "",
  success: false,
  data: {},
  stateList: [],
  countryList: [],
  basicInfoItem: {
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    name: "",
    trade_license: 0,
    street: "",
    street2: "",
    city: "",
    zip: "",
    state: 0,
    country: 0,
    trade_license_file: "",
    article_of_association_file: "",
    e_id_file: "",
  },
  updatedBasicInfoItem: {},
  successUpdate: false,
};
const basicInfo = (state = initialState, action) => {
  switch (action.type) {
    case BASIC_INFO_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        success: false,
      };
      break;

    case BASIC_INFO_GET_SUCCESS:
      state = {
        ...state,
        loading: false,
        basicInfoItem: action.payload,
        updatedBasicInfoItem: {
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          name: action.payload.name,
        },
      };
      break;

    case BASIC_INFO_GET_STATE_REQUEST:
      state = {
        ...state,
        loading: true,
        success: false,
      };
      break;

    case BASIC_INFO_GET_STATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        stateList: action.payload,
      };
      break;
    case BASIC_INFO_GET_COUNTRY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_COUNTRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        countryList: action.payload,
      };
      break;
    case SET_BASIC_INFO_ITEM:
      state = {
        ...state,
        updatedBasicInfoItem: action.payload,
      };
      break;

    case UPDATE_COMPANY_BASIC_INFO:
      state = {
        ...state,
        loading: true,
        typeAction: action.payload.type,
        companyId: action.payload.companyId,
        successUpdate: false,
      };
      break;
    case UPDATE_COMPANY_BASIC_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        successUpdate: true,
      };
    case API_ERROR:
      if (typeof action.payload === "object") {
        state = { ...state, errors: action.payload, loading: false };
      } else {
        state = { ...state, error: action.payload, loading: false };
      }
      break;
    case REST_ERROR:
      state = { ...state, errors: {}, error: "" };

    case REST_STATE:
      state = { ...initialState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default basicInfo;
