import React from "react";
import defaultImg from "../../assets/images/profile3.jpg";
const ProfileImg = ({ img, username, width }) => {
  return (
    <div>
      <img
        className="rounded-circle"
        alt={username || ""}
        width={width || "50px"}
        height={width || "50px"}
        src={img || defaultImg}
      />
    </div>
  );
};

export default ProfileImg;
