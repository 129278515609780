import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import profileIcon from "../../../assets/images/profile-icon.png";
import { getOnboarder, postOnboarder } from "../../../api/common";
import {
  setNextStep,
  nextState,
  previousState,
} from "../../../store/CompanyState/actions";

import CategoryLine from "../AccountingCategories/CategoryLine";
const CostCentersGroup = ({
  setCanSave,
  setNextStep,
  nextState,
  previousState,
  type,
  form,
  lines,
  setLines,
  deleted,
  setDeleted,
  company,
  activeCompanyIsEditable,
}) => {
  let history = useHistory();
  const [errors, setErrors] = useState({});
  const deleteLine = (id) => {
    setDeleted((deleted) => [...deleted, id]);
  };
  useEffect(() => {
    getOnboarder(`/UserManagement/${company?.id}/cost-center`).then((data) => {
      data.forEach((line) => {
        line.updated = false;
        line.is_deletable = true;
      });
      setLines(data);
    });
  }, []);

  useEffect(() => {
    if (form) {
      form.setTouched(Object.keys(form._inputs));
      const values = form.getValues();
      form.validateAll(values).then((errors) => {
        setCanSave(!form.hasError());
      });
    } else {
      setCanSave(true);
    }
  }, [lines]);
  const handleAddNew = () => {
    setLines((lines) => [...lines, { update: true, is_deletable: true }]);
  };

  const toggleSelectAll = (e) => {
    const checked = e.target.checked;
    let items = [...lines];
    items.forEach((item) => {
      if (item.is_deletable) item.selected = checked;
    });
    setLines(items);
  };
  return (
    <div className="container-fluid">
      <div className="table-container-input">
        <Table className="table-responsive-input">
          <Thead>
            <Tr>
              <Th style={{ minWidth: "10px" }}>
                <FormGroup>
                  <AvField
                    type="checkbox"
                    name="selected"
                    onChange={toggleSelectAll}
                    disabled={!activeCompanyIsEditable}
                  />
                </FormGroup>
              </Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {lines.map((line, index) => {
              return (
                <CategoryLine
                  index={index}
                  type={type}
                  line={line}
                  setLines={setLines}
                  deleteLine={deleteLine}
                  lines={lines}
                  form={form}
                />
              );
            })}
          </Tbody>
        </Table>
      </div>
      {activeCompanyIsEditable && (
        <Button className="p-2" color="link" onClick={handleAddNew}>
          Add New Line
        </Button>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
})(CostCentersGroup);
