import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  FormGroup,
  Table,
  Label,
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { getOnboarder, putOnboarder, postOnboarder } from "../../../api/common";
const CopyFromSales = ({
  modal,
  company,
  toggle,
  copyDone,
  salesLines,
  uomList,
}) => {
  const [errors, setErrors] = useState({});
  const [lines, setLines] = useState([]);
  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const checkIcon = (flag) => {
    return flag ? (
      <i className="fas fa-check"></i>
    ) : (
      <i className="fas fa-times"></i>
    );
  };

  const onValidSubmit = (e, vals) => {
    let ids = Object.keys(vals);
    let selectedLines = salesLines.filter((line) => {
      return (
        ids.includes(line?.id?.toString()) &&
        vals[line.id?.toString()] &&
        !line.is_purchase
      );
    });
    const body = { copy: selectedLines.map((line) => line.id) };
    postOnboarder(`/UserManagement/${company?.id}/purchase/submit`, body)
      .then((data) => {
        toggle();
        copyDone();
      })
      .catch((error) => {
        setErrors(error);
      });
  };
  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <AvForm className="needs-validation" onValidSubmit={onValidSubmit}>
        <ModalBody>
          <h3>Copy From Sales</h3>
          <Row>
            <Col>
              {!!Object.keys(errors).length && (
                <Alert color="danger">{Object.values(errors[0])}</Alert>
              )}
            </Col>
          </Row>
          <Table className="table-responsive">
            <thead>
              <tr>
                <th>Select</th>
                <th>Item Name</th>
                <th>Code</th>
                <th>Type</th>
                <th>Sales UoM</th>
                <th>Selling Price</th>
                <th colspan="2">Tax & Inclusive of Price</th>
                <th>QTY</th>
              </tr>
            </thead>
            <tbody>
              {salesLines.map((line, index) => {
                return (
                  <tr>
                    <td>
                      <FormGroup>
                        <AvField
                          type="checkbox"
                          name={line.id?.toString()}
                          disabled={line?.is_purchase}
                          value={line?.is_purchase}
                        />
                      </FormGroup>
                    </td>
                    <td>{line.name}</td>
                    <td>{line.code}</td>
                    <td>{line.type}</td>
                    <td>
                      {
                        uomList.find((uom) => {
                          return uom.id == line.sale_uom;
                        })?.name
                      }
                    </td>
                    <td>{line.price}</td>
                    <td>{checkIcon(line?.price_include_tax)}</td>
                    <td>{line.price}</td>
                    <td>{line.qty}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <FormGroup>
            <Button color="primary">Submit</Button>
          </FormGroup>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(CopyFromSales);
