import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

import { useHistory, useParams, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logo from "../../assets/images/logo.png";

import { handleErrorsMessage } from "../../utils";
import { postOnboarder } from "../../api/common";
import { showLoader, removeLoader } from "../../store/Loader/actions";

const ResetPassword = ({ showLoader, removeLoader }) => {
  let history = useHistory();
  const params = useParams();
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [valid, setValid] = useState(false);
  const [token, setToken] = useState(params?.token);
  const [loaded, setLoaded] = useState(false);
  const handleValidSubmit = async (event, values) => {
    try {
      showLoader();
      const vals = { token: token, ...values };
      const data = await postOnboarder("/forget_password/confirm/", vals);
      setSuccess(true);
      setErrors("");
      removeLoader();
      setTimeout(function () {
        history.push("/login");
      }, 4000);
    } catch (errors) {
      setErrors(errors);
      removeLoader();
      setSuccess(false);
    }
  };
  const checkToken = async (token) => {
    const values = { token: token };
    try {
      const data = await postOnboarder(
        "/forget_password/validate_token/",
        values
      );
      setLoaded(true);
      setValid(true);
      setErrors("");
    } catch (errors) {
      setToken("");
      setErrors(errors);
      setSuccess(false);
      setLoaded(true);
    }
  };
  useEffect(() => {
    checkToken(token);
  }, [token]);

  return (
    <>
      <div className="account-pages my-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div>
                <Row className="justify-content-center">
                  <div className="mb-4">
                    <img src={logo} alt="" height="100" />
                  </div>
                </Row>
                <Row className="justify-content-center">
                  <div className="text-primary">
                    <h5 className="text-primary">
                      Welcome to the On-boarding Wizard!
                    </h5>
                  </div>
                </Row>
              </div>
              <Card className="overflow-hidden">
                <CardBody className="pt-12">
                  <div></div>
                  {loaded && (
                    <div className="p-2">
                      {success && (
                        <Row className="justify-content-center">
                          <div className="text-primary">
                            <h3 className="text-success">
                              <i class="fas fa-check"></i>
                            </h3>
                          </div>
                          <div className="text-center">
                            <h3>Congrts, You have reset your password</h3>
                          </div>
                          <Link to="/login">back to login</Link>
                        </Row>
                      )}
                      {valid && !success && (
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={handleValidSubmit}
                          autoComplete={true}
                        >
                          {handleErrorsMessage(errors) && (
                            <Alert color="danger">
                              {handleErrorsMessage(errors)}
                            </Alert>
                          )}

                          <div className="form-group">
                            <AvField
                              name="password"
                              label="Password"
                              type="password"
                              required
                              placeholder="Enter Password"
                              validate={{
                                pattern: {
                                  value: "^[0-9a-zA-Z]+$",
                                  errorMessage: "Only Alphanumeric",
                                },
                                minLength: {
                                  value: 8,
                                  errorMessage: "Min 8 chars.",
                                },
                              }}
                            />

                            <AvField
                              name="password1"
                              type="password"
                              placeholder="Re-type Password"
                              errorMessage="Enter Re-password"
                              validate={{
                                required: true,
                                match: {
                                  value: "password",
                                  errorMessage: "password not match",
                                },
                                pattern: {
                                  value: "^[0-9a-zA-Z]+$",
                                  errorMessage: "Only Alphanumeric",
                                },
                                minLength: {
                                  value: 8,
                                  errorMessage: "Min 8 chars.",
                                },
                              }}
                            />
                          </div>
                          <div className="mt-3">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Reset Password
                            </button>
                          </div>
                        </AvForm>
                      )}
                      {!valid && !success && (
                        <Row className="justify-content-center">
                          <div className="text-primary">
                            <h3 className="text-danger">
                              <i class="fas fa-times"></i>
                            </h3>
                          </div>
                          <div className="text-center">
                            <h3>
                              Invalid Token, please use the link that in your
                              email
                            </h3>
                          </div>
                          <Link to="/login">back to login</Link>
                        </Row>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} The Accounter Technologies Ltd.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  showLoader,
  removeLoader,
})(ResetPassword);
