import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  FormGroup,
  Alert,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import ColorInput from "../../ColorInput";
import {
  nextState,
  previousState,
  setNextStep,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import FileUpload from "../../FileUpload";
import { getFilenameFromUrl } from "../../../utils";
import { handleErrorsMessage } from "../../../utils";
import debounce from "lodash.debounce";
import { getOnboarder, putFormOnboarder } from "../../../api/common";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";

const Preference = ({
  setNextStep,
  nextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const form = useRef(null);
  const [preference, setPreference] = useState({});
  const [errors, setErrors] = useState({});
  const [canSave, setCanSave] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [editInvoice, setEditInvoice] = useState(false);
  const [logo, setLogo] = useState(null);
  const onValidSubmit = (e, vals) => {};

  let savePreference = (cb) => {
    if (preference.updated) {
      setCanSave(false);
      let vals = form.current.getValues();
      vals = {
        ...vals,
        ...(preference.logo_update && {
          logo: logo || "",
        }),
      };
      showLoader();
      putFormOnboarder(`/UserManagement/${company?.id}/preference`, vals)
        .then((data) => {
          setErrors({});
          data.updated = false;
          setPreference(data);
          setEditInvoice(!data.enable_invoice_app);
          cb();
          setCanSave(true);
          removeLoader();
        })
        .catch((error) => {
          setErrors(error);
          removeLoader();
          setCanSave(true);
          throw error;
        });
    } else {
      setErrors({});
      cb();
    }
  };
  let save = debounce(() => {
    savePreference(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    savePreference(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    savePreference(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    savePreference(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  const handleOnChange = (e) => {
    const values = form.current.getValues();
    form.current.validateAll(values).then(() => {
      setCanSave(!form.current.hasError());
    });
    setPreference((preference) => {
      return { ...preference, updated: true };
    });
    setEditInvoice(!values.enable_invoice_app);
  };
  useEffect(() => {
    showLoader();
    getOnboarder(`/UserManagement/${company?.id}/preference`).then((data) => {
      data.updated = false;
      setPreference(data);
      setEditInvoice(!data.enable_invoice_app);
      if (data.logo) {
        fetch(data.logo)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], getFilenameFromUrl(data.logo), {
              type: blob.type,
            });
            setLogo(file);
          });
      }
      removeLoader();
    });
    getOnboarder(`/UserManagement/${company?.id}/receivables`).then((data) => {
      setCustomersList(data);
    });
  }, []);

  useEffect(() => {
    form.current.setTouched(Object.keys(form.current._inputs));
    setCanSave(!form.current.hasError());
  }, [form]);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  return (
    <div className="container-fluid">
      <ActionHeader canSave={canSave} save={save} saveExit={saveExit} />
      <div className="">
        <Row>
          <Col>
            {handleErrorsMessage(errors) && (
              <Alert color="danger">{handleErrorsMessage(errors)}</Alert>
            )}
          </Col>
        </Row>
        <AvForm
          ref={form}
          name="current"
          onChange={handleOnChange}
          onValidSubmit={onValidSubmit}
        >
          <Row>
            <Col>
              {!!errors?.detail && (
                <Alert color="danger">{errors?.detail}</Alert>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <CardBody>
                  <FormGroup>
                    <AvField
                      type="checkbox"
                      name="inventory_setup_report"
                      checked={preference?.inventory_setup_report || ""}
                      label="Inventory Setup & Report"
                      disabled
                    />

                    {!!errors?.inventory_setup_report && (
                      <Alert color="danger">
                        {errors?.inventory_setup_report[0]}
                      </Alert>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <AvField
                      type="checkbox"
                      name="enable_invoice_app"
                      checked={preference?.enable_invoice_app || ""}
                      label="Enable Invoice app"
                      disabled={!activeCompanyIsEditable}
                    />

                    {!!errors?.enable_invoice_app && (
                      <Alert color="danger">
                        {errors?.enable_invoice_app[0]}
                      </Alert>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <AvField
                      type="checkbox"
                      name="enable_invoice_tips"
                      checked={preference?.enable_invoice_tips || ""}
                      label="Enable Invoice Tips"
                      disabled={editInvoice || !activeCompanyIsEditable}
                    />

                    {!!errors?.enable_invoice_tips && (
                      <Alert color="danger">
                        {errors?.enable_invoice_tips[0]}
                      </Alert>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <AvField
                      type="checkbox"
                      name="enable_invoice_global_discount"
                      checked={preference?.enable_invoice_global_discount || ""}
                      label="Enable Invoice Global Discount"
                      disabled={editInvoice || !activeCompanyIsEditable}
                    />

                    {!!errors?.enable_invoice_global_discount && (
                      <Alert color="danger">
                        {errors?.enable_invoice_global_discount[0]}
                      </Alert>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <AvField
                      type="select"
                      label="Default Payment Status"
                      name="default_payment_status"
                      value={preference?.default_payment_status || ""}
                      className="form-control"
                      grid={{ xs: 7 }}
                      disabled={!activeCompanyIsEditable}
                    >
                      <option></option>
                      <option key="not_paid" value="not_paid">
                        Not Paid
                      </option>

                      <option key="fully_paid" value="fully_paid">
                        Fully Paid
                      </option>
                    </AvField>

                    {!!errors?.default_payment_status && (
                      <Alert color="danger">
                        {errors?.default_payment_status[0]}
                      </Alert>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <AvField
                      type="select"
                      label="Default Invoice Customer"
                      name="default_invoice_customer"
                      className="form-control"
                      value={preference?.default_invoice_customer || ""}
                      grid={{ xs: 7 }}
                      disabled={editInvoice || !activeCompanyIsEditable}
                    >
                      <option></option>
                      {customersList.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option?.partner_name}
                        </option>
                      ))}
                    </AvField>

                    {!!errors?.default_invoice_customer && (
                      <Alert color="danger">
                        {errors?.default_invoice_customer[0]}
                      </Alert>
                    )}
                  </FormGroup>
                  <Row>
                    <FileUpload
                      title="Logo"
                      subtitle="Upload Images"
                      nameField="logo"
                      currentFile={logo}
                      handleOnChange={(file) => {
                        setPreference({
                          ...preference,
                          logo_update: true,
                          updated: true,
                        });
                        setLogo(file);
                      }}
                      disabled={!activeCompanyIsEditable}
                    />
                    {!!errors?.logo && (
                      <Alert color="danger">{errors?.logo[0]}</Alert>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle>Template</CardTitle>
                  <FormGroup className="row">
                    <label className="col-5 ">Primary Color</label>
                    <ColorInput
                      name="primary_color"
                      preference={preference}
                      value={preference.primary_color}
                      key="primary_color"
                      disabled={editInvoice || !activeCompanyIsEditable}
                    />

                    {!!errors?.primary_color && (
                      <Alert color="danger">{errors?.primary_color[0]}</Alert>
                    )}
                  </FormGroup>
                  <FormGroup className="row">
                    <label className="col-5 ">Secondary Color</label>
                    <ColorInput
                      name="secondary_color"
                      preference={preference}
                      value={preference.secondary_color}
                      key="secondary_color"
                      disabled={editInvoice || !activeCompanyIsEditable}
                    />

                    {!!errors?.secondary_color && (
                      <Alert color="danger">{errors?.secondary_color[0]}</Alert>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <AvField
                      name="tagline"
                      placeholder="Tag Line"
                      type="text"
                      value={preference?.tagline || ""}
                      label="Tag Line"
                      className="form-control"
                      disabled={editInvoice || !activeCompanyIsEditable}
                      grid={{ xs: 7 }}
                    />

                    {!!errors?.tagline && (
                      <Alert color="danger">{errors?.tagline[0]}</Alert>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <AvField
                      name="footer"
                      placeholder="Footer"
                      type="text"
                      value={preference?.footer || ""}
                      label="Footer"
                      className="form-control"
                      disabled={editInvoice || !activeCompanyIsEditable}
                      grid={{ xs: 7 }}
                    />

                    {!!errors?.footer && (
                      <Alert color="danger">{errors?.footer[0]}</Alert>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <AvField
                      type="select"
                      label="Default Invoice Template"
                      name="default_invoice_template"
                      value={preference?.default_invoice_template || ""}
                      className="form-control"
                      grid={{ xs: 7 }}
                      disabled={editInvoice || !activeCompanyIsEditable}
                    >
                      <option></option>
                      <option key="standard" value="standard">
                        Standard
                      </option>

                      <option key="background" value="background">
                        Background
                      </option>

                      <option key="boxed" value="boxed">
                        Boxed
                      </option>

                      <option key="clean" value="clean">
                        Clean
                      </option>
                    </AvField>

                    {!!errors?.default_invoice_template && (
                      <Alert color="danger">
                        {errors?.default_invoice_template[0]}
                      </Alert>
                    )}
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </AvForm>
      </div>
      <ActionFooter
        next={next}
        canSave={canSave}
        back={back}
        complete={savePreference}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    nextStep: state.CompanyState.nextStep,
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(Preference);
