import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { CustomInput, Form } from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  FormGroup,
  Table,
  Label,
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { getOnboarder, postFormOnboarder } from "../../api/common";
import { handleErrorsAllMessage } from "../../utils";
import { toast } from "react-toastify";

const Import = ({ modal, toggle, endpoint, company }) => {
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);

  const toggleModal = () => {
    setErrors({});
    toggle();
  };

  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const checkIcon = (flag) => {
    return flag ? (
      <i className="fas fa-check"></i>
    ) : (
      <i className="fas fa-times"></i>
    );
  };

  const onValidSubmit = (e) => {
    e.preventDefault();
    let vals = { file_uploaded: file };
    postFormOnboarder(endpoint, vals)
      .then((data) => {
        setErrors([]);
        toast.success("Document Uploaded successfully");
        toggleModal();
      })
      .catch((error) => {
        setErrors(error);
        throw error;
      });
  };
  return (
    <Modal isOpen={modal} toggle={toggleModal} size="lg">
      <Form onSubmit={onValidSubmit}>
        <ModalBody>
          <h3>Import/Upload</h3>
          <Row>
            <Col>
              {handleErrorsAllMessage(errors) && (
                <Alert color="danger">{handleErrorsAllMessage(errors)}</Alert>
              )}
            </Col>
          </Row>
          <FormGroup>
            <Label for="file_uploaded">File Browser</Label>
            <CustomInput
              type="file"
              id="file_uploaded"
              name="file_uploaded"
              label="only excel files are accepted"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              accept=".xls, .xlx, .xlsx, .xlsm"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <FormGroup>
            <Button color="primary">Submit</Button>
          </FormGroup>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return { company: state.CompanyState.activeCompany };
};
export default connect(mapStateToProps, {})(Import);
