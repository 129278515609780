import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FETCH_USERS, CREATE_USER } from "./actionTypes";
import { fetchUsersSuccess, apiError, createUserSuccess } from "./actions";

import { getUsers, postUser } from "../../../api/usersAPI";

function* fetchUsers() {
  try {
    const response = yield call(getUsers, "/user/");
    yield put(fetchUsersSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* createUser({ payload: { user, history } }) {
  try {
    const response = yield call(postUser, "/user/", user);
    yield put(createUserSuccess(response));
    history.push("/users");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchFetchUsers() {
  yield takeEvery(FETCH_USERS, fetchUsers);
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_USER, createUser);
}

function* sagaUsers() {
  yield all([fork(watchFetchUsers), fork(watchCreateUser)]);
}

export default sagaUsers;
