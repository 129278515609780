import React from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import "./style.css";
const Loader = ({ isLoading }) => {
  return (
    isLoading && (
      <div className="warper-spinner">
        <Spinner
          animation="border"
          role="status"
          variant="primary"
          style={{ width: "5rem", height: "5rem" }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.Loader.isLoading,
  };
};
export default connect(mapStateToProps, {})(Loader);
