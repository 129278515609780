import { useCallback } from "react";
import { useEffect, useState } from "react";
import useInterval from "../../hooks/useInterval";

const ElapsedTime = ({
  startHour,
  endHour,
  workingHourPerDay,
  initDays,
  initHours,
  initMinutes,
  freeze,
}) => {
  const [days, setDays] = useState(initDays || 0);
  const [hours, setHours] = useState(initHours || 0);
  const [minutes, setMinutes] = useState(initMinutes || 0);
  const getHours = (time) => {
    return parseInt(time?.split(":")[0]);
  };
  const count = () => {
    const now = new Date();
    if (
      !freeze &&
      ![5, 6].includes(now.getDay()) &&
      now.getHours() >= getHours(startHour) &&
      now.getHours() < getHours(endHour)
    ) {
      if (minutes >= 59) {
        countHours();
        setMinutes(0);
      } else {
        setMinutes(minutes + 1);
      }
    }
  };
  const countHours = () => {
    if (hours >= workingHourPerDay) {
      countDays();
      setHours(0);
    } else {
      setHours(hours + 1);
    }
  };
  const countDays = () => {
    setDays(days + 1);
  };
  useInterval(count, 60000);

  return (
    <div>
      {`${days} Day${days > 1 ? "s" : ""} `}{" "}
      {`${hours} Hour${hours > 1 ? "s" : ""} `}
      {`${minutes} Minute${minutes > 1 ? "s" : ""}`}
    </div>
  );
};

export default ElapsedTime;
