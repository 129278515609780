import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, FormGroup, Button, Label, Alert } from "reactstrap";
import {
  createUser,
  restError,
  apiError,
} from "../../../store/auth/Users/actions";
import { putOnboarder } from "../../../api/common";
const User = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState(location?.state?.user);
  const { createUser, errors, restError, error, apiError } = props;
  const [success, setSuccess] = useState("");

  const editUserAPI = (vals) => {
    if (vals?.password === "") {
      delete vals?.password;
    }
    putOnboarder(`/UserManagement/user/${vals?.id}`, vals)
      .then((data) => {
        setSuccess("Update Successfuly");
        setUser(data)
      })
      .catch((error) => {
        apiError(error);
        throw error;
      });
  };

  const handleValidSubmit = (event, values) => {
    delete values?.password1;
    if (user?.id) {
      editUserAPI(values);
    } else {
      createUser(values, history);
    }
  };

  useEffect(() => {
    return () => {
      restError();
    };
  }, []);

  return (
    <div className="page-content">
      <div className="container-fluid">
        {error && <Alert color="danger">{error}</Alert>}
        {success && <Alert color="success">{success}</Alert>}
        <AvForm
          className="needs-validation"
          onValidSubmit={handleValidSubmit}
          autoComplete
        >
          <Row>
            <Col md="6">
              <FormGroup hidden>
                <AvField
                  name="id"
                  label="id"
                  value={user?.id || ""}
                  type="text"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="validationCustom01">First name*</Label>
                <AvField
                  name="first_name"
                  placeholder="First name"
                  type="text"
                  value={user?.first_name || ""}
                  errorMessage="Enter First Name"
                  className="form-control"
                  validate={{
                    pattern: {
                      value: "^[A-Za-z]+$",
                      errorMessage: "Invalid First Name character only",
                    },
                    required: { value: true },
                    minLength: { value: 3, errorMessage: "Min 3 chars." },
                    maxLength: { value: 100, errorMessage: "Max 100 chars." },
                  }}
                  id="validationCustom01"
                />
                {errors?.first_name && (
                  <Alert color="danger">{errors.first_name[0]}</Alert>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="validationCustom02">Last name*</Label>
                <AvField
                  name="last_name"
                  placeholder="Last name*"
                  type="text"
                  value={user?.last_name || ""}
                  errorMessage="Enter Last name"
                  className="form-control"
                  validate={{
                    pattern: {
                      value: "^[A-Za-z]+$",
                      errorMessage: "Invalid Last name character only",
                    },
                    required: { value: true },
                    minLength: { value: 3, errorMessage: "Min 3 chars." },
                    maxLength: { value: 100, errorMessage: "Max 100 chars." },
                  }}
                  id="validationCustom02"
                />
                {errors?.last_name && (
                  <Alert color="danger">{errors.last_name[0]}</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <AvField
                  name="username"
                  label="Username*"
                  placeholder="username"
                  type="text"
                  value={user?.username || ""}
                  errorMessage="Enter Name"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3, errorMessage: "Min 3 chars." },
                    maxLength: { value: 32, errorMessage: "Max 32 chars." },
                  }}
                />
                {!!errors?.username && (
                  <Alert color="danger">{errors?.username[0]}</Alert>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <AvField
                  name="email"
                  label="E-Mail*"
                  placeholder="Enter Valid Email"
                  type="email"
                  value={user?.email || ""}
                  errorMessage="Invalid Email"
                  validate={{
                    required: { value: true },
                    email: { value: true },
                  }}
                />
                {!!errors?.email && (
                  <Alert color="danger">{errors?.email[0]}</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Password*</Label>
                <AvField
                  name="password"
                  type="password"
                  placeholder="Password"
                  errorMessage="Enter password"
                  validate={{
                    required: { value: !user?.id },
                    pattern: {
                      value: "^[0-9a-zA-Z]+$",
                      errorMessage: "Only Alphanumeric",
                    },
                    minLength: { value: 8, errorMessage: "Min 8 chars." },
                  }}
                />
                {errors?.password && (
                  <Alert color="danger">{errors.password[0]}</Alert>
                )}
                <AvField
                  name="password1"
                  type="password"
                  placeholder="Re-type Password"
                  errorMessage="Enter Re-password"
                  validate={{
                    required: { value: !user?.id },
                    match: {
                      value: "password",
                      errorMessage: "password not match",
                    },
                    pattern: {
                      value: "^[0-9a-zA-Z]+$",
                      errorMessage: "Only Alphanumeric",
                    },
                    minLength: { value: 8, errorMessage: "Min 8 chars." },
                  }}
                />
                <Row>
                  <Col md="6">
                    <AvField
                      type="checkbox"
                      name="is_active"
                      label="Active user"
                      value={user?.is_active || ""}
                    />
                    <AvField
                      type="checkbox"
                      name="is_staff"
                      label="admin"
                      value={user?.is_staff || ""}
                    />
                  </Col>
                  <Col md="6">
                    <AvField
                      type="checkbox"
                      name="is_onboarder"
                      label="Onboarder"
                      value={user?.is_onboarder || ""}
                    />
                    <AvField
                      type="checkbox"
                      name="is_reviewer"
                      label="Reviewer"
                      value={user?.is_reviewer || ""}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <AvField
                  name="mobile"
                  label="Mobile Number*"
                  placeholder="+971 5X XXX XXXX"
                  type="text"
                  value={user?.mobile || ""}
                  errorMessage="Invalid Mobile Number"
                  validate={{
                    required: { value: true },
                  }}
                />
                {errors?.mobile && (
                  <Alert color="danger">{errors.mobile[0]}</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" type="submit">
            {user?.id ? "Update" : "Submit"}
          </Button>
        </AvForm>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    errors: state.Users.errors,
    error: state.Users.error,
  };
};
export default connect(mapStateToProps, { createUser, restError, apiError })(
  User
);

User.propTypes = {
  errors: PropTypes.object,
  createUser: PropTypes.func,
  restError: PropTypes.func,
  error: PropTypes.string,
  user: PropTypes.object,
};
