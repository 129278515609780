import {
  SET_SPECIFIC_PDF_FILE,
  SET_PDF_FILE_NAME,
  SAVE_PDF_FILE_AS_ARRAY,
  RESET_FILE_STATE
} from "./actionType";

const initialState = {
  specificPDF: "",
  fileName: "",
  fileAsArray: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SPECIFIC_PDF_FILE:
      return {
        ...state,
        specificPDF: action.payload
      };

    case SET_PDF_FILE_NAME:
      return {
        ...state,
        fileName: action.payload
      };

    case SAVE_PDF_FILE_AS_ARRAY:
      return {
        ...state,
        fileAsArray: action.payload.split(/\r?\n/).filter(item => item)
      };

    case RESET_FILE_STATE:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
};
