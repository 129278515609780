import axios from "axios";
import history from "../routes/history";
import { toast } from "react-toastify";
import {eraseCookie, getCookie} from "../utils"
export const ONBOARDERURL = `${process.env.REACT_APP_ONBOARDER_URL}`;
export const BASEURL = `${process.env.REACT_APP_ROOT_URL}`;
export const ONBOARDINGBASEURL = `${process.env.REACT_APP_ONBOARDING_URL}`;
axios.interceptors.request.use((req) => {
  // `req` is the Axios request config, so you can modify
  // the `headers`.
  if (req.url.includes("login")) {
    return req;
  }
  const savedJwt = getCookie("jwt");
  if (savedJwt) req.headers.authorization = `Bearer ${savedJwt}`;
  return req;
});

const UNAUTHORIZED = 401;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (
      status === UNAUTHORIZED &&
      !error.request.responseURL.includes("login")
    ) {
      eraseCookie("jwt");
      localStorage.removeItem("user");
      history.push("/login", {});
    }
    if (status === 403) {
      history.push("/dashboard", {});
      toast.info("You Can not access this page");
    }
    return Promise.reject(error);
  }
);

export default axios;
