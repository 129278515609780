import React, { useEffect, useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import { formatNumber } from "../../../utils";
const Max2DigitsPositiveNumberInput = ({
  name,
  value,
  onChangeValue,
  disabled,
  required,
  ...props
}) => {
  const [editMode, setEditMode] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [displayValue, setDisplayValue] = useState(formatNumber(value));
  const handleOnChangeInput = (e) => {
    setCurrentValue(e.target.value);
    onChangeValue(e.target.value, e.target.name);
  };
  const handleOnBlur = (e) => {
    setDisplayValue(formatNumber(currentValue));
    setEditMode(false);
  };

  const handleOnFocus = (e) => {
    setDisplayValue(currentValue);
    setEditMode(true);
  };

  const isPositive = (value, ctx, input, cb) => {
    if (currentValue < 0.000001) {
      cb("Amount can't be negative or Zero");
    }
    return true;
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (!editMode) {
      setDisplayValue(formatNumber(currentValue));
    }
  }, [editMode]);
  return (
    <AvField
      name={name}
      placeholder="Enter a Number"
      type="text"
      value={displayValue}
      errorMessage="Enter Number"
      className="form-control"
      onChange={handleOnChangeInput}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      validate={{
        required: { value: required },
        pattern: {
          value: "^[0-9,]+([.][0-9]{0,2})?$",
          errorMessage:
            "input accepts only postive numbers, with maximum 2 decimal digits",
        },
        custom: isPositive,
      }}
      disabled={disabled}
      {...props}
    />
  );
};

export default Max2DigitsPositiveNumberInput;
