import React, {useEffect, useState} from "react";

const Search = ({ onSearch }) => {
         const [value, setValue] = useState("")
    const onChangeSearchValue = (e) => {
        if (e.target.value == "") {
            onSearch("")
        }
        setValue(e.target.value)
    }
    const onClickSearch = (e) => {
        onSearch(value)
    }
    return (
        <div className="w-100">
        <div className="input-group">
            <div className="form-outline" style={{display: "contents"}}>
                <input type="search" id="search" placeholder="search" className="form-control inline" style={{width: "80%"}} onChange={onChangeSearchValue} />
            </div>
            <button type="button" className="btn btn-primary mx-2 inline" onClick={onClickSearch}>
                <i className="fas fa-search"></i>
            </button>
        </div>
            </div>
    )
}

export default Search;

