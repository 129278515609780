import {
  SET_IMAGE_URL,
  RESET_STATE_IMAGE,
  SET_IMAGE_ICON_URL
} from "./actionType";

const initialState = {
  url: "",
  iconUrl: "",
  name: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IMAGE_URL:
      return {
        ...state,
        url: { ...state.url, [action.payload.name]: action.payload.result }
      };

    case SET_IMAGE_ICON_URL:
      return {
        ...state,
        iconUrl: action.payload
      };

    case RESET_STATE_IMAGE:
      return {
        ...state,
        url: "",
        iconUrlMission: ""
      };

    default:
      return state;
  }
};
