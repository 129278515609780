import React from "react";
import Select from "react-select";

const FilterChoice = ({ name, options, onChangeFilter, statuses }) => {
  const onChangeSelect = (newValue, actionMeta) => {
    onChangeFilter(newValue);
  };
  return (
    <div>
      <Select
        isMulti
        name={name}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChangeSelect}
        value={statuses}
      />
    </div>
  );
};

export default FilterChoice;
