import React, { memo } from "react";
import { connect } from "react-redux";
import "./style.scss";
// import ContentForm from "./ContentForm";

const Header = memo(({ company, currentState }) => {
  return (
    <div className="content-header">
      <div className="main-content">
        <div className="container">
          <div className="container-fluid">
            <div className="container-fluid pt-2">
              <h1 style={{ display: "inline" }} >
                {company?.name}
              </h1> <span>({company?.status?.replaceAll("_", " ")}</span>)
    </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const mapStateToProps = (state) => {
  return {
    currentState: state.CompanyState.currentState,
  };
};
export default connect(mapStateToProps, {})(Header);
