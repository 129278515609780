import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { useParams, Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo.png";
import { openPdfViewer } from "../../store/layout/PdfViewer/actions.js";
import { getFile, getOnboarder } from "../../api/common";
import PdfViewer from "../../components/PdfViewer";

const ViewAgreement = ({ openPdfViewer }) => {
  const params = useParams();
  const [agreement, setAgreement] = useState();
  const [loaded, setLoaded] = useState();
  const [token, setToken] = useState(params?.token);

  const generateAgreement = async () => {
    try {
      const data = await getOnboarder(
        `/UserManagement/agreement/view/${token}`
      );
      setAgreement(data);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      throw error;
    }
  };
  useEffect(() => {
    generateAgreement();
  }, [token]);

  useEffect(() => {
    const getAgreement = async () => {
      const res = await fetch(agreement?.agreement);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      if (
        agreement.status === "sent" &&
        new Date(agreement?.expired_at) >= new Date()
      ) {
        openPdfViewer(url, "agreement", false, false, true, token);
      } else {
        openPdfViewer(url, "agreement");
      }
    };
    if (agreement) {
      getAgreement();
    }
  }, [agreement]);
  return (
    <>
      <div className="account-pages my-4" style={{ height: "100%" }}>
        <Container style={{ height: "100%" }}>
          <PdfViewer />
          {agreement && loaded && <div style={{ minHeight: "500px" }}></div>}
          {!agreement && loaded && (
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div>
                  <Row className="justify-content-center">
                    <div className="mb-4">
                      <img src={logo} alt="" height="100" />
                    </div>
                  </Row>
                  <Row className="justify-content-center">
                    <div className="text-primary">
                      <h5 className="text-primary">
                        Welcome to the On-boarding Wizard!
                      </h5>
                    </div>
                  </Row>
                </div>
                <Card className="overflow-hidden">
                  <CardBody className="pt-12">
                    <div></div>
                    <div className="p-2">
                      <Row className="justify-content-center">
                        <div className="text-primary">
                          <h3 className="text-danger">
                            <i class="fas fa-times"></i>
                          </h3>
                        </div>
                      </Row>
                      <Row className="justify-content-center">
                        <div className="text-center">
                          <h3>This agreement can not be found</h3>
                        </div>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} The Accounter Technologies Ltd.
                  </p>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {};
export default connect(mapStateToProps, {
  openPdfViewer,
})(ViewAgreement);
