import {
  ASSIGN_TO_ME_REQUEST,
  ASSIGN_TO_ME_REVIEW_REQUEST,
  API_ERROR,
  ASSIGN_TO_ME_SUCCESS,
  ASSIGN_TO_ME_REVIEW_SUCCESS,
  REST_ERROR,
  START_ON_BOARDING_REQUEST,
  START_REVIEW_REQUEST,
  START_ON_BOARDING_SUCCESS,
  START_REVIEW_SUCCESS,
  CHANGE_ON_BOARDING_REQUEST,
  CHANGE_REVIEWER_REQUEST,
  CHANGE_ON_BOARDING_SUCCESS,
  REST_STATE,
} from "./actionTypes";

export const assignRequest = (companyId) => {
  return {
    type: ASSIGN_TO_ME_REQUEST,
    payload: { companyId },
  };
};

export const assignReviewRequest = (companyId) => {
  return {
    type: ASSIGN_TO_ME_REVIEW_REQUEST,
    payload: { companyId },
  };
};
export const createAssignSuccess = (data) => {
  return {
    type: ASSIGN_TO_ME_SUCCESS,
    payload: data,
  };
};
export const createAssignReviewSuccess = (data) => {
  return {
    type: ASSIGN_TO_ME_REVIEW_SUCCESS,
    payload: data,
  };
};
export const startOnBoardingRequest = (companyId) => {
  return {
    type: START_ON_BOARDING_REQUEST,
    payload: { companyId },
  };
};
export const startReviewRequest = (companyId) => {
  return {
    type: START_REVIEW_REQUEST,
    payload: { companyId },
  };
};

export const startOnBoardingSuccess = (data) => {
  return {
    type: START_ON_BOARDING_SUCCESS,
    payload: data,
  };
};

export const startReviewSuccess = (data) => {
  return {
    type: START_REVIEW_SUCCESS,
    payload: data,
  };
};

export const changeOnBoardingRequest = (companyId, userId) => {
  return {
    type: CHANGE_ON_BOARDING_REQUEST,
    payload: { userId, companyId },
  };
};

export const changeReviewerRequest = (companyId, userId) => {
  return {
    type: CHANGE_REVIEWER_REQUEST,
    payload: { userId, companyId },
  };
};

export const changeOnBoardingSuccess = (data) => {
  return {
    type: CHANGE_ON_BOARDING_SUCCESS,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const restError = () => {
  return {
    type: REST_ERROR,
  };
};
export const restStateAssign = () => {
  return {
    type: REST_STATE,
  };
};
