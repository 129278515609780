import React, { useState, useEffect } from "react";
import { AvField } from "availity-reactstrap-validation";
import { Col, FormGroup, Button } from "reactstrap";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { formatNumber } from "../../../utils";
import Max2DigitsNumberInput from "../../Inputs/Max2DigitsNumberInput";
import { Tr, Td } from "react-super-responsive-table";

const ItemLine = ({
  type,
  index,
  line,
  categories,
  uoms,
  taxes,
  setLines,
  lines,
  activeCompanyIsEditable,
}) => {
  const onChangeLine = (line) => {
    if (line?.type != "product") {
      line.qty = 0.0;
    }
    if (line?.qty == "") {
      line.qty = 0.0;
    }
    if (type === "sale") {
      line.value = (
        parseFloat(line.price || 0) * parseFloat(line.qty || 0)
      ).toFixed(2);
    } else {
      line.value = (
        parseFloat(line.cost || 0) * parseFloat(line.qty || 0)
      ).toFixed(2);
    }
  };
  const handleOnChangeInput = (e) => {
    if (
      e.target.name.split("-")[0] == "type" &&
      line?.type == "product" &&
      e.target.value != "product" &&
      line?.is_purchase
    ) {
      toast.warn("Related purchase item will be deleted");
    }
    line[e.target.name.split("-")[0]] = e.target.value;
    onChangeLine(line);
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleOnChangePrice = (value, name) => {
    line[name.split("-")[0]] = value;
    onChangeLine(line);
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleOnDelete = (e) => {
    line.selected = e.target.checked;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  return (
    <Tr>
      <Td style={{ minWidth: "10px" }}>
        <FormGroup hidden>
          <AvField name="id" type="text" value={line?.id || ""} />
        </FormGroup>

        <FormGroup>
          <AvField
            type="checkbox"
            name="selected"
            checked={line?.selected || ""}
            onChange={handleOnDelete}
            disabled={
              !activeCompanyIsEditable ||
              (type === "purchase" && line?.is_auto_copied)
            }
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`name-${index}`}
            placeholder="Item Name"
            type="text"
            value={line?.name || ""}
            errorMessage="Enter Name"
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
            }}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`code-${index}`}
            placeholder="code"
            type="text"
            value={line?.code || ""}
            errorMessage="Enter code"
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
            }}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            type="select"
            name={`${type}_category-${index}`}
            value={line[`${type}_category`] || ""}
            errorMessage="Select Category"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            {categories.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.name}
              </option>
            ))}
          </AvField>
        </FormGroup>
      </Td>

      <Td hidden={type == "purchase"}>
        <FormGroup>
          <AvField
            type="select"
            name={`type-${index}`}
            value={line?.type || ""}
            errorMessage="Select Type"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            <option key="inventory" value="product">
              Inventory
            </option>
            <option key="service" value="service">
              Service
            </option>
            <option key="consumable" value="consu">
              Consumable
            </option>
            ))}
          </AvField>
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            type="select"
            name={`${type}_uom-${index}`}
            value={line[`${type}_uom`] || ""}
            errorMessage="Select UOM"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            {uoms.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.name}
              </option>
            ))}
          </AvField>
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <Max2DigitsNumberInput
            name={type === "sale" ? `price-${index}` : `cost-${index}`}
            value={line[type === "sale" ? `price` : `cost`] || ""}
            disabled={!activeCompanyIsEditable}
            onChangeValue={handleOnChangePrice}
          />
        </FormGroup>
      </Td>
      <Td hidden={type == "purchase"}>
        <FormGroup style={{ width: "250px" }} className="row">
          <Col md="2">
            <AvField
              type="checkbox"
              name={`price_include_tax-${index}`}
              value={line?.price_include_tax || ""}
              inline
              disabled={!activeCompanyIsEditable}
            />
          </Col>
          <Col md="10">
            <AvField
              type="select"
              name={`tax-${index}`}
              value={line?.tax || ""}
              errorMessage="Select Tax"
              onChange={handleOnChangeInput}
              validate={{
                required: { value: false },
              }}
              inline
              disabled={!activeCompanyIsEditable}
            >
              <option></option>
              {taxes.map((option) => (
                <option key={option?.id} value={option?.id}>
                  {option?.name}
                </option>
              ))}
            </AvField>
          </Col>
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`qty-${index}`}
            placeholder="QTY"
            type="text"
            value={line?.qty || ""}
            errorMessage="Enter QTY"
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: false },
            }}
            disabled={line?.type != "product" || !activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      {type == "purchase" && <Td>{formatNumber(line.value)}</Td>}
    </Tr>
  );
};
const mapStateToProps = (state) => {
  return {
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(ItemLine);
