import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { getOnboarder } from "../../api/common";
import HistoryCard from "./HistoryCard";
import "./style.css";
const History = ({ company }) => {
  const [histories, setHistories] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const getHistory = () => {
    getOnboarder(`/UserManagement/company/${company?.id}/history`).then(
      (data) => {
        setHistories(data["results"]);
      }
    );
  };
  const toggle = () => {
    if (!dropdownOpen) getHistory();
    setDropdownOpen((prevState) => !prevState);
  };
  useEffect(() => {
    getHistory();
  }, []);
  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} inNavbar size="lg">
        <DropdownToggle nav>
          <span style={{ fontSize: "x-large" }} title="History">
            <i class="fas fa-history"></i>
          </span>
        </DropdownToggle>
        <DropdownMenu left className="history-dropdown" nav>
          <div className="history-title"> History</div>
          <div className="history-list">
            <div className="list-group">
              {histories.slice(0, 4).map((history, index) => {
                return <HistoryCard history={history} />;
              })}
            </div>
          </div>
          <div class="list-footer">
            <Link to={`/history/${company.id}`}>View all History</Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
  };
};
export default connect(mapStateToProps, {})(History);
