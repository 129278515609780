import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { getOnboarder, postOnboarder } from "../../../api/common";
import {
  FormGroup,
  Label,
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { handleErrorsMessage } from "../../../utils";
import { postUsersAndEmployees } from "../../../api/usersAndEmployees";
import { putOnboarder } from "../../../api/common";
const AddNew = ({ modal, toggle, handleAddNewLine, line, parent, company }) => {
  const [errors, setErrors] = useState({});
  const [currentLine, setCurrentLine] = useState(line);
  const [showAgreementMessage, setShowAgreementMessage] = useState(false);
  useEffect(() => {
    setCurrentLine(line);
  }, [line]);
  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        toggle();
        setErrors({});
      }}
    >
      &times;
    </button>
  );

  const onValidSubmit = (e, vals) => {
    let body;
    if (line) {
      body = {
        employees: [{ ...vals, ...{ id: line.id } }],
      };
    } else {
      body = {
        employees: [{ ...vals }],
      };
    }
    postOnboarder(`/UserManagement/${company?.id}/employee/submit`, body)
      .then((data) => {
        if (line?.employee_type === "owner") {
          if (showAgreementMessage) toast.info("agreement document is updated");
          setShowAgreementMessage(false);
        }
        toggle();
        setErrors({});
      })
      .catch((error) => {
        if (Array.isArray(error)) {
          setErrors(error[0]);
        } else {
          setErrors(error);
        }
        throw error;
      });
  };
  const handleOnChange = (e) => {
    if (e.target.type === "file") {
      return;
    }
    if (["first_name", "last_name"].includes(e.target.name)) {
      setShowAgreementMessage(true);
    }
  };
  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        setErrors({});
      }}
      size="lg"
    >
      <AvForm onChange={handleOnChange} onValidSubmit={onValidSubmit}>
        <FormGroup hidden>
          <AvField
            name="parent"
            label="parent"
            placeholder="Parent"
            value={parent?.id || currentLine?.parent || ""}
            type="text"
          />
          {!!errors?.parent && <Alert color="danger">{errors?.parent}</Alert>}
        </FormGroup>
        <ModalHeader
          toggle={() => {
            toggle();
            setErrors({});
          }}
          close={closeBtn}
        >
          {line
            ? "Update User Details"
            : `Add Child User (for ${parent?.employee_type} User)`}
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <Row>
              <Col>
                {handleErrorsMessage(errors) && (
                  <Alert color="danger">{handleErrorsMessage(errors)}</Alert>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card>
                  <CardBody>
                    <CardTitle>User Details</CardTitle>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="validationCustom01">
                            First name*
                          </Label>
                          <AvField
                            name="first_name"
                            placeholder="First name"
                            value={currentLine?.first_name || ""}
                            type="text"
                            errorMessage="Enter First Name"
                            className="form-control"
                            validate={{
                              pattern: {
                                value: "^[A-Za-z]+$",
                                errorMessage:
                                  "Invalid First Name character only",
                              },
                              required: {
                                value: true,
                                errorMessage: "Mandatory field",
                              },
                              minLength: {
                                value: 3,
                                errorMessage: "Min 3 chars.",
                              },
                              maxLength: {
                                value: 30,
                                errorMessage: "Max 30 chars.",
                              },
                            }}
                            id="validationCustom01"
                          />
                          {errors?.first_name && (
                            <Alert color="danger">{errors.first_name}</Alert>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="validationCustom02">Last name*</Label>
                          <AvField
                            name="last_name"
                            placeholder="Last name*"
                            value={currentLine?.last_name || ""}
                            type="text"
                            errorMessage="Enter Last name"
                            className="form-control"
                            validate={{
                              pattern: {
                                value: "^[A-Za-z]+$",
                                errorMessage:
                                  "Invalid Last Name Character Only",
                              },
                              required: {
                                value: true,
                                errorMessage: "Mandatory field",
                              },
                              minLength: {
                                value: 3,
                                errorMessage: "Min 3 chars.",
                              },
                              maxLength: {
                                value: 30,
                                errorMessage: "Max 30 chars.",
                              },
                            }}
                            id="validationCustom02"
                          />
                          {errors?.last_name && (
                            <Alert color="danger">{errors.last_name}</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <AvField
                        name="email"
                        label="E-Mail*"
                        placeholder="Enter Valid Email"
                        value={currentLine?.email || ""}
                        type="email"
                        errorMessage="Invalid Email"
                        validate={{
                          email: { value: true },
                          required: {
                            value: true,
                            errorMessage: "Mandatory field",
                          },
                        }}
                      />
                      {!!errors?.email && (
                        <Alert color="danger">{errors?.email}</Alert>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <AvField
                        name="mobile"
                        label="Mobile Number*"
                        placeholder="Enter Mobile Number"
                        value={currentLine?.mobile || ""}
                        type="text"
                        errorMessage="Invalid Mobile Number"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Mandatory field",
                          },
                        }}
                      />
                      {errors?.mobile && (
                        <Alert color="danger">{errors.mobile}</Alert>
                      )}
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>

              <Col md="6">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <AvRadioGroup
                            name="employee_type"
                            label="User Type"
                            required
                            value={
                              currentLine?.employee_type ||
                              (parent?.employee_type == "main" ? "sub" : "")
                            }
                            errorMessage="Pick one!"
                            disabled={line || parent?.employee_type == "main"}
                          >
                            <AvRadio label="Main User" value="main" />
                            <AvRadio label="Sub User" value="sub" />
                          </AvRadioGroup>

                          {errors?.employee_type && (
                            <Alert color="danger">{errors.employee_type}</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <h2>User Employment</h2>
                        <FormGroup>
                          <AvField
                            type="checkbox"
                            name="is_employee"
                            label="Employee"
                            value={currentLine?.is_employee}
                          />
                          {parent?.employee_type == "owner" && (
                            <AvField
                              type="checkbox"
                              name="is_co_owner"
                              label="Co-Owner"
                              value={currentLine?.is_co_owner}
                            />
                          )}

                          {errors?.is_employee && (
                            <Alert color="danger">{errors.is_employee}</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col md="12">
                <Card>
                  <CardBody>
                    <CardTitle>User Permissions</CardTitle>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <AvField
                            type="checkbox"
                            name="needs_approval"
                            label="Needs Approval(U)"
                            value={currentLine?.needs_approval}
                          />

                          {errors?.needs_approval && (
                            <Alert color="danger">
                              {errors.needs_approval}
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <AvField
                            type="checkbox"
                            name="needs_payment_approval"
                            label="Needs Approval(P.v)"
                            value={currentLine?.needs_payment_approval}
                          />

                          {errors?.needs_payment_approval && (
                            <Alert color="danger">
                              {errors.needs_payment_approval}
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <AvField
                            type="checkbox"
                            name="view_reports"
                            label="View Reports"
                            value={currentLine?.view_reports}
                          />

                          {errors?.view_reports && (
                            <Alert color="danger">{errors.view_reports}</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            {line ? "Update" : "Add New"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              toggle();
              setErrors({});
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
  };
};
export default connect(mapStateToProps, {})(AddNew);
