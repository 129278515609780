import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import {
  openPdfViewer,
  closePdfViewer,
} from "../../store/layout/PdfViewer/actions";
import { postOnboarder } from "../../api/common";
import { handleErrorsMessage } from "../../utils";
import { Viewer } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// redux actions
import "./style.css";
const PdfViewer = ({
  company,
  pdfFile,
  fileName,
  isOpen,
  closePdfViewer,
  openPdfViewer,
  sendAgreement,
  agreementActions,
  disabledSendAgreement,
  token,
}) => {
  const sendAgreementMethod = async () => {
    try {
      const res = await postOnboarder(
        `/UserManagement/${company.id}/agreement/send`
      );
      toast.success("Agreement has been sent");
    } catch (error) {
      toast.error(handleErrorsMessage(error));
      throw error;
    }
  };
  const approveAgreementMethod = async () => {
    try {
      if (token) {
        const res = await postOnboarder(
          `/UserManagement/agreement/approve/${token}`
        );
        toast.success("agreement approved");
        openPdfViewer(res?.agreement, "agreement");
      }
    } catch (error) {
      toast.error(handleErrorsMessage(error));
      throw error;
    }
  };
  const rejectAgreementMethod = async () => {
    try {
      if (token) {
        const res = await postOnboarder(
          `/UserManagement/agreement/reject/${token}`
        );
        toast.error("agreement rejected");
        openPdfViewer(res?.agreement, "agreement");
      }
    } catch (error) {
      toast.error(handleErrorsMessage(error));
      throw error;
    }
  };
  const renderToolbar = (Toolbar) => (
    <>
      <Toolbar />
      {sendAgreement && (
        <div className="d-flex flex-row-reverse">
          <div className="mr-3 mt-1">
            <Button
              color="success"
              onClick={() => sendAgreementMethod()}
              disabled={disabledSendAgreement}
            >
              Send Agreement
            </Button>
          </div>
        </div>
      )}
      {agreementActions && (
        <div className="d-flex flex-row-reverse">
          <div className="mr-3 mt-1">
            <Button color="success" onClick={() => approveAgreementMethod()}>
              Approve
            </Button>
          </div>
          <div className="mr-3 mt-1">
            <Button color="danger" onClick={() => rejectAgreementMethod()}>
              Reject
            </Button>
          </div>
        </div>
      )}
    </>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator: (file) => {
          return `${fileName}`;
        },
      },
    },
    renderToolbar,
    sidebarTabs: (defaultTabs) => [],
  });
  return (
    <div>
      {isOpen && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <div className="warper">
            <div className="close" onClick={() => closePdfViewer()}>
              &times;
            </div>
            <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
          </div>
        </Worker>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isOpen: state.PdfViewerReducer.isOpen,
    pdfFile: state.PdfViewerReducer.pdfFile,
    fileName: state.PdfViewerReducer.fileName,
    company: state.CompanyState.activeCompany,
    sendAgreement: state.PdfViewerReducer.sendAgreement,
    disabledSendAgreement: state.PdfViewerReducer.disabledSendAgreement,
    agreementActions: state.PdfViewerReducer.agreementActions,
    token: state.PdfViewerReducer.token,
  };
};
export default connect(mapStateToProps, { closePdfViewer, openPdfViewer })(
  PdfViewer
);
