export const BASIC_INFO_GET_REQUEST = "BASIC_INFO_GET_REQUEST";
export const BASIC_INFO_GET_SUCCESS = "BASIC_INFO_GET_SUCCESS";

export const BASIC_INFO_GET_STATE_REQUEST = "BASIC_INFO_GET_STATE_REQUEST";
export const BASIC_INFO_GET_STATE_SUCCESS = "BASIC_INFO_GET_STATE_SUCCESS";

export const BASIC_INFO_GET_COUNTRY_REQUEST = "BASIC_INFO_GET_COUNTRY_REQUEST";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";

export const SET_BASIC_INFO_ITEM = "SET_BASIC_INFO_ITEM";

export const UPDATE_COMPANY_BASIC_INFO = "UPDATE_COMPANY_BASIC_INFO";
export const UPDATE_COMPANY_BASIC_INFO_SUCCESS =
  "UPDATE_COMPANY_BASIC_INFO_SUCCESS";

export const API_ERROR = "BASIC_INFO_GET_API_ERROR";
export const REST_ERROR = "BASIC_INFO_GET_ERROR";
export const REST_STATE = "BASIC_INFO_GET_STATE";
