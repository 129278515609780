import { OPEN_PDF_VIEWER, CLOSE_PDF_VIEWER } from "./actionTypes";

export const openPdfViewer = (
  file,
  fileName,
  sendAgreement,
  disabledSendAgreement,
  agreementActions,
  token
) => ({
  type: OPEN_PDF_VIEWER,
  payload: file,
  fileName: fileName,
  sendAgreement: sendAgreement,
  disabledSendAgreement: disabledSendAgreement,
  agreementActions: agreementActions,
  token: token,
});

export const closePdfViewer = () => ({
  type: CLOSE_PDF_VIEWER,
});
