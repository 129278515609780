import { OPEN_IMAGE_VIEWER, CLOSE_IMAGE_VIEWER } from "./actionType";

const initialState = {
  isOpen: false,
  imageViewed: null,
};

const ImageViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_IMAGE_VIEWER:
      return {
        ...state,
        isOpen: true,
        imageViewed: action.payload,
      };

    case CLOSE_IMAGE_VIEWER:
      return {
        ...state,
        isOpen: false,
        imageViewed: null,
      };
    default:
      return state;
  }
};
export default ImageViewerReducer;
