import React, { useEffect, useState } from "react";

import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { useParams, Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo.png";

import { postOnboarder } from "../../api/common";

const VerifyMobile = (props) => {
  const params = useParams();
  const [valid, setValid] = useState(false);
  const [token, setToken] = useState(params?.token);
  const [loaded, setLoaded] = useState(false);
  const checkToken = async (token) => {
    const values = { token: token };
    try {
      const data = await postOnboarder("/mobile/validateToken/", values);
      setValid(true);
      setLoaded(true);
    } catch (errors) {
      setToken("");
      setLoaded(true);
    }
  };
  useEffect(() => {
    checkToken(token);
  }, [token]);

  return (
    <>
      <div className="account-pages my-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div>
                <Row className="justify-content-center">
                  <div className="mb-4">
                    <img src={logo} alt="" height="100" />
                  </div>
                </Row>
                <Row className="justify-content-center">
                  <div className="text-primary">
                    <h5 className="text-primary">
                      Welcome to the On-boarding Wizard!
                    </h5>
                  </div>
                </Row>
              </div>
              <Card className="overflow-hidden">
                <CardBody className="pt-12">
                  <div></div>
                  {loaded && (
                    <div className="p-2">
                      {valid && (
                        <Row className="justify-content-center">
                          <div className="text-primary">
                            <h3 className="text-success">
                              <i class="fas fa-check"></i>
                            </h3>
                          </div>
                          <div className="text-center">
                            <h3>Thank you, your number is now verified</h3>
                          </div>
                        </Row>
                      )}
                      {!valid && (
                        <Row className="justify-content-center">
                          <div className="text-primary">
                            <h3 className="text-danger">
                              <i class="fas fa-times"></i>
                            </h3>
                          </div>
                          <div className="text-center">
                            <h3>This link is expired</h3>
                          </div>
                        </Row>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} The Accounter Technologies Ltd.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default VerifyMobile;
