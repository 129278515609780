import React, { memo } from "react";
import { connect } from "react-redux";
import "./style.css";
import { Button } from "reactstrap";
import CompleteButton from "../../CompleteButton";
import AddReminder from "../../AddReminder";

const ActionFooter = ({
  canSave,
  next,
  back,
  complete,
  importToggle,
  copyFromSales,
  downloadTemplate,
  activeCompanyIsEditable,
  roleUser,
  company,
}) => {
  return (
    <div className="action-footer">
      <div className="main-content">
        <div className="content" style={{ marginTop: "0px" }}>
          <div className="container-fluid">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5px",
              }}
            >
              <div>
                {back && (
                  <Button
                    type="button"
                    color="light"
                    onClick={back}
                    disabled={!canSave}
                    title="Back"
                  >
                    <i class="fas fa-arrow-left"></i>
                  </Button>
                )}
              </div>
              <div>
                {copyFromSales && activeCompanyIsEditable && (
                  <Button
                    color="primary"
                    onClick={copyFromSales}
                    title="Copy From Sales"
                  >
                    Copy From Sales
                  </Button>
                )}
                {importToggle && activeCompanyIsEditable && (
                  <Button
                    color="light"
                    onClick={importToggle}
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    title="Upload"
                  >
                    <i class="fas fa-upload"></i>
                  </Button>
                )}
                {downloadTemplate && activeCompanyIsEditable && (
                  <Button
                    color="light"
                    onClick={downloadTemplate}
                    style={{
                      marginRight: 10,
                    }}
                    title="Download"
                  >
                    <i class="fas fa-download"></i>
                  </Button>
                )}
                {(company.user === roleUser.id || roleUser.is_staff) &&
                  activeCompanyIsEditable && <AddReminder />}
                {complete && activeCompanyIsEditable && (
                  <CompleteButton canSave={canSave} save={complete} />
                )}
                {next && (
                  <Button
                    type="button"
                    color="primary"
                    onClick={next}
                    disabled={!canSave}
                    title="Next"
                  >
                    <i class="fas fa-arrow-right"></i>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
    roleUser: state.Companies.role,
    company: state.CompanyState.activeCompany,
  };
};
export default connect(mapStateToProps, {})(ActionFooter);
