import React, { useState, useEffect, useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";
import NumberInput from "../../Inputs/NumberInput";

import { Tr, Td } from "react-super-responsive-table";
import { postOnboarder } from "../../../api/common";
const PaymentLine = ({
  index,
  line,
  users,
  banks,
  setLines,
  lines,
  activeCompanyIsEditable,
  accounts,
  setAccounts,
  setCanSave,
  company,
}) => {
  const [errorAccount, setErrorAccount] = useState(null);
  const errorAccountDiv = useRef(null);
  const handleOnChangeInput = (e) => {
    line[e.target.name.match(/[^0-9]+/)] = e.target.value;
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleOnDelete = (e) => {
    line.selected = e.target.checked;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleOnChangeBalance = (value, name) => {
    line[name.match(/[^0-9]+/)] = value;
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleChange = (newValue, actionMeta) => {
    if (newValue) {
      line[actionMeta.name.match(/[^0-9]+/)] = newValue.value;
      line.updated = true;
      let items = [...lines];
      items[index] = line;
      setLines(items);
    }
  };
  const createOption = (option) => ({ label: option?.name, value: option?.id });
  const handleCreate = async (inputValue, actionMeta) => {
    if (inputValue.length > 30) {
      errorAccountDiv.current.style.display = "block";
      setErrorAccount("maximum number of chars 30");
      line.account = null;
      let items = [...lines];
      setLines(items);
      setCanSave(false);
    } else {
      errorAccountDiv.current.style.display = "none";
      await postOnboarder(`/UserManagement/${company?.id}/accounts`, [
        {
          name: inputValue,
          type: "Bank and Cash",
          group: "Payment Gateways",
        },
      ]).then((data) => {
        setAccounts([...accounts, data[0]]);
        line["account"] = data[0].id;
        line.updated = true;
        let items = [...lines];
        items[index] = line;
        setLines(items);
        setCanSave(true);
      });
    }
  };
  return (
    <Tr>
      <Td style={{ minWidth: "10px" }}>
        <FormGroup hidden>
          <AvField name="id" type="text" value={line?.id || ""} />
        </FormGroup>

        <FormGroup>
          <AvField
            type="checkbox"
            name="selected"
            checked={line?.selected || ""}
            onChange={handleOnDelete}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`payment_method${index}`}
            placeholder="Payment Method"
            type="select"
            value={line?.payment_method || ""}
            errorMessage="select Payment Method"
            className="form-control"
            validate={{
              required: { value: true, errorMessage: "Mandatory field" },
            }}
            onChange={handleOnChangeInput}
            disabled={!activeCompanyIsEditable}
          >
            <option key="bank" value="bank">
              Bank
            </option>
            <option key="credit_card" value="credit_card">
              Credit Card
            </option>
            <option key="other" value="other">
              Other
            </option>
          </AvField>
        </FormGroup>
      </Td>
      <Td>
        <FormGroup hidden={line?.payment_method == "other"}>
          <AvField
            type="select"
            name={`bank${index}`}
            value={line?.bank || ""}
            onChange={handleOnChangeInput}
            validate={{
              required: {
                value: line.payment_method == "bank",
                errorMessage: "Mandatory field",
              },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            {banks.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.name}
              </option>
            ))}
          </AvField>
        </FormGroup>
        <FormGroup
          hidden={
            line?.payment_method == "bank" ||
            line?.payment_method == "credit_card"
          }
        >
          <CreatableSelect
            isClearable
            name={`account${index}`}
            isDisabled={!activeCompanyIsEditable}
            onChange={handleChange}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            onCreateOption={handleCreate}
            options={accounts
              .filter((account) => {
                return (
                  account.type === "Bank and Cash" &&
                  account.group === "Payment Gateways"
                );
              })
              .map((option) => createOption(option))}
            value={
              accounts
                .filter((account) => account.id == line?.account)
                .map((account) => createOption(account)) || ""
            }
          />

          <div class="invalid-feedback text-danger" ref={errorAccountDiv}>
            {errorAccount}
          </div>
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`account_number${index}`}
            placeholder="Account Number"
            type="text"
            value={line?.account_number || ""}
            errorMessage="Enter Payment Method"
            className="form-control"
            validate={{
              required: {
                value: line.payment_method == "bank",
                errorMessage: "Mandatory field",
              },
            }}
            onChange={handleOnChangeInput}
            disabled={
              !activeCompanyIsEditable || line.payment_method == "other"
            }
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          {/* <AvField */}
          {/*   name={`balance${index}`} */}
          {/*   placeholder="Balance" */}
          {/*   type="text" */}
          {/*   value={line?.balance || ""} */}
          {/*   errorMessage="Enter Balance" */}
          {/*   className="form-control" */}
          {/*   validate={{ */}
          {/*     required: { value: true, errorMessage: "Mandatory field" }, */}
          {/*   }} */}
          {/*   onChange={handleOnChangeInput} */}
          {/*   disabled={!activeCompanyIsEditable} */}
          {/* /> */}
          <NumberInput
            name={`balance${index}`}
            value={line?.balance || ""}
            onChangeValue={handleOnChangeBalance}
            disabled={!activeCompanyIsEditable}
            errorMessage="Please enter the outstanding amount due. In case credit card is overpaid and there is credit balance enter amount in negative "
          />
        </FormGroup>
      </Td>

      <Td>
        <FormGroup>
          <AvField
            type="select"
            name={`employee${index}`}
            value={line?.employee || ""}
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true, errorMessage: "Mandatory field" },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            {users.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.first_name}
              </option>
            ))}
          </AvField>
        </FormGroup>
      </Td>
    </Tr>
  );
};
const mapStateToProps = (state) => {
  return {
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(PaymentLine);
