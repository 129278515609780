import { takeEvery, select, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  NEXT_STATE,
  PREVIOUS_STATE,
  SAVE_ACTIVE_COMPANY,
} from "./actionTypes.js";
import { resetState } from "./actions";

import { putOnboarder } from "../../api/common";
const getComanyState = (state) => state.CompanyState;
function* changeState(payload) {
  try {
    let companyState = yield select(getComanyState); // <-- get the project

    const company = companyState.activeCompany;
    const values = {
      current_wizard_state: companyState.currentState.code,
    };
    const response = yield call(
      putOnboarder,
      `/UserManagement/${company?.id}/change_company_state`,
      values
    );
    yield put(
      resetState(response.wizard_states, companyState.currentState.code)
    );
  } catch (error) {
    throw error;
  }
}

export function* watchNextState() {
  yield takeEvery(NEXT_STATE, changeState);
  yield takeEvery(PREVIOUS_STATE, changeState);
  yield takeEvery(SAVE_ACTIVE_COMPANY, changeState);
}

function* sagaState() {
  yield all([fork(watchNextState)]);
}

export default sagaState;
