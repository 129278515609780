import React from "react";
import PropTypes from "prop-types";
import { Route, useRouteMatch } from "react-router-dom";
import ListUsers from "./ListUsers";
import Form from "./Form";

export default function Users() {
  let match = useRouteMatch();

  return (
    <>
      <Route
        exact
        path={match.path}
        render={(routeProps) => <ListUsers {...routeProps} />}
      />

      <Route path={`${match.path}/createUser`} component={Form} />
    </>
  );
}

Users.propTypes = {
  match: PropTypes.object,
};
