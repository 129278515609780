import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Alert,
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import { getOnboarder, postOnboarder, downloadFile } from "../../../api/common";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import DebitLine from "./debitLine.js";
import TotalLines from "../../TotalLines";
import { handleErrorsAllMessage, formatDate } from "../../../utils";
import {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";
import Import from "../../ImportModal";
import debounce from "lodash.debounce";

const Debits = ({
  type,
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const [canSave, setCanSave] = useState(false);
  const [errors, setErrors] = useState([]);
  const [lines, setLines] = useState([]);
  const [deleteLines, setDeleteLines] = useState([]);
  const [totalDebits, setTotalDebits] = useState(0);

  const [importModal, setImportModal] = useState(false);
  const form = useRef([]);

  const onValidSubmit = (e, vals) => {};

  const getLines = () => {
    showLoader();
    getOnboarder(`/UserManagement/${company?.id}/${type}`)
      .then((data) => {
        data.forEach((line) => {
          line.updated = false;
          line.selected = false;
        });
        setLines(data);
        removeLoader();
      })
      .catch((error) => {
        removeLoader();
      });
  };
  const toggleImport = () => {
    if (importModal) {
      getLines();
    }
    setImportModal(!importModal);
  };
  let save = debounce(() => {
    saveDebits(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    saveDebits(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveDebits(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    saveDebits(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  useEffect(() => {
    getLines();
    if (form.current) {
      form.current.setTouched(Object.keys(form.current._inputs));
      setCanSave(!form.current.hasError());
    }
  }, []);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  let saveDebits = (cb) => {
    const body = {
      debts: lines.filter((line) => line.updated),
      delete: deleteLines,
    };
    if (body.debts.length || body.delete.length) {
      setCanSave(false);
      showLoader();
      postOnboarder(`/UserManagement/${company?.id}/${type}/submit`, body)
        .then((data) => {
          setErrors([]);
          data.forEach((line) => {
            line.updated = false;
            line.selected = false;
          });
          setLines(data);
          cb();
          setCanSave(true);
          removeLoader();
        })
        .catch((error) => {
          setErrors(error);
          removeLoader();
          setCanSave(true);
          throw error;
        });
    } else {
      setErrors({});
      cb();
    }
  };

  useEffect(() => {
    if (form.current) {
      form.current.setTouched(Object.keys(form.current._inputs));
      const values = form.current.getValues();
      form.current.validateAll(values).then((errors) => {
        if (form.current) {
          setCanSave(!form.current.hasError());
        }
      });
    }
    let sumOfLines = lines.reduce(
      (sum, line) => sum + parseFloat(line.total || 0),
      0
    );
    setTotalDebits(sumOfLines);
  }, [lines]);
  const handleAddNew = () => {
    setLines((lines) => [...lines, { updated: true }]);
  };
  const deleteRows = debounce(() => {
    setLines((lines) => lines.filter((line) => !line.selected));
    setDeleteLines(
      lines.filter((line) => line.selected).map((line) => line.id)
    );
  }, 200);
  const toggleSelectAll = (e) => {
    const checked = e.target.checked;
    let items = [...lines];
    items.forEach((item) => {
      if (!item?.is_retail) item.selected = checked;
    });
    setLines(items);
  };

  const downloadTemplate = () => {
    const url = "/UserManagement/export_debt_template";
    const filename = `${type}_template.xlsx`;
    showLoader();
    downloadFile(`${url}`, filename)
      .then(() => {
        removeLoader();
      })
      .catch((error) => {
        setErrors(error);
        removeLoader();
      });
  };

  return (
    <div className="container-fluid">
      <ActionHeader
        canSave={canSave}
        save={save}
        deleteRows={deleteRows}
        lines={lines.filter((line) => !line?.is_retail)}
        saveExit={saveExit}
      />

      <Import
        modal={importModal}
        toggle={toggleImport}
        endpoint={`/UserManagement/${company.id}/import_${type}`}
      />
      <Card style={{ marginBottom: "0rem" }}>
        <CardBody style={{ padding: "0.75rem", paddingBottom: "0rem" }}>
          <Row>
            <CardTitle>
              Total Due Days From: {formatDate(company.starting_date)}
            </CardTitle>
          </Row>
          <Row>
            <Col>
              {handleErrorsAllMessage(errors) && (
                <Alert color="danger">{handleErrorsAllMessage(errors)}</Alert>
              )}
            </Col>
          </Row>

          <AvForm
            className="needs-validation"
            ref={form}
            onValidSubmit={onValidSubmit}
          >
            <div className="table-container-input">
              <Table className="table-responsive-input">
                <Thead>
                  <Tr>
                    <Th style={{ minWidth: "10px" }}>
                      <FormGroup>
                        <AvField
                          type="checkbox"
                          name="selected"
                          onChange={toggleSelectAll}
                          disabled={!activeCompanyIsEditable}
                        />
                      </FormGroup>
                    </Th>
                    <Th>
                      {type == "receivables" ? "Customer" : "Supplier"} Name
                    </Th>
                    <Th>
                      {type == "receivables" ? "Customer" : "Supplier"} Tax ID
                    </Th>
                    <Th>Total Due (AED)</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lines.map((line, index) => {
                    return (
                      <DebitLine
                        key={index}
                        index={index}
                        line={line}
                        setLines={setLines}
                        lines={lines}
                        type={type}
                      />
                    );
                  })}
                </Tbody>
              </Table>
            </div>
            {activeCompanyIsEditable && (
              <Button color="link" onClick={handleAddNew}>
                Add New Line
              </Button>
            )}
          </AvForm>

          <Row>
            <Col md="12">
              <TotalLines
                title={
                  type == "receivables"
                    ? "Total Due from all Debtors/Receivables"
                    : "Total Due from all Creditors/Payables"
                }
                total={totalDebits}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ActionFooter
        next={next}
        canSave={canSave}
        back={back}
        complete={saveDebits}
        downloadTemplate={downloadTemplate}
        importToggle={toggleImport}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(Debits);
