import React from "react";
import { Route, Redirect } from "react-router-dom";
import {getCookie} from "../../utils"
const Home = () => {
  const savedJwt = getCookie("jwt");
  return (
    <Route
      render={(props) => {
        if (!savedJwt) {
          return (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          );
        }
        return <Redirect to={{ pathname: "/dashboard" }} />;
      }}
    />
  );
};

export default Home;
