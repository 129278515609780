import {
  ASSIGN_TO_ME_REQUEST,
  API_ERROR,
  ASSIGN_TO_ME_SUCCESS,
  ASSIGN_TO_ME_REVIEW_REQUEST,
  ASSIGN_TO_ME_REVIEW_SUCCESS,
  REST_ERROR,
  START_ON_BOARDING_REQUEST,
  START_ON_BOARDING_SUCCESS,
  START_REVIEW_REQUEST,
  START_REVIEW_SUCCESS,
  CHANGE_ON_BOARDING_REQUEST,
  CHANGE_REVIEWER_REQUEST,
  CHANGE_ON_BOARDING_SUCCESS,
  REST_STATE,
} from "./actionTypes";

const initialState = {
  errors: {},
  loading: false,
  error: "",
  success: false,
  companySelected: 0,
  userId: 0,
  successStartOnboarding: false,
  successChangeOnBoarding: false,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_TO_ME_REQUEST:
      state = {
        ...state,
        loading: true,
        success: false,
        companySelected: action.payload.companyId,
        userId: action.payload.userId,
      };
      break;
    case ASSIGN_TO_ME_REVIEW_REQUEST:
      state = {
        ...state,
        loading: true,
        success: false,
        companySelected: action.payload.companyId,
        userId: action.payload.userId,
      };
      break;
    case ASSIGN_TO_ME_SUCCESS:
      state = {
        ...state,
        loading: false,
        errors: "",
        error: "",
        success: true,
      };
      break;

    case ASSIGN_TO_ME_REVIEW_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        errors: "",
        error: "",
      };
      break;
    case START_ON_BOARDING_REQUEST:
      state = {
        ...state,
        loading: true,
        successStartOnboarding: false,
        companySelected: action.payload.companyId,
      };
      break;

    case START_REVIEW_REQUEST:
      state = {
        ...state,
        loading: true,
        successStartReview: false,
        companySelected: action.payload.companyId,
      };
      break;
    case START_ON_BOARDING_SUCCESS:
      state = {
        ...state,
        loading: false,
        successStartOnboarding: true,
        errors: "",
        error: "",
      };
      break;

    case START_REVIEW_SUCCESS:
      state = {
        ...state,
        loading: false,
        successStartOnboarding: true,
        errors: "",
        error: "",
      };
      break;
    case CHANGE_ON_BOARDING_REQUEST:
      state = {
        ...state,
        loading: true,
        successChangeOnBoarding: false,
        userId: action.payload.userId,
        companyId: action.payload.companyId,
        companySelected: action.payload.companyId,
      };
      break;
    case CHANGE_REVIEWER_REQUEST:
      state = {
        ...state,
        loading: true,
        successChangeOnBoarding: false,
        userId: action.payload.userId,
        companyId: action.payload.companyId,
        companySelected: action.payload.companyId,
      };
      break;
    case CHANGE_ON_BOARDING_SUCCESS:
      state = {
        ...state,
        loading: false,
        successChangeOnBoarding: true,
        errors: "",
        error: "",
      };
      break;
    case API_ERROR:
      if (typeof action.payload === "object") {
        state = { ...state, errors: action.payload, loading: false };
      } else {
        state = { ...state, error: action.payload, loading: false };
      }
      break;
    case REST_ERROR:
      state = { ...state, errors: {}, error: "" };
      break;
    case REST_STATE:
      state = { ...initialState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default users;
