import { GET_WIZARD, REST_STATE_WIZARD } from "./actionTypes";

export const getWizardStatus = wizard => {
  return {
    type: GET_WIZARD,
    payload: { wizard }
  };
};

export const restStateWizard = error => {
  return {
    type: REST_STATE_WIZARD,
    payload: error
  };
};
