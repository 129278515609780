import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Row, Col, Container } from "reactstrap";
import Header from "../../components/CompanyWizard/Header";
import States from "../../components/CompanyWizard/States";
import Content from "../../components/CompanyWizard/Content";
import { getOnboarder } from "../../api/common";
import {
  resetState,
  setActiveCompany,
  setActiveCompanyIsEditable,
} from "../../store/CompanyState/actions.js";
import { fetchRole } from "../../store/auth/Companies/actions";
const Company = ({
  resetState,
  setActiveCompany,
  company,
  setActiveCompanyIsEditable,
  roleUser,
  fetchRole,
}) => {
  const params = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const companyId = params.company_id;
  useEffect(() => {
    getOnboarder(`/UserManagement/company/${companyId}`).then((data) => {
      setActiveCompany(data);
      setActiveCompanyIsEditable(
        (roleUser.is_staff &&
          data.status !== "completed" &&
          data.status !== "finalizing" &&
          data.status !== "cancelled") ||
          (data.status === "in_process" && data.user === roleUser.id)
      );
      resetState(data.wizard_states, data.current_wizard_state);
      setIsLoaded(true);
    });
  }, []);
  useEffect(() => {
    if (!roleUser) fetchRole();
    return () => {
      setActiveCompany({});
    };
  }, []);
  return (
    <>
      <div className="content">
        {isLoaded && (
          <>
            <States />

            <Content />
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    roleUser: state.Companies.role,
  };
};
export default connect(mapStateToProps, {
  resetState,
  setActiveCompany,
  setActiveCompanyIsEditable,
  fetchRole,
})(Company);
