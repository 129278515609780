import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import { handleErrorsMessage } from "../../utils";
import HistoryCard from "../../components/History/HistoryCard";
import Pagination from "../../components/Pagination";

import { deleteOnboarder, putOnboarder, getOnboarder } from "../../api/common";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { DropdownItem } from "reactstrap";
import DropDownList from "../../components/DropdownList";

const PAGELIMIT = 20;
const DefaultCard = ({
  card,
  dragging,
  allowRemoveCard,
  onCardRemove,
  afterSave,
  company,
  roleUser,
}) => {
  const [errors, setErrors] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setErrors({});
    afterSave();
  };

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const onValidSubmit = (e, vals) => {
    putOnboarder(`/UserManagement/reminder/${card.id}`, vals)
      .then(() => {
        toggle();
        setErrors({});
        toast.success(`Reminder saved`);
      })
      .catch((error) => {
        setErrors(error);
        throw error;
      });
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const deleteRemider = async () => {
    try {
      const data = await deleteOnboarder(`/UserManagement/reminder/${card.id}`);
      toggleDeleteModal();
      toggle();
    } catch (error) {
      throw error;
    }
  };
  const [history, setHisotry] = useState(false);
  const [histories, setHistories] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);

  const toggleHistory = () => {
    setHisotry(!history);
  };
  const getHistory = () => {
    getOnboarder(
      `/UserManagement/reminder/${card.id}/history?page=${page}`
    ).then((data) => {
      setHistories(data["results"]);
      setCount(data["count"]);
    });
  };
  useEffect(() => {
    if (page) getHistory();
  }, [page]);

  useEffect(() => {
    if (page === 1) getHistory();
    setPage(1);
  }, [history]);
  return (
    <React.Fragment>
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggle} charCode="Y">
          Confirm Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this reminder?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button color="danger" onClick={deleteRemider}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <AvForm onValidSubmit={onValidSubmit}>
          <ModalHeader toggle={toggle} close={closeBtn}>
            <div className="d-flex">
              {card.title}
              <div className="pl-2">
                <DropDownList>
                  <DropdownItem onClick={toggleHistory}>History</DropdownItem>
                  {roleUser.id === company.user && (
                    <DropdownItem onClick={toggleDeleteModal}>
                      Delete
                    </DropdownItem>
                  )}
                </DropDownList>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <Card style={{ maxHeight: "400px" }}>
              <CardBody>
                {history && (
                  <Row>
                    <Col>
                      <div className="history-title"> History</div>
                      <div
                        className="history-list-page"
                        style={{
                          minHeight: "350px",
                          maxHeight: "350px",
                          overflow: "auto",
                        }}
                      >
                        <div className="list-group">
                          {histories.map((history, index) => {
                            return <HistoryCard history={history} />;
                          })}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Pagination
                          name="HistoryPage"
                          totalRecords={count}
                          pageLimit={PAGELIMIT}
                          currentPage={page}
                          setCurrentPage={setPage}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {!history && (
                  <React.Fragment>
                    <Row>
                      <Col>
                        {handleErrorsMessage(errors) && (
                          <Alert color="danger">
                            {handleErrorsMessage(errors)}
                          </Alert>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <AvField
                            id="statusfield"
                            type="select"
                            name="status"
                            value={card.status}
                            label="Status"
                            disabled={roleUser.id !== company.user}
                          >
                            <option key="open" value="open">
                              Open
                            </option>
                            <option key="in_progress" value="in_progress">
                              In-Progress
                            </option>
                            <option key="closed" value="closed">
                              Closed
                            </option>
                          </AvField>
                          {!!errors?.status && (
                            <Alert color="danger">{errors?.status[0]}</Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="description*">Notes</Label>
                          <AvInput
                            type="textarea"
                            name="description"
                            id="description"
                            placeholder="description"
                            rows="10"
                            value={card.description}
                            required
                            disabled={roleUser.id !== company.user}
                          />
                          {!!errors?.description && (
                            <Alert color="danger">
                              {errors?.description[0]}
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            {history && (
              <Button color="primary" onClick={toggleHistory}>
                Back
              </Button>
            )}
            {!history && (
              <Button color="primary" type="submit">
                Save
              </Button>
            )}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
      <div
        className={`react-kanban-card ${
          dragging ? "react-kanban-card--dragging" : ""
        }`}
        onClick={toggle}
      >
        <span>
          <div className="react-kanban-card__title">
            <span>{card.title}</span>
            {allowRemoveCard && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onCardRemove(card)}
              >
                ×
              </span>
            )}
          </div>
        </span>
        <div className="react-kanban-card__description">{card.description}</div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    roleUser: state.Companies.role,
  };
};
export default connect(mapStateToProps, {})(DefaultCard);
