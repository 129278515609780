import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import { connect } from "react-redux";
import Max2DigitsNumberInput from "../../Inputs/Max2DigitsNumberInput";
import { Tr, Td } from "react-super-responsive-table";
const LiabilitieLine = ({
  index,
  line,
  banks,
  setLines,
  lines,
  activeCompanyIsEditable,
}) => {
  const handleOnChangeInput = (e) => {
    line[e.target.name.split("-")[0]] = e.target.value;
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleOnChangeAmount = (value, name) => {
    line[name.split("-")[0]] = value;
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleOnDelete = (e) => {
    line.selected = e.target.checked;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  return (
    <Tr>
      <Td style={{ minWidth: "10px" }}>
        <FormGroup hidden>
          <AvField name="id" type="text" value={line?.id || ""} />
        </FormGroup>

        <FormGroup>
          <AvField
            type="checkbox"
            name="selected"
            checked={line?.selected || ""}
            onChange={handleOnDelete}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            type="select"
            name={`type-${index}`}
            value={line?.type || ""}
            errorMessage="Select a Type"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            <option key="bank" value="bank">
              Bank
            </option>
            <option key="other" value="other">
              Other
            </option>
            ))}
          </AvField>
        </FormGroup>
      </Td>
      <Td>
        <FormGroup hidden={line.type == "other"}>
          <AvField
            type="select"
            name={`bank-${index}`}
            value={line?.bank || ""}
            errorMessage="Select a Bank"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: line.type == "bank" },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            {banks.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.name}
              </option>
            ))}
          </AvField>
        </FormGroup>
        <FormGroup hidden={line.type == "bank" || !line.type}>
          <AvField
            name={`lender_name-${index}`}
            placeholder="lender name"
            type="text"
            value={line?.lender_name || ""}
            errorMessage="Enter Lender Name"
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: line.type == "other" },
              maxLength: {
                value: 50,
                errorMessage: "Max 50 chars.",
              },
            }}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <Max2DigitsNumberInput
            name={`amount-${index}`}
            placeholder="Amount"
            type="text"
            value={line?.amount || ""}
            onChangeValue={handleOnChangeAmount}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>

      <Td>
        <FormGroup>
          <AvField
            type="select"
            name={`term-${index}`}
            value={line?.term || ""}
            errorMessage="Select a Loan term"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            <option key="long_term" value="long_term">
              Long term
            </option>
            <option key="short_term" value="short_term">
              Short term
            </option>
            ))}
          </AvField>
        </FormGroup>
      </Td>
    </Tr>
  );
};
const mapStateToProps = (state) => {
  return {
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(LiabilitieLine);
