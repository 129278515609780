const MappingFields = {
  ar_name: "اسم الشركة",
  ar_first_name: "الاسم الاول",
  ar_last_name: "لقب العائلة",
  owner_emirates_id: "رقم الهوية",
  starting_date: "Starting Date With The Accounter",
};
const RequiredError = ({ errors }) => {
  return (
    <div>
      {(typeof errors === "string" || errors instanceof String) && errors}
      {Array.isArray(errors) && errors}
      {!Array.isArray(errors) &&
        (typeof errors === "object" || errors instanceof Object) &&
        Object.entries(errors).map(([key, values]) => {
          return (
            <div>
              {key && key}:
              {Array.isArray(values) && (
                <ul>
                  {values.map((value) => {
                    return (
                      <li>{MappingFields[value] || value.replace("_", " ")}</li>
                    );
                  })}
                </ul>
              )}
              {(typeof values === "string" || values instanceof String) &&
                values}
            </div>
          );
        })}
    </div>
  );
};

export default RequiredError;
