import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import FileUpload from "../../FileUpload";
import { handleErrorsMessage } from "../../../utils";
import {
  getBasicInfoApi,
  getBasicInfoStateApi,
  getBasicInfoCountryApi,
} from "../../../api/basicInfo";
import { putFormOnboarder, postOnboarder } from "../../../api/common";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import {
  setNextStep,
  nextState,
  previousState,
  setActiveCompany,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import { getFilenameFromUrl } from "../../../utils";
import debounce from "lodash.debounce";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";

const BasicInfo = ({
  nextStep,
  nextState,
  previousState,
  setNextStep,
  company,
  activeCompanyIsEditable,
  setActiveCompany,
  saveActiveCompany,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const form = useRef(null);

  const [errors, setErrors] = useState({});

  const [showAgreementMessage, setShowAgreementMessage] = useState(false);
  const [basicInfo, setBasicInfo] = useState({});
  const [eIdFile, setEIdFile] = useState(null);
  const [articleOfAssociationFile, setArticleOfAssociationFile] =
    useState(null);
  const [tradeLicenseFile, setTradeLicenseFile] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const onValidSubmit = (e, vals) => {};

  let save = debounce(() => {
    saveBasicInfo(() => {
      setNextStep(null);
    });
  }, 200);
  const saveExit = debounce(() => {
    saveBasicInfo(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveBasicInfo(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const getFile = (data, name) => {
    fetch(data[name])
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], getFilenameFromUrl(data[name]), {
          type: blob.type,
        });
        setBasicInfo((basicInfo) => {
          return { ...basicInfo, [name]: file };
        });
      });
  };
  const getData = () => {
    showLoader();
    getBasicInfoApi(`/companyProfile/${company?.id}`)
      .then((data) => {
        data.updated = false;
        setBasicInfo(data);
        if (data.country) {
          getStates(data.country);
        }
        if (data.trade_license_file) {
          getFile(data, "trade_license_file");
        }
        if (data.e_id_file) {
          getFile(data, "e_id_file");
        }
        if (data.article_of_association_file) {
          getFile(data, "article_of_association_file");
        }
      })
      .then(() => {
        removeLoader();
      });
  };
  const getCountries = () => {
    getBasicInfoCountryApi(`/country/`).then((data) => {
      if (activeCompanyIsEditable)
        setCountryList(data.filter((line) => line?.erp_id && line?.is_active));
      else {
        setCountryList(data);
      }
    });
  };
  const getStates = (countryId) => {
    getBasicInfoStateApi(`/country/${countryId}/state`).then((data) => {
      if (activeCompanyIsEditable)
        setStateList(data.filter((line) => line?.erp_id && line?.is_active));
      else {
        setStateList(data);
      }
    });
  };
  React.useEffect(() => {
    getData();
    getCountries();
  }, []);

  const handleOnChangeCountry = (e) => {
    getStates(e.target.value);
  };

  const saveBasicInfo = async (cb) => {
    const values = form.current.getValues();
    if (basicInfo.updated) {
      const res = await form.current.validateAll(values);
      if (!res.isValid) {
        form.current.setTouched(Object.keys(form.current._inputs));
      } else {
        const vals = { ...basicInfo };
        if (!vals?.article_of_association_file_update) {
          delete vals["article_of_association_file"];
        }

        if (!vals.e_id_file_update) {
          delete vals["e_id_file"];
        }
        if (!vals.trade_license_file_update) {
          delete vals["trade_license_file"];
        }
        try {
          showLoader();
          let data = await putFormOnboarder(
            `/UserManagement/companyProfile/${company?.id}`,
            vals
          );
          data.updated = false;
          if (showAgreementMessage) toast.info("agreement document is updated");
          setShowAgreementMessage(false);

          setBasicInfo(data);
          if (data.trade_license_file) {
            getFile(data, "trade_license_file");
          }
          if (data.e_id_file) {
            getFile(data, "e_id_file");
          }
          if (data.article_of_association_file) {
            getFile(data, "article_of_association_file");
          }
          setErrors({});
          const companyData = { ...company, ...data };
          setErrors({});
          setActiveCompany(companyData);
          cb();
          removeLoader();
        } catch (error) {
          setErrors(error);
          removeLoader();
          throw error;
        }
      }
    } else {
      setErrors({});
      cb();
    }
  };
  const handleOnChange = (e) => {
    if (e.target.type === "file") {
      return;
    }
    if (
      [
        "first_name",
        "last_name",
        "name",
        "street",
        "street2",
        "city",
        "zip",
        "state",
        "country",
        "ar_first_name",
        "ar_last_name",
        "owner_emirates_id",
        "ar_name",
        "ar_street",
        "ar_street2",
        "ar_city",
      ].includes(e.target.name)
    ) {
      setShowAgreementMessage(true);
    }
    setBasicInfo((basicInfo) => {
      return { ...basicInfo, [e.target.name]: e.target.value, updated: true };
    });
  };
  const handleOnChangeFile = (name, value) => {
    setBasicInfo((basicInfo) => {
      return {
        ...basicInfo,
        [name]: value,
        [`${name}_update`]: true,
        updated: true,
      };
    });
  };
  const verifyMobile = async () => {
    try {
      showLoader();
      const values = { mobile: basicInfo.mobile };
      const data = await postOnboarder("/mobile/verify/", values);
      toast.success("message is sent to mobile");
      setBasicInfo((basicInfo) => {
        return { ...basicInfo, can_send_verify: false };
      });
      removeLoader();
    } catch (errors) {
      removeLoader();
      throw errors;
    }
  };
  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  return (
    <div className="container-fluid">
      <ActionHeader canSave={true} save={save} saveExit={saveExit} />
      <div>
        <AvForm
          ref={form}
          name="current"
          onChange={handleOnChange}
          onValidSubmit={onValidSubmit}
        >
          <Row>
            <Col>
              {handleErrorsMessage(errors) && (
                <Alert color="danger">{handleErrorsMessage(errors)}</Alert>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle>Owner Details</CardTitle>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom01">First name*</Label>
                        <AvField
                          name="first_name"
                          placeholder="First name"
                          value={basicInfo?.first_name || ""}
                          type="text"
                          errorMessage="Enter First Name"
                          className="form-control"
                          validate={{
                            pattern: {
                              value: "^[A-Za-z]+$",
                              errorMessage:
                                "Invalid First Name english character only",
                            },
                            required: { value: true },
                            minLength: {
                              value: 3,
                              errorMessage: "Min 3 chars.",
                            },
                            maxLength: {
                              value: 30,
                              errorMessage: "Max 30 chars.",
                            },
                          }}
                          id="validationCustom01"
                          disabled={!activeCompanyIsEditable}
                        />
                        {errors?.first_name && (
                          <Alert color="danger">{errors.first_name}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom02">Last name*</Label>
                        <AvField
                          name="last_name"
                          placeholder="Last name*"
                          value={basicInfo?.last_name || ""}
                          type="text"
                          errorMessage="Enter Last name"
                          className="form-control"
                          validate={{
                            pattern: {
                              value: "^[A-Za-z]+$",
                              errorMessage:
                                "Invalid Last Name English Character Only",
                            },
                            required: { value: true },
                            minLength: {
                              value: 3,
                              errorMessage: "Min 3 chars.",
                            },
                            maxLength: {
                              value: 30,
                              errorMessage: "Max 30 chars.",
                            },
                          }}
                          id="validationCustom02"
                          disabled={!activeCompanyIsEditable}
                        />
                        {errors?.last_name && (
                          <Alert color="danger">{errors.last_name}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <AvField
                      name="email"
                      label="E-Mail"
                      placeholder="Enter Valid Email"
                      value={basicInfo?.email || ""}
                      type="email"
                      errorMessage="Invalid Email"
                      validate={{
                        email: { value: true },
                      }}
                      disabled={!activeCompanyIsEditable}
                    />
                    {!!errors?.email && (
                      <Alert color="danger">{errors?.email}</Alert>
                    )}
                  </FormGroup>
                  <Label>Mobile Number*</Label>
                  <Row>
                    <Col md="10">
                      <AvField
                        name="mobile"
                        placeholder="Enter Mobile Number"
                        value={basicInfo?.mobile || ""}
                        type="text"
                        errorMessage="Invalid Mobile Number"
                        disabled={!activeCompanyIsEditable}
                      />
                      {errors?.mobile && (
                        <Alert color="danger">{errors.mobile}</Alert>
                      )}
                    </Col>

                    {activeCompanyIsEditable &&
                      !basicInfo.is_mobile_verified &&
                      basicInfo.can_send_verify && (
                        <Col md="2">
                          <Button color="primary" onClick={verifyMobile}>
                            Verify
                          </Button>
                        </Col>
                      )}
                    {basicInfo.is_mobile_verified && (
                      <Col md="2">
                        <h3 className="text-success">
                          <i class="fas fa-check"></i>
                        </h3>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Company Details</CardTitle>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom01">
                          Company Name*
                        </Label>
                        <AvField
                          name="name"
                          placeholder="Company Name"
                          value={basicInfo?.name || ""}
                          type="text"
                          errorMessage="Enter Company Name"
                          className="form-control"
                          validate={{
                            required: { value: true },
                            maxLength: {
                              value: 150,
                              errorMessage: "Max 150 chars.",
                            },
                          }}
                          id="validationCustom01"
                          disabled={!activeCompanyIsEditable}
                        />
                        {errors?.name && (
                          <Alert color="danger">{errors.name}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom02">
                          Trade License No.*
                        </Label>
                        <AvField
                          name="trade_license"
                          placeholder="Trade License No."
                          value={basicInfo?.trade_license || ""}
                          type="text"
                          errorMessage="Enter Trade License No."
                          className="form-control"
                          validate={{
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: "Invalid Trade License Number only",
                            },
                            maxLength: {
                              value: 25,
                              errorMessage: "Max 25 No.",
                            },
                          }}
                          id="validationCustom02"
                          disabled={!activeCompanyIsEditable}
                        />
                        {errors?.trade_license && (
                          <Alert color="danger">{errors.trade_license}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <AvField
                          name="street"
                          label="Street1*"
                          placeholder="Street"
                          value={basicInfo?.street || ""}
                          type="text"
                          validate={{
                            required: { value: false },
                            maxLength: {
                              value: 150,
                              errorMessage: "Max 150 chars.",
                            },
                          }}
                          disabled={!activeCompanyIsEditable}
                        />
                        {!!errors?.street && (
                          <Alert color="danger">{errors?.street}</Alert>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <AvField
                          name="street2"
                          label="Street2"
                          placeholder="Street2"
                          value={basicInfo?.street2 || ""}
                          type="text"
                          validate={{
                            required: { value: false },
                            maxLength: {
                              value: 150,
                              errorMessage: "Max 150 chars.",
                            },
                          }}
                          disabled={!activeCompanyIsEditable}
                        />

                        {!!errors?.street2 && (
                          <Alert color="danger">{errors?.street2}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <AvField
                          name="city"
                          label="City*"
                          placeholder="City"
                          value={basicInfo?.city || ""}
                          type="text"
                          validate={{
                            required: { value: false },
                            maxLength: {
                              value: 50,
                              errorMessage: "Max 50 chars.",
                            },
                          }}
                          disabled={!activeCompanyIsEditable}
                        />
                        {!!errors?.city && (
                          <Alert color="danger">{errors?.city}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="state"
                          value={basicInfo.state || ""}
                          label="State*"
                          disabled={!activeCompanyIsEditable}
                        >
                          <option></option>
                          {stateList.map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          ))}
                        </AvField>
                        {!!errors?.state && (
                          <Alert color="danger">{errors?.state}</Alert>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <AvField
                          name="zip"
                          label="ZipCode*"
                          placeholder="12345"
                          value={basicInfo?.zip || ""}
                          type="text"
                          validate={{
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: "Invalid Zipcode Number only",
                            },
                            required: { value: false },
                          }}
                          disabled={!activeCompanyIsEditable}
                        />
                        {!!errors?.zip && (
                          <Alert color="danger">{errors?.zip}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="country"
                          onChange={handleOnChangeCountry}
                          value={basicInfo.country || ""}
                          label="Country*"
                          disabled={!activeCompanyIsEditable}
                        >
                          <option></option>
                          {countryList.map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          ))}
                        </AvField>
                        {!!errors?.country && (
                          <Alert color="danger">{errors?.country}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" className="text-right" style={{ direction: "rtl" }}>
              <Card>
                <CardBody>
                  <CardTitle>بيانات العميل</CardTitle>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom03">الاسم الاول*</Label>
                        <AvField
                          name="ar_first_name"
                          placeholder="الاسم الاول"
                          value={basicInfo?.ar_first_name || ""}
                          type="text"
                          errorMessage="Enter First Name"
                          className="form-control"
                          validate={{
                            pattern: {
                              value: "^[\u0600-\u06FF ]+$",
                              errorMessage: "Accept only arabic characters",
                            },
                            required: { value: true },
                            minLength: {
                              value: 3,
                              errorMessage: "Min 3 chars.",
                            },
                            maxLength: {
                              value: 30,
                              errorMessage: "Max 30 chars.",
                            },
                          }}
                          id="validationCustom03"
                          disabled={!activeCompanyIsEditable}
                        />
                        {errors?.ar_first_name && (
                          <Alert color="danger">{errors.ar_first_name}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">لقب العائلة*</Label>
                        <AvField
                          name="ar_last_name"
                          placeholder="لقب العائلة"
                          value={basicInfo?.ar_last_name || ""}
                          type="text"
                          errorMessage="Enter Last name"
                          className="form-control"
                          validate={{
                            pattern: {
                              value: "^[\u0600-\u06FF ]+$",
                              errorMessage: "Accept only arabic characters",
                            },
                            required: { value: true },
                            minLength: {
                              value: 3,
                              errorMessage: "Min 3 chars.",
                            },
                            maxLength: {
                              value: 30,
                              errorMessage: "Max 30 chars.",
                            },
                          }}
                          id="validationCustom04"
                          disabled={!activeCompanyIsEditable}
                        />
                        {errors?.ar_last_name && (
                          <Alert color="danger">{errors.ar_last_name}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="validationCustom04">رقم الهوية*</Label>
                        <AvField
                          name="owner_emirates_id"
                          placeholder="784-XXXX-XXXXXXX-X"
                          value={basicInfo?.owner_emirates_id || ""}
                          type="text"
                          errorMessage="Enter Owner Emirates Id"
                          className="form-control"
                          validate={{
                            pattern: {
                              value: "^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$",
                              errorMessage:
                                "Owner Emirates ID format is 784-XXXX-XXXXXXX-X and accepts only numbers",
                            },
                            required: { value: true },
                            minLength: {
                              value: 3,
                              errorMessage: "Min 3 chars.",
                            },
                            maxLength: {
                              value: 30,
                              errorMessage: "Max 30 chars.",
                            },
                          }}
                          id="validationCustom04"
                          disabled={!activeCompanyIsEditable}
                        />
                        {errors?.owner_emirates_id && (
                          <Alert color="danger">
                            {errors.owner_emirates_id}
                          </Alert>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>بيانات الشركة</CardTitle>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="validationCustom01">اسم الشركة*</Label>
                        <AvField
                          name="ar_name"
                          placeholder="اسم الشركة"
                          value={basicInfo?.ar_name || ""}
                          type="text"
                          errorMessage="Enter Company Name"
                          className="form-control"
                          validate={{
                            pattern: {
                              value: "^[\u0600-\u06FF ]+$",
                              errorMessage: "Accept only arabic characters",
                            },
                            required: { value: true },
                            maxLength: {
                              value: 150,
                              errorMessage: "Max 150 chars.",
                            },
                          }}
                          id="validationCustom01"
                          disabled={!activeCompanyIsEditable}
                        />
                        {errors?.ar_name && (
                          <Alert color="danger">{errors.ar_name}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <AvField
                          name="ar_street"
                          label="العنوان الاول"
                          placeholder="العنوان الاول"
                          value={basicInfo?.ar_street || ""}
                          type="text"
                          validate={{
                            pattern: {
                              value: "^[\u0600-\u06FF 0-9!@#$%^&*)(+=._-]+$",
                              errorMessage: "Accept only arabic characters",
                            },
                            required: { value: false },
                            maxLength: {
                              value: 150,
                              errorMessage: "Max 150 chars.",
                            },
                          }}
                          disabled={!activeCompanyIsEditable}
                        />
                        {!!errors?.ar_street && (
                          <Alert color="danger">{errors?.ar_street}</Alert>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup>
                        <AvField
                          name="ar_street2"
                          label="العنوان الثاني"
                          placeholder="العنوان الثاني"
                          value={basicInfo?.ar_street2 || ""}
                          type="text"
                          validate={{
                            pattern: {
                              value: "^[\u0600-\u06FF 0-9!@#$%^&*)(+=._-]+$",
                              errorMessage: "Accept only arabic characters",
                            },
                            required: { value: false },
                            maxLength: {
                              value: 150,
                              errorMessage: "Max 150 chars.",
                            },
                          }}
                          disabled={!activeCompanyIsEditable}
                        />

                        {!!errors?.ar_street2 && (
                          <Alert color="danger">{errors?.ar_street2}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <AvField
                          name="ar_city"
                          label="المدينة*"
                          placeholder="المدينة"
                          value={basicInfo?.ar_city || ""}
                          type="text"
                          validate={{
                            pattern: {
                              value: "^[\u0600-\u06FF ]+$",
                              errorMessage: "Accept only arabic characters",
                            },
                            required: { value: false },
                            maxLength: {
                              value: 50,
                              errorMessage: "Max 50 chars.",
                            },
                          }}
                          disabled={!activeCompanyIsEditable}
                        />
                        {!!errors?.ar_city && (
                          <Alert color="danger">{errors?.ar_city}</Alert>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="country"
                          onChange={handleOnChangeCountry}
                          value={basicInfo.country || ""}
                          label="الدوله"
                          disabled={true}
                        >
                          <option></option>
                          {countryList.map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.ar_name}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Col md="12">
            <Card>
              <CardBody>
                <Row style={{ alignItems: "end" }}>
                  <Col md="4" className="text-center">
                    <FileUpload
                      title="Owner's E-ID*"
                      nameField="e_id_file"
                      currentFile={basicInfo.e_id_file}
                      handleOnChange={(file) => {
                        handleOnChangeFile("e_id_file", file);
                      }}
                      disabled={!activeCompanyIsEditable}
                    />
                    {!!errors?.e_id_file && (
                      <Alert color="danger">{errors?.e_id_file}</Alert>
                    )}
                  </Col>
                  <Col md="4" className="text-center">
                    <FileUpload
                      title="Article of Association"
                      nameField="article_of_association_file"
                      currentFile={basicInfo.article_of_association_file}
                      handleOnChange={(file) => {
                        handleOnChangeFile("article_of_association_file", file);
                      }}
                      disabled={!activeCompanyIsEditable}
                    />
                    {!!errors?.article_of_association_file && (
                      <Alert color="danger">
                        {errors?.article_of_association_file}
                      </Alert>
                    )}
                  </Col>
                  <Col md="4" className="text-center">
                    <FileUpload
                      title="Trade License*"
                      nameField="trade_license_file"
                      currentFile={basicInfo.trade_license_file}
                      handleOnChange={(file) => {
                        handleOnChangeFile("trade_license_file", file);
                      }}
                      disabled={!activeCompanyIsEditable}
                    />
                    {!!errors?.trade_license_file && (
                      <Alert color="danger">{errors?.trade_license_file}</Alert>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </AvForm>
      </div>
      <ActionFooter next={next} canSave={true} />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    nextStep: state.CompanyState.nextStep,
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  setActiveCompany,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(BasicInfo);
