import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { getOnboarder } from "../../api/common";
import NotificationCard from "../../components/Notification/NotificationCard";

import Pagination from "../../components/Pagination";
import "./style.css";
const PAGELIMIT = 20;
const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(0);
  const getNotifications = () => {
    getOnboarder(`/notifications/notifications/?page=${page}`).then((data) => {
      setNotifications(data["results"]);
      setCount(data["count"]);
    });
  };

  useEffect(() => {
    if (page) getNotifications();
  }, [page]);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="notification-title">Notifications</div>
                <div className="notification-list-page">
                  <div className="list-group">
                    {notifications.map((notification, index) => {
                      return (
                        <NotificationCard
                          notification={notification}
                          updateNotification={getNotifications}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <Pagination
                    name="notificationsPage"
                    totalRecords={count}
                    pageLimit={PAGELIMIT}
                    currentPage={page}
                    setCurrentPage={setPage}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Notifications;
