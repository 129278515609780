import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { formatNumber } from "../../../utils";
import {
  getUsersAndEmployees,
  putUsersAndEmployees,
} from "../../../api/usersAndEmployees";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import { Row, Col, FormGroup, Alert, Card, CardBody, Button } from "reactstrap";
import profileIcon from "../../../assets/images/profile-icon.png";
import {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";
import { handleErrorsAllMessage, formatDate } from "../../../utils";
import Max2DigitsNumberInput from "../../Inputs/Max2DigitsNumberInput";
import debounce from "lodash.debounce";
import "./style.css";

const EmployeesSalaries = ({
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [errors, setErrors] = useState({});
  const forms = useRef([]);
  const saveEmployees = async (cb) => {
    const canSave = await getCanSave();
    if (!canSave) {
      forms.current.forEach((form) => {
        form.setTouched(Object.keys(form._inputs));
      });
    } else {
      const array = employees.filter((employee) => employee.updated);
      if (array.length) {
        showLoader();
        putUsersAndEmployees(`/${company?.id}/EmployeeHr`, array)
          .then((data) => {
            setEmployees(data);
            setErrors({});
            cb();
            removeLoader();
          })
          .catch((error) => {
            setErrors(error);
            removeLoader();
            throw error;
          });
      } else {
        setErrors({});
        cb();
      }
    }
  };
  const save = debounce(() => {
    saveEmployees(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    saveEmployees(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveEmployees(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    saveEmployees(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  useEffect(() => {
    showLoader();
    getUsersAndEmployees(`/${company?.id}/EmployeeHr`)
      .then((data) => {
        setEmployees(data);
        removeLoader();
      })
      .catch(() => {
        removeLoader();
      });
  }, []);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);
  const getCanSave = async () => {
    const flag = await forms.current.reduce(async (flag, form) => {
      const values = form.getValues();
      const errors = await form.validateAll(values);
      return flag && errors.isValid;
    }, true);
    return flag;
  };
  const onValidSubmit = (e, vals) => {};

  const handleForm = (e) => {
    const employees_copy = employees.slice();
    employees_copy.forEach((employee) => {
      if (employee.id == e.target.form.id) {
        employee.updated = true;
        employee[e.target.name] = e.target.value;
        afterUpdate(employee);
      }
    });
    setEmployees(employees_copy);
  };
  const afterUpdate = (employee) => {
    employee.total_salary =
      parseFloat(employee.basic_salary || 0.0) +
      parseFloat(employee.allowance || 0.0);
    if (employee.joining_date) {
      const date1 = new Date(employee.joining_date);
      const date2 = new Date(company.starting_date);
      let factor = 0;
      employee.working_days = 0.0;
      if (date1 <= date2) {
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        employee.working_days = diffDays;
        if (diffDays > 1825) {
          factor = 105 + ((diffDays - 1825) / 365) * 30;
        } else {
          factor = (diffDays / 365) * 21;
        }
      }
      employee.end_of_service = (
        (parseFloat(employee.basic_salary) / 30) *
        factor
      ).toFixed(2);
    }
  };
  const onChangeNumber = async (value, name) => {
    const employees_copy = employees.slice();
    employees_copy.forEach((employee) => {
      if (employee.id == name.split("-")[1]) {
        employee.updated = true;
        employee[name.split("-")[0]] = value || null;
        afterUpdate(employee);
      }
    });
    setEmployees(employees_copy);
  };
  return (
    <div className="container-fluid">
      <ActionHeader canSave={true} save={save} saveExit={saveExit} />
      <div className="">
        <Row>
          <Col>
            {handleErrorsAllMessage(errors) && (
              <Alert color="danger">{handleErrorsAllMessage(errors)}</Alert>
            )}
          </Col>
        </Row>
        {employees.map((employee, cnt) => {
          return (
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <Row>
                      <Col md="4">
                        <div className="text-center">
                          <img
                            className="rounded-circle"
                            alt="100x100"
                            width="80px"
                            height="80px"
                            src={profileIcon}
                            data-holder-rendered="true"
                          />
                          <div>{employee?.employee_type}</div>
                        </div>
                      </Col>
                      <Col md="8">
                        <div>
                          <div>
                            <span style={{ lineBreak: "anywhere" }}>
                              <b>Name</b>: {employee.first_name}{" "}
                              {employee.last_name}
                            </span>
                          </div>
                          <div>
                            <span style={{ lineBreak: "anywhere" }}>
                              <b>Email</b>: {employee.email}
                            </span>
                          </div>
                          <div>
                            <span style={{ lineBreak: "anywhere" }}>
                              <b>Mobile</b>: {employee.mobile}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="8">
                    <AvForm
                      className="needs-validation"
                      onChange={handleForm}
                      id={employee.id}
                      onValidSubmit={onValidSubmit}
                      ref={(ref) => {
                        forms.current[employee.id] = ref;
                      }}
                      disabled={!activeCompanyIsEditable}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Max2DigitsNumberInput
                              name={`basic_salary-${employee.id.toString()}`}
                              label="Basic Salary"
                              placeholder="Basic Salary"
                              value={employee?.basic_salary || ""}
                              onChangeValue={onChangeNumber}
                              disabled={!activeCompanyIsEditable}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <AvField
                              name="joining_date"
                              label="Joining Date"
                              placeholder="Joining Date"
                              value={employee?.joining_date}
                              type="date"
                              validate={{
                                required: { value: false },
                              }}
                              disabled={!activeCompanyIsEditable}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Max2DigitsNumberInput
                              name={`allowance-${employee.id.toString()}`}
                              label="Allowance"
                              placeholder="Allowance"
                              value={employee?.allowance || ""}
                              onChangeValue={onChangeNumber}
                              disabled={!activeCompanyIsEditable}
                              required={false}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <label>Starting Date</label>
                          <div className="starting-date">
                            {formatDate(company.starting_date)}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </Col>

                  <Col md="12">
                    <Row>
                      <Col md="4"></Col>
                      <Col md="8">
                        <Row>
                          <Col md="4">
                            <div>
                              <b>Total Salary</b> <br />
                              {formatNumber(employee?.total_salary)} AED
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <b>End of Service</b>
                              <br />
                              {formatNumber(employee?.end_of_service)} AED
                            </div>
                          </Col>
                          <Col md="4">
                            <div>
                              <b>Working Days</b>
                              <br /> {employee?.working_days} Days
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        })}
      </div>
      <ActionFooter
        next={next}
        canSave={true}
        back={back}
        complete={saveEmployees}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(EmployeesSalaries);
