import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

import { useHistory, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logo from "../../assets/images/logo.png";

import { handleErrorsMessage } from "../../utils";
import { showLoader, removeLoader } from "../../store/Loader/actions";
import { postOnboarder } from "../../api/common";

const ForgetPassword = ({ showLoader, removeLoader }) => {
  let history = useHistory();
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const handleValidSubmit = async (event, values) => {
    try {
      showLoader();
      const data = await postOnboarder("/forget_password/", values);
      setSuccess(true);
      setErrors("");
      removeLoader();
    } catch (errors) {
      setErrors(errors);
      setSuccess(false);
      removeLoader();
    }
  };

  return (
    <>
      <div className="account-pages my-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div>
                <Row className="justify-content-center">
                  <div className="mb-4">
                    <img src={logo} alt="" height="100" />
                  </div>
                </Row>
                <Row className="justify-content-center">
                  <div className="text-primary">
                    <h5 className="text-primary">
                      Welcome to the On-boarding Wizard!
                    </h5>
                  </div>
                </Row>
              </div>
              <Card className="overflow-hidden">
                <CardBody className="pt-12">
                  <div></div>
                  <div className="p-2">
                    {success && (
                      <Row className="justify-content-center">
                        <div className="text-primary">
                          <h3 className="text-success">
                            <i class="fas fa-check"></i>
                          </h3>
                        </div>
                        <div className="text-center">
                          <h3>
                            we have sent to your email a link, please use the
                            link to reset password
                          </h3>
                        </div>
                        <Link to="/login">back to login</Link>
                      </Row>
                    )}
                    {!success && (
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={handleValidSubmit}
                        autoComplete={true}
                      >
                        {handleErrorsMessage(errors) && (
                          <Alert color="danger">
                            {handleErrorsMessage(errors)}
                          </Alert>
                        )}

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            validate={{
                              email: { value: true },
                            }}
                          />
                        </div>
                        <div className="mt-3">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Send Me Link
                          </button>
                        </div>
                      </AvForm>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} The Accounter Technologies Ltd.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  showLoader,
  removeLoader,
})(ForgetPassword);
