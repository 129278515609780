import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGIN_SUCCESS } from "./actionTypes";
import { loginSuccess, apiError } from "./actions";

//Include Both Helper File with needed methods
import { postLogin } from "../../../api/loginApi";
import {setCookie} from "../../../utils"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, "/login/", {
      username: user.userName,
      password: user.password,
    });
    yield put(loginSuccess(response, history, user.rememberMe));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* loginSuccessCall({ payload: { token, history, rememberMe } }) {
  try {
    let days = 0
    if (rememberMe) days = 30;
    setCookie("jwt", token, days)
    history.push(history?.location?.state?.from?.pathname || "/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGIN_SUCCESS, loginSuccessCall);
}

function* authSaga() {
  yield all([fork(watchUserLogin)]);
}

export default authSaga;
