import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { FormGroup } from "reactstrap";
import { connect } from "react-redux";
import NumberInput from "../../Inputs/NumberInput";

const ShareEquityLine = ({
  index,
  line,
  users,
  banks,
  setLines,
  deleteLine,
  lines,
  activeCompanyIsEditable,
}) => {
  const handleOnChangeInput = (e) => {
    line[e.target.name.split("-")[0]] = e.target.value;
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };

  const handleOnChangeNumber = (value, name) => {
    line[name.split("-")[0]] = value;
    line.updated = true;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  return (
    <tr>
      <td>
        <FormGroup hidden>
          <AvField name="id" type="text" value={line?.id || ""} />
        </FormGroup>
        {line?.name}
      </td>
      <td>
        <FormGroup>
          <AvField
            name={`number_of_shares-${index}`}
            placeholder="Enter number of shares"
            type="text"
            value={line?.number_of_shares?.toString() || ""}
            errorMessage="Enter number of shares"
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },

              pattern: {
                value: "^[0-9]+$",
                errorMessage:
                  "Input accept only positive number without fractions",
              },
              maxLength: {
                value: 25,
                errorMessage: "Max 25 chars.",
              },
            }}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </td>
      <td>{(line?.shareholding_percentage || 0).toFixed(2)}%</td>
      <td>
        <FormGroup>
          <NumberInput
            name={`account_balance-${index}`}
            placeholder="Account Balance"
            value={line?.account_balance?.toString() || ""}
            onChangeValue={handleOnChangeNumber}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </td>
    </tr>
  );
};
const mapStateToProps = (state) => {
  return {
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(ShareEquityLine);
