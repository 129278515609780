import React, { useState, useEffect } from "react";

import { getOnboarder } from "../../api/common";
const Indicator = () => {
  const [count, setCount] = useState("");

  const getNotificationsCount = () => {
    getOnboarder(`/notifications/notifications/indicatorCount`).then((data) => {
      if (data["count"] > 9) {
        setCount("+9");
      } else {
        setCount(data["count"] || "");
      }
    });
  };

  useEffect(() => {
    getNotificationsCount();
    const timer = setInterval(() => getNotificationsCount(), 6000);
    return () => clearInterval(timer);
  }, []);
  return (
    <React.Fragment>
      {count && (
        <div className="indicator-continaer">
          <span className="indicator">{count}</span>
        </div>
      )}
    </React.Fragment>
  );
};

export default Indicator;
