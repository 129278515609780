import React from "react";
import { Route, Redirect } from "react-router-dom";
import {getCookie} from "../utils"

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  role,
  ...rest
}) => {
  const savedJwt = getCookie("jwt");
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !savedJwt) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location, requireLogin: true },
              }}
            />
          );
        }

        if (!Component) {
          return <Redirect to={{ pathname: "/dashboard" }} />;
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default AppRoute;
