import React from "react";
import { Switch, Router } from "react-router-dom";
import { connect } from "react-redux";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes";
import AppRoute from "./routes/route";
import VerticalLayout from "./components/VerticalLayout";
import NonAuthLayout from "./components/NonAuthLayout";
import history from "./routes/history";
// Import scss
import "./assets/scss/theme.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ToastContainer } from "react-toastify";
import ModalProvider from "./components/Modal/provider";
import Loader from "./components/Loader";

const hasRole = (user, roles) => {
  return roles.some((role) => user.includes(role.toLowerCase()));
};

const App = (props) => {
  const { roleUser } = props;
  return (
    <ModalProvider>
      <Router history={history}>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact={route.exact}
            />
          ))}
          {authProtectedRoutes.map((route, idx) => {
            const user = roleUser.is_staff ? "admin" : "user";
            return (
              <AppRoute
                path={route.path}
                layout={VerticalLayout}
                component={hasRole(user, route.roles) && route.component}
                key={idx}
                isAuthProtected={true}
                role={hasRole(user, route.roles)}
                exact={route.exact}
              />
            );
          })}
        </Switch>
      </Router>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Loader />
    </ModalProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    roleUser: state.Companies.role,
  };
};

export default connect(mapStateToProps)(App);
