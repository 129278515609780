import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Alert } from "reactstrap";

import { handleErrorsMessage } from "../../utils";
const ResponsiveTables = (props) => {
  const {
    children,
    headCells,
    title,
    btnText,
    handleRouting,
    error,
    start,
    end,
    count,
    orderBy,
    setCurrentOrderBy,
  } = props;
  return (
    <div className="container-fluid">
      {handleErrorsMessage(error) && (
        <Alert color="danger">{handleErrorsMessage(error)}</Alert>
      )}
      <Row>
        <Col>
          <Card style={{ minHeight: "400px" }}>
            <CardBody>
              <Row className="mb-2">
                <Col sm="4"></Col>
                {btnText && (
                  <Col sm="8">
                    <div className="text-sm-right">
                      <Button
                        type="button"
                        color="secondary"
                        className="btn-rounded waves-effect waves-light mb-2 mr-2"
                        onClick={handleRouting}
                      >
                        <i className="mdi mdi-plus mr-1"></i> {btnText}
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
              <CardTitle>
                <Row className="justify-content-between">
                  <div>{title}</div>
                  {count && <div>{`${start}-${end}/${count}`}</div>}
                </Row>
              </CardTitle>

              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                  style={{ maxWidth: "100%" }}
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        {headCells.map((headCell) => {
                          const identifier = `${headCell.label}-${headCell.id}`;

                          return (
                            <Th
                              key={identifier}
                              style={headCell.style}
                              colspan={headCell.colspan}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCurrentOrderBy(headCell.id);
                              }}
                            >
                              {headCell.label}
                              {orderBy && orderBy === `-${headCell.id}` && (
                                <i class="fas fa-arrow-down"></i>
                              )}
                              {orderBy && orderBy === `${headCell.id}` && (
                                <i class="fas fa-arrow-up"></i>
                              )}
                            </Th>
                          );
                        })}
                      </Tr>
                    </Thead>
                    <Tbody>{children}</Tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ResponsiveTables;

ResponsiveTables.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  btnText: PropTypes.string,
  handleRouting: PropTypes.func,
  error: PropTypes.string,
};
