import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Alert,
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import { getOnboarder, postOnboarder } from "../../../api/common";
import { showLoader, removeLoader } from "../../../store/Loader/actions";
import ContractLine from "./contractLine.js";
import { handleErrorsAllMessage, formatDate } from "../../../utils";
import {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
} from "../../../store/CompanyState/actions";
import ActionFooter from "../ActionFooter";
import ActionHeader from "../ActionHeader";
import debounce from "lodash.debounce";

const Contracts = ({
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  company,
  activeCompanyIsEditable,
  showLoader,
  removeLoader,
}) => {
  let history = useHistory();
  const [canSave, setCanSave] = useState(false);
  const [errors, setErrors] = useState([]);
  const [lines, setLines] = useState([]);
  const [deleteLines, setDeleteLines] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const form = useRef([]);
  const accountsName = [
    "Prepaid Office Rent",
    "Prepaid Trade License, Establishment Card, PO Box & Sponsor Fees",
    "Prepaid Medical Insurance",
    "Prepaid Marketing Expenses",
    "Prepaid Subscription Fees",
    "Prepaid Professional Indemnity & Office Insurance",
    "Prepaid Fire & Accident Insurance",
    "Prepaid Vehicle Insurance",
    "Prepaid Expenses - Others",
  ];
  const onValidSubmit = (e, vals) => {};
  const deleteLine = (id) => {
    setDeleted((deleted) => [...deleted, id]);
  };

  let save = debounce(() => {
    saveContracts(() => {
      setNextStep(null);
      saveActiveCompany();
    });
  }, 200);
  const saveExit = debounce(() => {
    saveContracts(() => {
      setNextStep("exit");
      history.goBack();
    });
  }, 200);
  const next = debounce(() => {
    saveContracts(() => {
      setNextStep("next");
      nextState();
    });
  }, 200);
  const back = debounce(() => {
    saveContracts(() => {
      setNextStep("back");
      previousState();
    });
  }, 200);
  useEffect(() => {
    showLoader();
    getOnboarder(`/UserManagement/${company?.id}/accounts`)
      .then((data) => {
        setAccounts(
          data.filter((line) => {
            return accountsName.includes(line.name);
          })
        );
      })
      .catch((error) => {
        removeLoader();
      });
    getOnboarder(`/UserManagement/${company?.id}/contracts/`).then((data) => {
      data.forEach((line) => {
        line.updated = false;
        line.selected = false;
      });
      setLines(data);
      removeLoader();
    });

    if (form.current) {
      form.current.setTouched(Object.keys(form.current._inputs));
      setCanSave(!form.current.hasError());
    }
  }, []);
  let saveContracts = (cb) => {
    const body = {
      contracts: lines.filter((line) => line.updated),
      delete: deleteLines,
    };
    if (body.contracts.length || body.delete.length) {
      setCanSave(false);
      showLoader();
      postOnboarder(`/UserManagement/${company?.id}/contracts/submit`, body)
        .then((data) => {
          setErrors([]);
          data.forEach((line) => {
            line.updated = false;
            line.selected = false;
          });
          setLines(data);
          cb();
          setCanSave(true);
          removeLoader();
        })
        .catch((error) => {
          setErrors(error);
          removeLoader();
          setCanSave(true);
          throw error;
        });
    } else {
      setErrors({});
      cb();
    }
  };

  useEffect(() => {
    if (form.current) {
      form.current.setTouched(Object.keys(form.current._inputs));
      const values = form.current.getValues();
      form.current.validateAll(values).then((errors) => {
        if (form.current) {
          setCanSave(!form.current.hasError());
        }
      });
    }
  }, [lines]);

  useEffect(() => {
    const div = document.querySelector(".alert-danger");
    div?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [errors]);

  const handleAddNew = () => {
    setLines((lines) => [...lines, { updated: true }]);
  };

  const deleteRows = debounce(() => {
    setLines((lines) => lines.filter((line) => !line.selected));
    setDeleteLines(
      lines.filter((line) => line.selected).map((line) => line.id)
    );
  }, 200);
  const toggleSelectAll = (e) => {
    const checked = e.target.checked;
    let items = [...lines];
    items.forEach((item) => {
      item.selected = checked;
    });
    setLines(items);
  };
  return (
    <div className="container-fluid">
      <ActionHeader
        canSave={canSave}
        save={save}
        deleteRows={deleteRows}
        lines={lines}
        saveExit={saveExit}
      />
      <Card style={{ marginBottom: "0rem" }}>
        <CardBody style={{ padding: "0.75rem", paddingBottom: "0rem" }}>
          <Row>
            <CardTitle>
              Balance Sheet and Useful life as of:{" "}
              {formatDate(company.starting_date)}
            </CardTitle>
          </Row>
          <Row>
            <Col>
              {handleErrorsAllMessage(errors) && (
                <Alert color="danger">{handleErrorsAllMessage(errors)}</Alert>
              )}
            </Col>
          </Row>
          <AvForm
            className="needs-validation"
            ref={form}
            onValidSubmit={onValidSubmit}
          >
            <div className="table-container-input-with-title">
              <Table className="table-responsive-input">
                <Thead>
                  <Tr>
                    <Th style={{ minWidth: "10px" }}>
                      <FormGroup>
                        <AvField
                          type="checkbox"
                          name="selected"
                          onChange={toggleSelectAll}
                          disabled={!activeCompanyIsEditable}
                        />
                      </FormGroup>
                    </Th>
                    <Th>Name</Th>
                    <Th>Account</Th>
                    <Th>Total Amount</Th>
                    <Th>Paid Amount</Th>
                    <Th>Date</Th>

                    <Th className="text-center" style={{ minWidth: "135px" }}>
                      Useful Life <br />
                      (Months)
                    </Th>
                    <Th className="text-center">
                      Amortization <br /> (Monthly)
                    </Th>
                    <Th className="text-center">
                      Balance Sheet
                      <br /> Asset <br /> as of{" "}
                      {formatDate(company.starting_date)}
                    </Th>
                    <Th className="text-center">
                      Balance Sheet
                      <br /> Liability <br /> as of{" "}
                      {formatDate(company.starting_date)}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lines.map((line, index) => {
                    return (
                      <ContractLine
                        index={index}
                        line={line}
                        setLines={setLines}
                        lines={lines}
                        accounts={accounts}
                        company={company}
                      />
                    );
                  })}
                </Tbody>
              </Table>
            </div>
            {activeCompanyIsEditable && (
              <Button color="link" onClick={handleAddNew}>
                Add New Line
              </Button>
            )}
          </AvForm>
        </CardBody>
      </Card>
      <ActionFooter
        next={next}
        canSave={canSave}
        back={back}
        complete={saveContracts}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {
  setNextStep,
  nextState,
  previousState,
  saveActiveCompany,
  showLoader,
  removeLoader,
})(Contracts);
