import React from "react";
import { AvField } from "availity-reactstrap-validation";

import { connect } from "react-redux";
import { FormGroup, Button } from "reactstrap";
import { monthDiff } from "../../../utils";
import { formatNumber } from "../../../utils";
import { Tr, Td } from "react-super-responsive-table";
import Max2DigitsPositiveNumberInput from "../../Inputs/Max2DigitsPositiveNumberInput";
import Max2DigitsNumberInput from "../../Inputs/Max2DigitsNumberInput";
const AssetsLine = ({
  index,
  line,
  categories,
  setLines,
  lines,
  company,
  activeCompanyIsEditable,
}) => {
  const onChangeLine = (line) => {
    if (line?.useful_life && line?.purchase_cost) {
      line["monthly_depreciation_amount"] = (
        (line.purchase_cost - line.non_depreciable_amount) /
        line.useful_life
      ).toFixed(2);
    } else {
      line["monthly_depreciation_amount"] = 0.0;
    }

    if (line?.purchase_date && company?.starting_date) {
      const purchaseDate = new Date(line.purchase_date);
      const startingDate = new Date(company.starting_date);
      const diffMonths = monthDiff(purchaseDate, startingDate);

      line["accumulated_depreciation_amount"] = Math.min(
        line?.monthly_depreciation_amount * diffMonths,
        line.purchase_cost
      ).toFixed(2);
    }
  };
  const handleOnChangeInput = (e) => {
    line[e.target.name.split("-")[0]] = e.target.value;
    line.updated = true;
    let items = [...lines];
    if (e.target.name.split("-")[0] === "category") {
      const selecedCategory = categories.filter((category) => {
        return category.id == line.category;
      });
      if (selecedCategory.length >= 1)
        line.useful_life = selecedCategory[0].duration;
    }
    onChangeLine(line);
    items[index] = line;
    setLines(items);
  };

  const onChangePurchaseValue = (value, name) => {
    line[name.split("-")[0]] = value;
    line.updated = true;
    if (
      parseFloat(line?.purchase_cost) ===
      parseFloat(line?.non_depreciable_amount)
    ) {
      line.useful_life = 0;
    }
    onChangeLine(line);
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const handleOnDelete = (e) => {
    line.selected = e.target.checked;
    let items = [...lines];
    items[index] = line;
    setLines(items);
  };
  const isDepreciated = (startingDate, purchaseDate, useful_life) => {
    const months = monthDiff(startingDate, purchaseDate);
    if (
      useful_life == 0 &&
      parseFloat(line?.purchase_cost) ===
        parseFloat(line?.non_depreciable_amount)
    ) {
      return false;
    }
    if (useful_life <= months) {
      return true;
    } else {
      return false;
    }
  };
  const isDepreciatedPurchaseDate = (value, ctx, input, cb) => {
    const startingDate = new Date(company.starting_date);
    if (isDepreciated(startingDate, new Date(value), line?.useful_life)) {
      cb("Can’t add this item, This item is already depreciated");
    } else {
      return true;
    }
  };

  const validateNonDepricaibalAmount = (value, ctx, input, cb) => {
    if (line.purchase_cost < parseFloat(value)) {
      cb("Can’t more than purchase cost");
    } else {
      return true;
    }
  };

  const isDepreciatedUsefulLife = (value, ctx, input, cb) => {
    const startingDate = new Date(company.starting_date);
    if (isDepreciated(startingDate, new Date(line?.purchase_date), value)) {
      cb("Can’t add this item, This item is already depreciated");
    } else {
      return true;
    }
  };
  return (
    <Tr>
      <Td style={{ minWidth: "10px" }}>
        <FormGroup hidden>
          <AvField name="id" type="text" value={line?.id || ""} />
        </FormGroup>

        <FormGroup>
          <AvField
            type="checkbox"
            name="selected"
            checked={line?.selected || ""}
            onChange={handleOnDelete}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`name-${index}`}
            placeholder="Name"
            type="text"
            value={line?.name || ""}
            errorMessage="Enter Name"
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true },
              pattern: {
                value: "^[A-Za-z ]+$",
                errorMessage: "input accepts only characters",
              },
              maxLength: {
                value: 100,
                errorMessage: "Max 100 chars.",
              },
            }}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            type="select"
            name={`category-${index}`}
            value={line?.category || ""}
            onChange={handleOnChangeInput}
            errorMessage="Enter Category"
            validate={{
              required: { value: true },
            }}
            disabled={!activeCompanyIsEditable}
          >
            <option></option>
            {categories.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.name}
              </option>
            ))}
          </AvField>
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <Max2DigitsPositiveNumberInput
            name={`purchase_cost-${index}`}
            value={line?.purchase_cost || ""}
            disabled={!activeCompanyIsEditable}
            onChangeValue={onChangePurchaseValue}
          />
        </FormGroup>
      </Td>

      <Td>
        <FormGroup>
          <Max2DigitsNumberInput
            name={`non_depreciable_amount-${index}`}
            value={line?.non_depreciable_amount || ""}
            disabled={!activeCompanyIsEditable}
            onChangeValue={onChangePurchaseValue}
            validate={{
              custom: validateNonDepricaibalAmount,
            }}
            required={false}
          />
        </FormGroup>
      </Td>
      <Td>
        <FormGroup>
          <AvField
            name={`purchase_date-${index}`}
            value={line?.purchase_date || ""}
            placeholder="Purchase Date"
            type="date"
            onChange={handleOnChangeInput}
            validate={{
              required: { value: true, errorMessage: "Mandatory field" },
              custom: isDepreciatedPurchaseDate,
              dateRange: {
                format: "YYYY-MM-DD",
                errorMessage:
                  "Purchase date can't be later than the Starting Date of the service",
                start: { value: "1950-01-01" },
                end: { value: company.starting_date },
              },
            }}
            disabled={!activeCompanyIsEditable}
          />
        </FormGroup>
      </Td>

      <Td>
        <FormGroup>
          <AvField
            name={`useful_life-${index}`}
            placeholder="useful life"
            type="text"
            value={line?.useful_life || ""}
            errorMessage="useful life"
            className="form-control"
            onChange={handleOnChangeInput}
            validate={{
              required: {
                value:
                  parseFloat(line?.purchase_cost) !==
                  parseFloat(line?.non_depreciable_amount),
              },

              pattern: {
                value: "^[0-9]+$",
                errorMessage:
                  "Input accept only positive number, Fractions aren't allowed",
              },
              custom: isDepreciatedUsefulLife,
            }}
            disabled={
              !activeCompanyIsEditable ||
              parseFloat(line?.purchase_cost) ===
                parseFloat(line?.non_depreciable_amount)
            }
          />
        </FormGroup>
      </Td>

      <Td>{formatNumber(line?.monthly_depreciation_amount || 0.0)} AED</Td>
      <Td>{formatNumber(line?.accumulated_depreciation_amount || 0.0)} AED</Td>
    </Tr>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.CompanyState.activeCompany,
    activeCompanyIsEditable: state.CompanyState.activeCompanyIsEditable,
  };
};
export default connect(mapStateToProps, {})(AssetsLine);
