export const ASSIGN_TO_ME_REQUEST = "ASSIGN_TO_ME_REQUEST";
export const ASSIGN_TO_ME_SUCCESS = "ASSIGN_TO_ME_SUCCESS";

export const ASSIGN_TO_ME_REVIEW_REQUEST = "ASSIGN_TO_ME_REVIEW_REQUEST";
export const ASSIGN_TO_ME_REVIEW_SUCCESS = "ASSIGN_TO_ME_REVIEW_SUCCESS";

export const START_ON_BOARDING_REQUEST = "START_ON_BOARDING_REQUEST";
export const START_ON_BOARDING_SUCCESS = "START_ON_BOARDING_SUCCESS";

export const START_REVIEW_REQUEST = "START_REVIEW_REQUEST";
export const START_REVIEW_SUCCESS = "START_REVIEW_SUCCESS";

export const CHANGE_ON_BOARDING_REQUEST = "CHANGE_ON_BOARDING_REQUEST";
export const CHANGE_REVIEWER_REQUEST = "CHANGE_REVIEWER_REQUEST";
export const CHANGE_ON_BOARDING_SUCCESS = "CHANGE_ON_BOARDING_SUCCESS";

export const API_ERROR = "ASSIGN_API_ERROR";
export const REST_ERROR = "ASSIGN_REST_ERROR";
export const REST_STATE = "ASSIGN_REST_STATE";
